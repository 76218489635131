import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    message,
    Select,
    Radio,
    TimePicker,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import clone from 'clone'
import Paper from '@mui/material/Paper'
import { v4 as uuidv4 } from 'uuid'
import WhatsAppTemplates from '../User/WhatsAppTemplates'
import { EditTwoTone, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import ReorderTable from '../Common/ReorderTable'
import RouteData from '../Common/RouteData'

const TYPE_EMAIL = 'Email'
const TYPE_SMS = 'SMS'
const TYPE_WHATSAPP = 'WhatsApp'
const TYPE_NOTIFICATION = 'Notification'

const FLOW_ATTEMPT_WISE_COMPLETION_STATUS = 'Attemp_Wise_Completion_Status'

const SENDING_PATTERN_WEEKLY_CUSTOM = 'Weekly_Custom'

function NudgeNotification(props) {
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const [selectedFlow, setSelectedFlow] = useState(
        FLOW_ATTEMPT_WISE_COMPLETION_STATUS
    )
    const [selectedType, setSelectedType] = useState(TYPE_EMAIL)

    const [testing, setTesting] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [previewEmailModalVisible, setPreviewEmailModalVisible] =
        useState(false)
    const [emailBody, setEmailBody] = useState()
    const [isEmailCustomHtml, setIsEmailCustomHtml] = useState(false)
    const [emailCustomHtml, setEmailCustomHtml] = useState('')
    const [
        emailCustomHtmlPreviewModalVisible,
        setEmailCustomHtmlPreviewModalVisible,
    ] = useState(false)
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [userPlaceHolders, setUserPlaceHolders] = useState([])
    const [timeToSend, setTimeToSend] = useState()
    const [sms, setSms] = useState()
    const [whatsappTemplateNames, setWhatsappTemplateNames] = useState([])
    const [whatsappTemplateNamesLoading, setWhatsappTemplateNamesLoading] =
        useState(false)
    const [whatsAppTemplatesModalVisible, setWhatsAppTemplatesModalVisible] =
        useState(false)
    const [addVariableDataModalVisible, setAddVariableDataModalVisible] =
        useState(false)
    const [selectedVariableData, setSelectedVariableData] = useState({})
    const [variablesData, setVariablesData] = useState([])
    const [
        reorderVariableDataModalVisible,
        setReorderVariableDataModalVisible,
    ] = useState(false)
    const [notificationTitle, setNotificationTitle] = useState()
    const [routeData, setRouteData] = useState()
    const [selectedSendingPattern, setSelectedSendingPattern] = useState()
    const [sendingPatternSubTypes1, setSendingPatternSubTypes1] = useState([])
    const [sendingDays, setSendingDays] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        getNotifications()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlow, selectedType])

    useEffect(() => {
        getSendingPatternSubTypes1()
        getSendingDays()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSendingPattern])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getNotifications = () => {
        setLoading(true)
        setData([])

        RestApi.doGet(
            GLOBAL.URL.NUDGE.GET_NOTIFICATIONS +
                '?flow=' +
                selectedFlow +
                '&type=' +
                selectedType
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisable(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Sending Pattern',
            dataIndex: 'sendingPattern',
            key: 'sendingPattern',
            render: (text, record) => (
                <span>
                    {record.sendingPattern}
                    {record.sendingPatternSubType1 ? (
                        <>
                            <br />
                            {record.sendingPatternSubType1}
                        </>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Sending Day',
            dataIndex: 'sendingDay',
            key: 'sendingDay',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    {record.title}
                    {record.type === 'Email' ? (
                        <span style={{ fontSize: 11 }}>
                            <br />
                            Open count: {record.emailOpenCount}
                        </span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'timeToSend',
            key: 'timeToSend',
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <>
                    {record.type === TYPE_EMAIL ? (
                        <>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => showPreviewEmail(record)}
                                style={{ padding: 0 }}
                            >
                                Preview Email
                            </Button>
                        </>
                    ) : selectedType === TYPE_SMS ? (
                        <>{record.sms}</>
                    ) : selectedType === TYPE_WHATSAPP ? (
                        <>{record.whatsAppTemplateName}</>
                    ) : selectedType === TYPE_NOTIFICATION ? (
                        <>
                            <strong>{record.notificationTitle}</strong>
                            <br />
                            {record.notificationBody}
                            <br />
                            <span style={{ fontSize: 12 }}>
                                {record.notificationRoute}
                            </span>
                            {record.notificationImage ? (
                                <>
                                    <br />
                                    <a
                                        href={record.notificationImage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Image
                                    </a>
                                </>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Test',
            dataIndex: 'test',
            key: 'test',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => testNotification(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        size="small"
                        disabled={testing}
                        style={{ padding: 0 }}
                    >
                        Test
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteNotification(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.NUDGE.ENABLE_NOTIFICATION
        if (record.enabled) {
            url = GLOBAL.URL.NUDGE.DISABLE_NOTIFICATION
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showPreviewEmail = (record) => {
        setSelectedRecord(record)
        setPreviewEmailModalVisible(true)
    }

    const deleteNotification = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NUDGE.DELETE_NOTIFICATION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getNotifications()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onChangeFlow = (event) => {
        setSelectedFlow(event.target.value)
    }

    const onChangeType = (event) => {
        setSelectedType(event.target.value)
    }

    const hidePreviewEmail = () => {
        setPreviewEmailModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(true)
    }

    const hideWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(false)
    }

    const showEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(true)
    }

    const hideEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(false)
    }

    const getWhatsAppTemplates = () => {
        setWhatsappTemplateNamesLoading(true)
        RestApi.doGet(GLOBAL.URL.NOTIFICATION.GET_WHATSAPP_TEMPLATE_NAMES)
            .then((res) => {
                setWhatsappTemplateNames(res.data)
            })
            .finally(() => {
                setWhatsappTemplateNamesLoading(false)
            })
    }

    const whatsappTemplateOptions = () => {
        var options = []
        whatsappTemplateNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const getUserPlaceHolders = () => {
        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }
    }

    const getPlaceHolders = () => {
        var res = ''
        userPlaceHolders.forEach((userPlaceHolder) => {
            if (res) {
                res = res + ', '
            }

            res = res + userPlaceHolder
        })

        return res
    }

    const updateVariableDataInState = (variablesDataObj = []) => {
        var obj = []
        variablesDataObj.forEach((item) => {
            var varObj = {}
            varObj.id = uuidv4()
            varObj.data = item

            obj.push(varObj)
        })

        setVariablesData(obj)
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        getUserPlaceHolders()
        setSelectedSendingPattern()

        if (selectedType === TYPE_WHATSAPP) {
            getWhatsAppTemplates()
        }

        if (record && record.uid) {
            setTimeToSend(record.timeToSend)
            setSelectedSendingPattern(record.sendingPattern)

            var formData = {}
            formData.title = record.title
            formData.sendingPattern = record.sendingPattern
            formData.sendingPatternSubType1 = record.sendingPatternSubType1
            formData.sendingDay = record.sendingDay
            formData.timeToSend = dayjs(record.timeToSend, 'HH:mm')

            if (selectedType === TYPE_EMAIL) {
                setIsEmailCustomHtml(record.emailCustomHtmlBoolean)
                formData.emailSubject = record.emailSubject

                if (record.emailCustomHtmlBoolean) {
                    formData.emailCustomHtml = record.emailCustomHtml
                    setEmailCustomHtml(record.emailCustomHtml)
                    setEmailBody('')
                } else {
                    setEmailBody(record.emailBody)
                    setEmailCustomHtml('')
                    formData.emailPreHeader = record.emailPreHeader
                }
            } else if (selectedType === TYPE_SMS) {
                setSms(record.sms)
                formData.sms = record.sms
            } else if (selectedType === TYPE_WHATSAPP) {
                updateVariableDataInState(
                    record.whatsAppParams
                        ? JSON.parse(record.whatsAppParams)
                        : []
                )
                formData.whatsAppTemplateName = record.whatsAppTemplateName
                formData.whatsAppImage = record.whatsAppImage ?? ''
            } else if (selectedType === TYPE_NOTIFICATION) {
                setNotificationTitle(record.notificationTitle)
                setRouteData(record.notificationRoute)

                formData.notificationTitle = record.notificationTitle
                formData.notificationBody = record.notificationBody
                formData.notificationImage = record.notificationImage
            }

            setTimeout(() => {
                form.setFieldsValue(formData)
            }, 100)
        } else {
            form.resetFields()
            setSendingPatternSubTypes1([])
            setSendingDays([])
            setTimeToSend()
            setEmailBody()
            setSms()
            setVariablesData([])
            setNotificationTitle()
            setRouteData()
        }
    }

    const hideAddVariableDataModalVisible = () => {
        setAddVariableDataModalVisible(false)
    }

    const showReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(true)
    }

    const hideReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(false)
    }

    const saveReorderVariablesData = (data) => {
        setVariablesData(data)
        hideReorderVariableDataModal()
    }

    const reorderVariablesDataTableColumns = [
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
    ]

    const deleteVariableData = (id) => {
        setVariablesData(variablesData.filter((item) => item.id !== id))
    }

    const showAddVariableDataModalVisible = (record = {}) => {
        setAddVariableDataModalVisible(true)
        setSelectedVariableData(record)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({
                    dataValue: record.data,
                })
            }, 100)
        }

        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }
    }

    const addOrUpdateVariableData = () => {
        form.validateFields(['dataValue'])
            .then((values) => {
                var variablesData1 = clone(variablesData)
                if (selectedVariableData.id) {
                    variablesData1.forEach((item) => {
                        if (item.id === selectedVariableData.id) {
                            item.data = values.dataValue
                        }
                    })
                } else {
                    var varData = {}
                    varData.id = uuidv4()
                    varData.data = values.dataValue

                    variablesData1.push(varData)
                }

                setVariablesData(variablesData1)
                hideAddVariableDataModalVisible()
            })
            .catch((error) => {})
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const variablesDataTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddVariableDataModalVisible(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Variable #',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteVariableData(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const handleEmailBodyChange = (newValue, e) => {
        setEmailBody(newValue)
    }

    const onChangeTime = (time, timeString) => {
        setTimeToSend(timeString)
    }

    const testNotification = (uid) => {
        setTesting(true)
        message.loading('Sending...', 0)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NUDGE.TEST_NOTIFICATION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setTesting(false)
                message.destroy()
            })
    }

    const onChangeIsEmailCustomHtml = (event) => {
        setIsEmailCustomHtml(event.target.value)
    }

    const getIsEmailCustomHtmlOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )

        return options
    }

    const addOrUpdate = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                if (!timeToSend) {
                    notification['error']({
                        message: 'Select time to send!',
                    })

                    return
                }

                values.flow = selectedFlow
                values.type = selectedType
                values.timeToSend = timeToSend

                if (selectedType === TYPE_EMAIL) {
                    values.isEmailCustomHtmlBoolean = isEmailCustomHtml

                    if (!isEmailCustomHtml) {
                        values.emailBody = emailBody
                    }
                } else if (selectedType === TYPE_WHATSAPP) {
                    var whatsAppParams = []
                    variablesData.forEach((item) => {
                        whatsAppParams.push(item.data)
                    })

                    values.whatsAppParams = whatsAppParams
                } else if (selectedType === TYPE_NOTIFICATION) {
                    var routeObj = {}
                    routeObj.key = 'route'
                    routeObj.value = values.route

                    if (!values.routeData) {
                        values.routeData = []
                    }

                    values.routeData.push(routeObj)
                }

                var URL = GLOBAL.URL.NUDGE.ADD_NOTIFICATION
                if (selectedRecord.uid) {
                    values.uid = selectedRecord.uid
                    URL = GLOBAL.URL.NUDGE.UPDATE_NOTIFICATION
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(URL, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getNotifications()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const onChangeSendingPattern = (e) => {
        setSelectedSendingPattern(e.target.value)
    }

    const getSendingPatternSubTypes1 = () => {
        setSendingPatternSubTypes1([])
        if (!selectedSendingPattern) {
            return
        }

        RestApi.doGet(
            GLOBAL.URL.NUDGE.GET_NOTIFICATION_SENDING_PATTERN_SUB_TYPES_1 +
                selectedSendingPattern
        ).then((res) => {
            setSendingPatternSubTypes1(res.data)
        })
    }

    const getSendingDays = () => {
        setSendingDays([])
        if (!selectedSendingPattern) {
            return
        }

        RestApi.doGet(
            GLOBAL.URL.NUDGE.GET_NOTIFICATION_SENDING_DAYS +
                selectedSendingPattern
        ).then((res) => {
            setSendingDays(res.data)
        })
    }

    const getSendingPatternSubTypes1Options = () => {
        var options = []

        sendingPatternSubTypes1.forEach((option) => {
            options.push(
                <Radio.Button value={option} key={option}>
                    {option}
                </Radio.Button>
            )
        })

        return options
    }

    const getSendingDaysOptions = () => {
        var options = []

        sendingDays.forEach((option) => {
            options.push(
                <Radio.Button value={option} key={option}>
                    {option}
                </Radio.Button>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Nudge Notifications</h2>
            <div>
                <Radio.Group onChange={onChangeFlow} value={selectedFlow}>
                    <Radio.Button
                        value={FLOW_ATTEMPT_WISE_COMPLETION_STATUS}
                        key={FLOW_ATTEMPT_WISE_COMPLETION_STATUS}
                    >
                        {FLOW_ATTEMPT_WISE_COMPLETION_STATUS}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={onChangeType} value={selectedType}>
                    <Radio.Button value={TYPE_EMAIL} key={TYPE_EMAIL}>
                        {TYPE_EMAIL}
                    </Radio.Button>
                    <Radio.Button value={TYPE_SMS} key={TYPE_SMS}>
                        {TYPE_SMS}
                    </Radio.Button>
                    <Radio.Button value={TYPE_WHATSAPP} key={TYPE_WHATSAPP}>
                        {TYPE_WHATSAPP}
                    </Radio.Button>
                    <Radio.Button
                        value={TYPE_NOTIFICATION}
                        key={TYPE_NOTIFICATION}
                    >
                        {TYPE_NOTIFICATION}
                    </Radio.Button>
                </Radio.Group>
                <Button
                    type="link"
                    onClick={getNotifications}
                    style={{ marginLeft: 10 }}
                >
                    {loading ? (
                        <LoadingOutlined
                            type="loading"
                            style={{ fontSize: 20 }}
                        />
                    ) : (
                        <ReloadOutlined
                            type="reload"
                            style={{ fontSize: 20 }}
                        />
                    )}
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                />
            </div>
            <Modal
                title={selectedRecord.title}
                open={previewEmailModalVisible}
                onCancel={hidePreviewEmail}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <h4>Subject</h4>
                <Paper style={{ marginBottom: 10, padding: 10 }}>
                    {selectedRecord.emailSubject}
                </Paper>
                {selectedRecord.emailCustomHtmlBoolean ? (
                    <>
                        <h4>Email Body</h4>
                        <Paper
                            dangerouslySetInnerHTML={createMarkupWeb(
                                selectedRecord.emailCustomHtml
                            )}
                            style={{ padding: 10 }}
                        />
                    </>
                ) : (
                    <>
                        <h4>Pre Header</h4>
                        <Paper style={{ marginBottom: 10, padding: 10 }}>
                            {selectedRecord.emailPreHeader}
                        </Paper>
                        <h4>Email Body</h4>
                        <Paper
                            dangerouslySetInnerHTML={createMarkupWeb(
                                selectedRecord.emailBody
                            )}
                            style={{ padding: 10 }}
                        />
                    </>
                )}
            </Modal>
            <Modal
                title={'Custom HTML Preview'}
                open={emailCustomHtmlPreviewModalVisible}
                onCancel={hideEmailCustomHtmlPreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <Paper
                    dangerouslySetInnerHTML={createMarkupWeb(emailCustomHtml)}
                    style={{ padding: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Variable Data'}
                open={addVariableDataModalVisible}
                onOk={addOrUpdateVariableData}
                onCancel={hideAddVariableDataModalVisible}
                okText={selectedVariableData.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={600}
            >
                {addVariableDataModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Data"
                            style={{ marginBottom: 5 }}
                            name="dataValue"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                    </Form>
                ) : null}
                <span style={{ fontSize: 12, marginTop: 20 }}>
                    {'Place holders:  '}
                    <br />
                    {getPlaceHolders()}
                </span>
            </Modal>
            <Modal
                title={'Reorder Variables Data'}
                open={reorderVariableDataModalVisible}
                width={600}
                onCancel={hideReorderVariableDataModal}
                destroyOnClose={true}
                footer={null}
            >
                <ReorderTable
                    reorderData={variablesData}
                    columns={reorderVariablesDataTableColumns}
                    onOk={saveReorderVariablesData}
                />
            </Modal>
            {whatsAppTemplatesModalVisible ? (
                <WhatsAppTemplates hideModal={hideWhatsAppTemplatesModal} />
            ) : null}
            <Modal
                title={'Configure Notification'}
                open={addEditModalVisible}
                onOk={addOrUpdate}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1200}
            >
                {addEditModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Title"
                            style={{ marginBottom: 5 }}
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input ref={inputRef} />
                        </Form.Item>
                        <Form.Item
                            label="Sending Pattern"
                            style={{ marginBottom: 10 }}
                            name="sendingPattern"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeSendingPattern}>
                                <Radio.Button
                                    value={SENDING_PATTERN_WEEKLY_CUSTOM}
                                    key={SENDING_PATTERN_WEEKLY_CUSTOM}
                                >
                                    {SENDING_PATTERN_WEEKLY_CUSTOM}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {sendingPatternSubTypes1.length ? (
                            <Form.Item
                                label="Sending Pattern Sub Pattern 1"
                                style={{ marginBottom: 10 }}
                                name="sendingPatternSubType1"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {getSendingPatternSubTypes1Options()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                        {sendingDays.length ? (
                            <Form.Item
                                label="Sending Day"
                                style={{ marginBottom: 10 }}
                                name="sendingDay"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {getSendingDaysOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="Time"
                            style={{ marginBottom: 5 }}
                            name="timeToSend"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeTime}
                            />
                        </Form.Item>
                        {selectedType === TYPE_EMAIL ? (
                            <>
                                <Form.Item
                                    label="Email Subject"
                                    style={{ marginBottom: 5 }}
                                    name="emailSubject"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <div
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                    <span
                                        style={{
                                            color: 'black',
                                            marginRight: 10,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Email Body Custom Html?
                                    </span>
                                    <Radio.Group
                                        onChange={onChangeIsEmailCustomHtml}
                                        value={isEmailCustomHtml}
                                    >
                                        {getIsEmailCustomHtmlOptions()}
                                    </Radio.Group>
                                </div>
                                {isEmailCustomHtml ? (
                                    <>
                                        <Button
                                            type="primary"
                                            style={{
                                                marginBottom: 10,
                                                marginLeft: 144,
                                            }}
                                            onClick={showEmailCustomHtmlPreview}
                                        >
                                            Preview Html
                                        </Button>
                                        <Form.Item
                                            label="Custom Html"
                                            style={{ marginBottom: 5 }}
                                            name="emailCustomHtml"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={5}
                                                onChange={(e) =>
                                                    setEmailCustomHtml(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            label="Email Pre Header"
                                            style={{ marginBottom: 5 }}
                                            name="emailPreHeader"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <UploadAndGetLink />
                                        <div style={{ marginTop: 0 }}>
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                }}
                                            >
                                                {'Place holders:  '}
                                                {getPlaceHolders()}
                                            </span>
                                            <div style={{ marginTop: 10 }}>
                                                <span>Email Body:</span>
                                            </div>
                                            <TinyMceEditor
                                                handleEditorChange={
                                                    handleEmailBodyChange
                                                }
                                                initialValue={emailBody}
                                                height={300}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : null}
                        {selectedType === TYPE_SMS ? (
                            <>
                                <div style={{ marginTop: 0 }}>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            marginLeft: 145,
                                        }}
                                    >
                                        {'Place holders:  '}
                                        {getPlaceHolders()}
                                    </span>
                                </div>
                                <Form.Item
                                    label="SMS"
                                    style={{ marginBottom: 5 }}
                                    name="sms"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => setSms(e.target.value)}
                                    />
                                </Form.Item>
                                {sms && sms.length ? (
                                    <span style={{ marginLeft: 145 }}>
                                        {sms.length + ' characters'}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        {selectedType === TYPE_WHATSAPP ? (
                            <>
                                <Form.Item
                                    label="WA Template"
                                    style={{ marginBottom: 5 }}
                                    name="whatsAppTemplateName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select template"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        allowClear={true}
                                    >
                                        {whatsappTemplateOptions()}
                                    </Select>
                                </Form.Item>
                                <div style={{ marginBottom: 5 }}>
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={getWhatsAppTemplates}
                                        loading={whatsappTemplateNamesLoading}
                                        style={{ marginLeft: 145 }}
                                    >
                                        Refresh Templates
                                    </Button>
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={showWhatsAppTemplatesModal}
                                        loading={whatsappTemplateNamesLoading}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Configure
                                    </Button>
                                </div>
                                <Form.Item
                                    label="WA Image"
                                    style={{ marginBottom: 5 }}
                                    name="whatsAppImage"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <div style={{ marginTop: 10 }}>
                                    <div>
                                        <label style={{ color: 'black' }}>
                                            {'Variables Data'}
                                        </label>
                                        <Button
                                            type="primary"
                                            size="small"
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                showAddVariableDataModalVisible
                                            }
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            onClick={
                                                showReorderVariableDataModal
                                            }
                                            type="primary"
                                            size="small"
                                            style={{ marginLeft: 10 }}
                                        >
                                            Reorder
                                        </Button>
                                    </div>
                                    <Table
                                        columns={variablesDataTableColumns}
                                        dataSource={variablesData}
                                        pagination={false}
                                        size="small"
                                        loading={loading}
                                        rowKey="id"
                                        style={{ marginTop: 10 }}
                                    />
                                </div>
                            </>
                        ) : null}
                        {selectedType === TYPE_NOTIFICATION ? (
                            <>
                                <Form.Item
                                    label="Notification Title"
                                    style={{ marginBottom: 5 }}
                                    name="notificationTitle"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) =>
                                            setNotificationTitle(e.target.value)
                                        }
                                    />
                                </Form.Item>
                                {notificationTitle &&
                                notificationTitle.length ? (
                                    <>
                                        <span style={{ marginLeft: 145 }}>
                                            {notificationTitle.length +
                                                ' characters'}
                                        </span>
                                        {notificationTitle.length > 43 ? (
                                            <span
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'red',
                                                }}
                                            >
                                                {
                                                    '  (Recommended title length is 43 characters, beyond that it may get trimmed)'
                                                }
                                            </span>
                                        ) : null}
                                    </>
                                ) : null}
                                <Form.Item
                                    label="Notification Body"
                                    style={{ marginBottom: 5 }}
                                    name="notificationBody"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Form.Item
                                    label="Notification Image"
                                    style={{ marginBottom: 5 }}
                                    name="notificationImage"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <RouteData
                                    form={form}
                                    routeRequired={true}
                                    data={routeData}
                                />
                                {routeData ? (
                                    <span style={{ marginLeft: 145 }}>
                                        Current Route: {routeData}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default NudgeNotification
