import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import GLOBAL from '../../Globals'
import { RestApi } from '../../RestApi'

const TinyMceEditor = (props) => {
    return (
        <Editor
            apiKey="tp7pxqmrtk5iypcwqpvnp4f0ggwm6es0t68vg6ljv9oryj48"
            value={props.initialValue}
            init={{
                height: props.height ? props.height : 500,
                plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'code',
                    'help',
                    'wordcount',
                ],
                toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                menubar: 'insert format view tools edit table',
                default_link_target: '_blank',
                images_upload_handler: function (blobInfo) {
                    return new Promise((resolve, reject) => {
                        let formData = new FormData()
                        formData.append(
                            'file',
                            blobInfo.blob(),
                            blobInfo.filename()
                        )

                        RestApi.doPost(GLOBAL.URL.UPLOAD_TO_S3, formData)
                            .then((response) => {
                                var res = JSON.parse(response.data)
                                resolve(res.location)
                            })
                            .catch((err) => {
                                reject('HTTP Error: ' + err.message)
                            })
                    })
                },

                table_toolbar:
                    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                table_default_attributes: {
                    border: '1',
                },
            }}
            onEditorChange={props.handleEditorChange}
        />
    )
}

export default TinyMceEditor
