import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Select,
    Radio,
    DatePicker,
    InputNumber,
} from 'antd'
import {
    LoadingOutlined,
    ReloadOutlined,
    PlayCircleTwoTone,
} from '@ant-design/icons'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from '@mui/material'

function CloudCalls() {
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [adminUsers, setAdminUsers] = useState([])
    const [selectedAsignee, setSelectedAsignee] = useState('')
    const [playAudioModalVisible, setPlayAudioModalVisible] = useState(false)
    const [audioUrl, setAudioUrl] = useState('')
    const [withConversationDurationOnly, setWithConversationDurationOnly] =
        useState('no')
    const [direction, setDirection] = useState('All')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [durationMinMins, setDurationMinMins] = useState(0)
    const [durationMaxMins, setDurationMaxMins] = useState(0)
    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedAsignee,
        withConversationDurationOnly,
        direction,
        startDate,
        endDate,
    ])

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
            setAdminUsers(res.data)
        })
    }

    const getData = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.CLOUD_PHONE.GET_CALLS +
                '?calledBy=' +
                selectedAsignee +
                '&withConversationDurationOnly=' +
                withConversationDurationOnly +
                '&direction=' +
                direction +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate +
                '&durationMinMins=' +
                durationMinMins +
                '&durationMaxMins=' +
                durationMaxMins
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName}
                </Option>
            )
        })

        return options
    }

    const onChangeAsignee = (value) => {
        setSelectedAsignee(value ? value : '')
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => (
                <span style={{ fontSize: 12 }}>{index + 1}</span>
            ),
        },
        {
            title: 'Called By',
            dataIndex: 'calledBy',
            key: 'calledBy',
            render: (text, record) => <span>{record.calledBy.firstName}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.recordingUrl ? (
                        <Button
                            type="link"
                            style={{ padding: 0, marginRight: 5 }}
                            onClick={() =>
                                showPlayAudioModal(record.recordingUrl)
                            }
                        >
                            <PlayCircleTwoTone
                                style={{
                                    marginLeft: 5,
                                    fontSize: 14,
                                }}
                            />
                        </Button>
                    ) : null}
                    {record.conversationDuration
                        ? record.conversationDurationString
                        : null}
                </span>
            ),
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            key: 'direction',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.direction}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.status}</span>
            ),
        },
        {
            title: 'Leg 1',
            dataIndex: 'leg1Status',
            key: 'leg1Status',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.leg1Status}</span>
            ),
        },
        {
            title: 'Leg 2',
            dataIndex: 'leg2Status',
            key: 'leg2Status',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.leg2Status}</span>
            ),
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
            render: (text, record) => (
                <Link
                    onClick={() =>
                        window.open(getLinkRoutePath(record), '_blank')
                    }
                    to={{
                        pathname: getLinkRoutePath(record),
                    }}
                    target="_blank"
                >
                    {record.callTo.firstName ? (
                        <span>
                            {record.callTo.firstName +
                                (record.callTo.lastName
                                    ? ' ' + record.callTo.lastName
                                    : '')}
                        </span>
                    ) : null}
                    <br />
                    <span>{record.callTo.phone}</span>
                </Link>
            ),
        },
    ]

    const getLinkRoutePath = (record) => {
        if (!record.externalLead) {
            return '/userdetails/' + record.callTo.id
        } else {
            return '/leads/external?searchQuery=' + record.callTo.phone
        }
    }

    const showPlayAudioModal = (url) => {
        setPlayAudioModalVisible(true)
        setAudioUrl(url)
    }

    const hidePlayAudioModal = () => {
        setPlayAudioModalVisible(false)
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    return (
        <div>
            <div>
                <Select
                    placeholder="Called By"
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 150 }}
                    onChange={onChangeAsignee}
                    value={selectedAsignee || undefined}
                >
                    {getAdminUserOptions()}
                </Select>
                <Radio.Group
                    onChange={(e) =>
                        setWithConversationDurationOnly(e.target.value)
                    }
                    value={withConversationDurationOnly}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={'no'} key={'no'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value={'yes'} key={'yes'}>
                        {'With Duration'}
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={(e) => setDirection(e.target.value)}
                    value={direction}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={'All'} key={'All'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value={'Outgoing'} key={'Outgoing'}>
                        {'Out'}
                    </Radio.Button>
                    <Radio.Button value={'Incoming'} key={'Incoming'}>
                        {'In'}
                    </Radio.Button>
                </Radio.Group>
                <RangePicker
                    format="DD-MMM-YY HH:mm"
                    showTime
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateFilterChange}
                    style={{ marginLeft: 10, width: 300 }}
                />
                <span style={{ marginLeft: 10 }}>
                    {'Duration: '}
                    <InputNumber
                        min={0}
                        max={99999}
                        defaultValue={durationMinMins}
                        step={1}
                        onChange={(value) => setDurationMinMins(value)}
                        style={{ width: 60 }}
                    />
                    <span style={{ marginLeft: 2 }}>{' - '}</span>
                    <InputNumber
                        min={0}
                        max={99999}
                        defaultValue={durationMaxMins}
                        step={1}
                        style={{ marginLeft: 2, width: 60 }}
                        onChange={(value) => setDurationMaxMins(value)}
                    />
                </span>
                <Button type="link" onClick={getData}>
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="id"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                open={playAudioModalVisible}
                title={'Phone Call Recording'}
                onCancel={hidePlayAudioModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <ReactAudioPlayer src={audioUrl} autoPlay controls />
            </Modal>
        </div>
    )
}

export default CloudCalls
