import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    DatePicker,
    Radio,
    Select,
    Checkbox,
    message,
    Popconfirm,
    Form,
} from 'antd'

const UserReport = () => {
    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [selectedStream, setSelectedStream] = useState('')
    const [attempts, setAttempts] = useState([])
    const [joinedStartDate, setJoinedStartDate] = useState('')
    const [joinedEndDate, setJoinedEndDate] = useState('')
    const [lastLoginStartDate, setLastLoginStartDate] = useState('')
    const [lastLoginEndDate, setLastLoginEndDate] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })

        RestApi.doGet(
            GLOBAL.URL.GET_UNIQUE_ATTEMPT_NAMES_FROM_USER_PROFILE
        ).then((res) => {
            setAttempts(res.data)
        })
    }

    const onJoiningDateChange = (value, dateString) => {
        setJoinedStartDate(dateString[0])
        setJoinedEndDate(dateString[1])
    }

    const onlastLoginDateChange = (value, dateString) => {
        setLastLoginStartDate(dateString[0])
        setLastLoginEndDate(dateString[1])
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setSelectedStream(e.target.value)
        setLevelsData(levels)
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.joinedStartDate = joinedStartDate
                values.joinedEndDate = joinedEndDate
                values.lastLoginStartDate = lastLoginStartDate
                values.lastLoginEndDate = lastLoginEndDate

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.GENERATE_USER_REPORT, body)
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const generateDndReport = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.GENERATE_DND_SMS_REPORT)
            .then((res) => {
                message.success(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const { RangePicker } = DatePicker
    const dateFormat = 'DD-MMM-YY'
    const { Option } = Select

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    var streamOptions = []
    streamData.forEach((item) => {
        streamOptions.push(
            <Radio.Button value={item.course} key={item.course}>
                {item.course}
            </Radio.Button>
        )
    })

    var levelOptions = []
    levelsData.forEach((item) => {
        levelOptions.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    var attemptList = []
    attempts.forEach((item) => {
        attemptList.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    return (
        <div>
            <h2>Generate User Report</h2>
            <Form
                form={form}
                initialValues={{
                    excludeAccountsWithNoPhone: false,
                    phoneNumbersOnly: false,
                    excludeDnd: false,
                    includeSource: false,
                    includeLastLoginDate: false,
                    includeMcqTestsTaken: false,
                    includeFreeCourseMins: false,
                }}
                layout="horizontal"
                {...formItemLayout}
            >
                <Form.Item
                    label="Joining Date Range"
                    style={{ marginBottom: 10 }}
                    name="joiningDateRange"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onJoiningDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Select course"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="course"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeStream}>
                        {streamOptions}
                    </Radio.Group>
                </Form.Item>
                {selectedStream ? (
                    <Form.Item
                        label="Select level"
                        style={{ marginBottom: 10, lineHeight: 0 }}
                        name="level"
                        rules={[
                            {
                                required: true,
                                message: 'Please select level',
                            },
                        ]}
                    >
                        <Radio.Group>{levelOptions}</Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label="Attempt"
                    style={{ marginBottom: 10 }}
                    name="attempt"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        style={{ width: 200 }}
                    >
                        {attemptList}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Exclude users with no phone?"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="excludeAccountsWithNoPhone"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Purchase Status"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="purchasedStatus"
                    rules={[
                        {
                            message: 'Please select',
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value="all" key="all">
                            Purchased + Not Purchased
                        </Radio.Button>
                        <Radio.Button value="yes" key="yes">
                            Purchased
                        </Radio.Button>
                        <Radio.Button value="no" key="no">
                            Not Purchased
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Last Login Date Range"
                    style={{ marginBottom: 0 }}
                    name="lastLoginDateRange"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onlastLoginDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Get phone numbers only"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="phoneNumbersOnly"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Exclude DND"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="excludeDnd"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Include Source"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="includeSource"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Include Last Login Date"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="includeLastLoginDate"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Include MCQ Tests Taken"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="includeMcqTestsTaken"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Include Free Course Mins"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="includeFreeCourseMins"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={() => generateReport()}
                style={{ marginTop: 20, marginLeft: 267 }}
            >
                Generate User Report
            </Button>
            <div style={{ marginTop: 20 }}>
                <h3>Other reports</h3>
                <div style={{ marginTop: 10 }}>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => generateDndReport()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button loading={loading}>Generate DND Report</Button>
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
}

export default UserReport
