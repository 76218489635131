import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, message, Modal, Input, Popconfirm } from 'antd'
import { DeleteTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { goBack } from '../../Util/ILUtil'

class CouponUserMapping extends React.Component {
    state = {
        id: '',
        loading: false,
        showModal: false,
        searchUserData: [],
        data: [],
        couponName: '',
    }

    componentDidMount() {
        const { id, couponName } = this.props.location.state
        this.setState({ id: id, couponName: couponName }, () => {
            this.getData()
        })
    }

    getData(id) {
        this.setState({
            loading: true,
        })

        RestApi.doGet(GLOBAL.URL.GET_COUPON_USER_MAPPING + this.state.id)
            .then((res) => {
                this.setState({
                    data: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    showSelectModal = () => {
        this.setState({ showModal: true })
    }

    hideSelectModal = () => {
        this.setState({ showModal: false, searchUserData: [] })
    }

    searchUser = (query) => {
        this.setState({
            loading: true,
        })

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                this.setState({
                    searchUserData: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    addUser = (record) => {
        this.setState({ loading: true })

        var userIds = []
        userIds.push(record.id)

        var payload = {}
        payload.id = this.state.id
        payload.userIds = userIds

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_USER_TO_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.hideSelectModal()
                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    removeUser = (id) => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.REMOVE_USER_FROM_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    removeAllUsers = () => {
        var payload = {}
        payload.id = this.state.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.CLEAR_USERS_FROM_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const { Search } = Input

        const columns = [
            {
                title: 'Delete',
                dataIndex: 'delete',
                key: 'delete',
                width: 80,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.removeUser(record.id, true)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <DeleteTwoTone
                                style={{ fontSize: 20 }}
                                twoToneColor="#eb2f96"
                            />
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (text, record) => <span>{record.user.firstName}</span>,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                render: (text, record) => <span>{record.user.lastName}</span>,
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (text, record) => <span>{record.user.phone}</span>,
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => <span>{record.user.email}</span>,
            },
        ]

        const searchColumns = [
            {
                title: 'Add',
                dataIndex: 'add',
                key: 'add',
                width: 50,
                render: (text, record) => (
                    <Button
                        type="link"
                        onClick={() => {
                            this.addUser(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <PlusCircleTwoTone style={{ fontSize: 20 }} />
                    </Button>
                ),
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
            },
        ]

        return (
            <div>
                <h3>
                    Coupon: '<strong>{this.state.couponName}</strong>'
                </h3>
                <Button
                    onClick={() => {
                        goBack(this.props.navigate)
                    }}
                    size="small"
                >
                    Go Back
                </Button>
                <Button
                    type="primary"
                    onClick={() => this.showSelectModal()}
                    size="small"
                    style={{ marginLeft: 10 }}
                >
                    Add User
                </Button>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        this.removeAllUsers()
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                    disabled={this.state.data.length === 0}
                >
                    <Button
                        size="small"
                        style={{ marginLeft: 10 }}
                        disabled={this.state.data.length === 0}
                    >
                        Remove All Users
                    </Button>
                </Popconfirm>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={false}
                    loading={this.state.loading}
                    size="small"
                    style={{ marginTop: 10 }}
                />
                <Modal
                    title="Add Users"
                    open={this.state.showModal}
                    onCancel={this.hideSelectModal}
                    destroyOnClose={true}
                    width={800}
                    footer={null}
                >
                    <Search
                        placeholder="Search User"
                        onSearch={(value) => this.searchUser(value)}
                        autoFocus
                    />
                    {this.state.searchUserData.length !== 0 ? (
                        <Table
                            columns={searchColumns}
                            dataSource={this.state.searchUserData}
                            pagination={false}
                            loading={this.state.loading}
                            size="small"
                            style={{ marginTop: 8 }}
                        />
                    ) : null}
                </Modal>
            </div>
        )
    }
}

export default CouponUserMapping
