import React, { useEffect, useState } from 'react'
import {
    Input,
    Button,
    message,
    Select,
    Checkbox,
    Descriptions,
    Table,
    Modal,
    InputNumber,
    Popconfirm,
    DatePicker,
    Radio,
    notification,
    Form,
} from 'antd'
import {
    CopyOutlined,
    EditTwoTone,
    MinusCircleOutlined,
    PlusCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Utils } from '../JS/Utils'
import MetaFormItems from '../Common/MetaFormItems'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

var EDIT_TYPE_COURSE_DETAILS = 'details'
var EDIT_TYPE_BUNDLE_INFO = 'bundle'
var EDIT_TYPE_FACULTY = 'faculty'
var EDIT_TYPE_DESCRIPTION_WEB = 'description_web'

var VIEW_ALL = 'All'
var VIEW_COURSE_DETAILS = 'Course Details'
var VIEW_PRICE = 'Price'
var VIEW_COURSE_BOOKS = 'Books'
var VIEW_ADD_ONS = 'Add Ons'
var VIEW_DESCRIPTION_WEB = 'Description'
var VIEW_FACULTY = 'Faculty'

const ConfigureCourse = ({ navigate }) => {
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState('')
    const [data, setData] = useState({
        details: {
            learn: [],
            seo: {
                meta: null,
                title: '',
            },
        },
    })
    const [courses, setCourses] = useState([])
    const [facultyData, setFacultyData] = useState([])
    const [editType, setEditType] = useState('')
    const [firstFetchDone, setFirstFetchDone] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [attemptData, setAttemptData] = useState([])
    const [content, setContent] = useState('')
    const [selectedPriceRecord, setSelectedPriceRecord] = useState({})
    const [editPriceModalVisible, setEditPriceModalVisible] = useState(false)
    const [bundleData, setBundleData] = useState([])
    const [addCourseToBundleModalVisible, setAddCourseToBundleModalVisible] =
        useState(false)
    const [
        facultyCourseWeightageDateRanges,
        setFacultyCourseWeightageDateRanges,
    ] = useState([])
    const [
        selectedFacultyCourseWeightageDateRanges,
        setSelectedFacultyCourseWeightageDateRanges,
    ] = useState({})
    const [facultyCourseWeightage, setFacultyCourseWeightage] = useState([])
    const [
        facultyCourseWeightageDateRangeModalVisible,
        setFacultyCourseWeightageDateRangeModalVisible,
    ] = useState(false)
    const [
        facultyCourseWeightageDateRangeStartDate,
        setFacultyCourseWeightageDateRangeStartDate,
    ] = useState('')
    const [
        facultyCourseWeightageDateRangeEndDate,
        setFacultyCourseWeightageDateRangeEndDate,
    ] = useState('')
    const [
        addFacultyCourseWeightageDateRange,
        setAddFacultyCourseWeightageDateRange,
    ] = useState(false)
    const [
        addCourseFacultyWeightageVisible,
        setAddCourseFacultyWeightageVisible,
    ] = useState(false)
    const [
        updateCourseFacultyWeightageVisible,
        setUpdateCourseFacultyWeightageVisible,
    ] = useState(false)
    const [
        selectedFacultyCourseMappingWeightage,
        setSelectedFacultyCourseMappingWeightage,
    ] = useState({})
    const [selectedView, setSelectedView] = useState(VIEW_ALL)
    const [totalWeightage, setTotalWeightage] = useState(0)
    const [booksInCourse, setBooksInCourse] = useState([])
    const [booksData, setBooksData] = useState([])
    const [addBookModalVisible, setAddBookModalVisible] = useState(false)
    const [booksDataNextUrl, setBooksDataNextUrl] = useState('')
    const [loadingBooks, setLoadingBooks] = useState(false)
    const [resetCacheLoading, setResetCacheLoading] = useState(false)
    const [addOns, setAddOns] = useState([])
    const [addAdOnModalVisible, setAddAdOnModalVisible] = useState(false)
    const [isIndModule, setIsIndModule] = useState(false)
    const [form] = Form.useForm()

    const { id: paramsId } = useParams()

    useEffect(() => {
        setId(paramsId)
        getData(paramsId)
        //eslint-disable-next-line
    }, [])

    const getData = (idNew = id) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_COURSE_BY_ID + idNew)
            .then((res) => {
                var details = JSON.parse(res.data.details)
                res.data.details = details

                setData(res.data)
                setAddOns(res.data.addOns)
                getBooksInCourse(res.data)
                if (res.data.bundle) {
                    getBundleData(res.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })

        if (firstFetchDone) {
            return
        }

        if (Utils.isUserAdmin()) {
            RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY).then((res) => {
                setFacultyData(res.data)
            })

            getFacultyCourseWeightageDateRange(idNew)
            setFirstFetchDone(true)
        }
    }

    const getBooksInCourse = (dataNew = data) => {
        RestApi.doGet(
            GLOBAL.URL.BOOKS.GET_BOOKS_IN_COURSE + dataNew.courseId
        ).then((res) => {
            setBooksInCourse(res.data)
        })
    }

    const getBundleData = (dataNew = data) => {
        RestApi.doGet(GLOBAL.URL.GET_COURSES_IN_BUNDLE + dataNew.id).then(
            (res) => {
                setBundleData(res.data)
            }
        )
    }

    const getFacultyNames = () => {
        if (!data.faculty) {
            return ''
        }

        var response = ''
        data.faculty.forEach((faculty) => {
            facultyData.forEach((facultyData) => {
                if (facultyData.id === faculty) {
                    if (response) {
                        response = response + ', '
                    }

                    response =
                        response +
                        facultyData.firstName +
                        ' ' +
                        facultyData.lastName
                }
            })
        })

        return response
    }

    const createMarkupWeb = () => {
        return { __html: data.details.overview }
    }

    const setEditTypeData = (editType) => {
        var length = 0
        var whatYouWillLearn = [0]
        var learnArrayFields = [0]
        if (data.details.learn) {
            if (data.details.learn.length) {
                whatYouWillLearn = []
                learnArrayFields = []
            }

            data.details.learn.forEach((item) => {
                var learn = {}
                learn.name = item

                whatYouWillLearn.push(learn)
            })

            length = data.details.learn.length

            for (var j = 0; j < length; j++) {
                learnArrayFields.push(j)
            }
        }

        var metaArrayFields = [0]
        var meta = []
        if (data.details.seo && data.details.seo.meta) {
            if (data.details.seo.meta.length) {
                metaArrayFields = []
            }

            meta = data.details.seo.meta
            length = data.details.seo.meta.length

            for (var k = 0; k < length; k++) {
                metaArrayFields.push(k)
            }
        }

        setEditType(editType)
        setIsIndModule(data.indModule)
        form.setFieldsValue(data)
        form.setFieldsValue({
            course: data.stream.course,
            level: data.stream.level,
        })

        if (editType === EDIT_TYPE_COURSE_DETAILS) {
            if (courses.length === 0) {
                RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                    (res) => {
                        setCourses(res.data)
                    }
                )
            }

            if (streamData.length === 0) {
                RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
                    var streamData = JSON.parse(res.data)
                    setStreamData(streamData)
                })
            }

            handleCourseChange(data.stream.course, data.stream.level)
        } else if (editType === EDIT_TYPE_DESCRIPTION_WEB) {
            setContent(data.details.overview)
            setTimeout(() => {
                form.setFieldsValue({
                    learn: whatYouWillLearn,
                    meta: meta,
                    title: data.details.seo ? data.details.seo.title : '',
                })
            }, 100)
        }
    }

    const hideModal = () => {
        setEditType('')
    }

    const handleCourseChange = (value, level) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                return
            }
        })
    }

    const updateCourseDetails = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values['id'] = id
                values['courseName'] = values.name

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_COURSE_BASIC_DETAILS, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateBundleInfo = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values['id'] = id

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_COURSE_BUNDLE_INFO, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateFaculty = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values['id'] = id

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_COURSE_FACULTY, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const addOrUpdatePrice = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_COURSE_PRICE
                values['courseId'] = id

                if (selectedPriceRecord.id) {
                    url = GLOBAL.URL.UPDATE_COURSE_PRICE
                    values['id'] = selectedPriceRecord.id
                }

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideEditPriceModal(0)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateDescriptionWeb = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var filtered = values.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                var learn = []
                values.learn.forEach((item) => {
                    if (item.name) {
                        learn.push(item.name)
                    }
                })

                var seo = {}
                seo.title = values.title ? values.title : ''
                seo.meta = filtered

                var payload = {}
                payload.id = data.id
                payload.overview = content
                payload.learn = learn
                payload.seo = seo

                let body = new FormData()
                body.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_COURSE_DESCRIPTION, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const enableDisablePrice = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_COURSE_PRICE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_COURSE_PRICE
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var dataNew = data
                    dataNew.prices.forEach((data) => {
                        if (record.id === data.id) {
                            data.enabled = !record.enabled
                            return
                        }
                    })

                    setData(dataNew)
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deletePrice = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_PRICE, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const checkAndGetAttemptsData = () => {
        if (attemptData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS).then((res) => {
                setAttemptData(res.data)
            })
        }
    }

    const showAddPriceModal = () => {
        checkAndGetAttemptsData()
        setEditTypeData()
        setSelectedPriceRecord({})
        setEditPriceModalVisible(true)
    }

    const showEditPriceModal = (record) => {
        checkAndGetAttemptsData()
        setEditTypeData()
        setSelectedPriceRecord(record)
        setEditPriceModalVisible(true)
        form.setFieldsValue({
            strikePrice: record.strikePrice,
            price: record.price,
            attempt: record.attempt,
            lmsCourseId: record.lmsCourseId,
        })
    }

    const hideEditPriceModal = () => {
        setSelectedPriceRecord({})
        setEditPriceModalVisible(false)
    }

    const reorderPrices = () => {
        const columns = [
            {
                title: 'Attempt',
                dataIndex: 'attempt',
                key: 'attempt',
            },
            {
                title: 'Price',
                dataIndex: 'priceString',
                key: 'priceString',
            },
        ]

        navigate('/reorder', {
            state: {
                reorderData: data.prices,
                updateUrl: GLOBAL.URL.REORDER_COURSE_PRICES,
                columns: columns,
            },
        })
    }

    const enableDisableBundleCourse = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_COURSE_IN_BUNDLE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_COURSE_IN_BUNDLE
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var bundleDataNew = bundleData
                    bundleDataNew.forEach((data) => {
                        if (record.id === data.id) {
                            data.enabled = !record.enabled
                            return
                        }
                    })

                    setBundleData(bundleDataNew)
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteBundleCourse = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.REMOVE_COURSE_FROM_BUNDLE, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAddCourseToBundleModal = () => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setEditTypeData()
        setAddCourseToBundleModalVisible(true)
    }

    const hideAddCourseToBundleModal = () => {
        setAddCourseToBundleModalVisible(false)
    }

    const addCourseToBundle = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values['bundleCourseId'] = data.courseId

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ADD_COURSE_TO_BUNDLE, body)
                    .then((response) => {
                        message.success(response.data)
                        getData()
                        hideAddCourseToBundleModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const reorderCoursesinBundle = () => {
        const columns = [
            {
                title: 'Course Name',
                dataIndex: 'courseName',
                key: 'courseName',
            },
        ]

        navigate('/reorder', {
            state: {
                reorderData: bundleData,
                updateUrl: GLOBAL.URL.REORDER_COURSES_IN_BUNDLE,
                columns: columns,
            },
        })
    }

    const getFacultyCourseWeightageDateRange = (idNew) => {
        RestApi.doGet(
            GLOBAL.URL.GET_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE + idNew
        ).then((res) => {
            setFacultyCourseWeightageDateRanges(res.data)
            if (res.data.length > 0) {
                setSelectedFacultyCourseWeightageDateRanges(res.data[0])
                getFacultyCourseWeightage(res.data[0])
            }
        })
    }

    const getFacultyCourseWeightage = (
        courseWeightageDateRanges = selectedFacultyCourseWeightageDateRanges
    ) => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_FACULTY_COURSE_WEIGHTAGE +
                courseWeightageDateRanges.id
        )
            .then((res) => {
                var totalWeightage = 0
                res.data.forEach((row) => {
                    totalWeightage = totalWeightage + row.weightagePercent
                })
                setFacultyCourseWeightage(res.data)
                setTotalWeightage(totalWeightage.toFixed(2))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onFacultyCourseMappingDateRangeRowClick = (record) => {
        setSelectedFacultyCourseWeightageDateRanges(record)
        getFacultyCourseWeightage(record)
    }

    const showFacultyCourseWeightageDateRangeModal = (record) => {
        var facultyCourseWeightageDateRangeStartDate = ''
        var facultyCourseWeightageDateRangeEndDate = ''

        if (record) {
            facultyCourseWeightageDateRangeStartDate = record.startDate
            facultyCourseWeightageDateRangeEndDate = record.endDate

            var startDate = dayjs(record.startDate, 'DD-MMM-YYYY')
            var endDate = dayjs(record.endDate, 'DD-MMM-YYYY')
            setTimeout(() => {
                form.setFieldsValue({
                    startDate: startDate,
                    endDate: endDate,
                })
            }, 100)
        } else {
            form.resetFields()
        }

        setFacultyCourseWeightageDateRangeModalVisible(true)
        setAddFacultyCourseWeightageDateRange(record ? false : true)
        setFacultyCourseWeightageDateRangeStartDate(
            facultyCourseWeightageDateRangeStartDate
        )
        setFacultyCourseWeightageDateRangeEndDate(
            facultyCourseWeightageDateRangeEndDate
        )
        setSelectedFacultyCourseWeightageDateRanges(record)
    }

    const hideFacultyCourseWeightageDateRangeModal = () => {
        setFacultyCourseWeightageDateRangeModalVisible(false)
    }

    const onChangeFacultyCourseWeightageDateRangeStartDate = (
        value,
        dateString
    ) => {
        setFacultyCourseWeightageDateRangeStartDate(dateString)
    }

    const onChangeFacultyCourseWeightageDateRangeEndDate = (
        value,
        dateString
    ) => {
        setFacultyCourseWeightageDateRangeEndDate(dateString)
    }

    const addOrUpdateFacultyCourseWeightageDateRange = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.courseId = data.courseId
                payload.startDate = facultyCourseWeightageDateRangeStartDate
                payload.endDate = facultyCourseWeightageDateRangeEndDate
                if (!addFacultyCourseWeightageDateRange) {
                    payload.id = selectedFacultyCourseWeightageDateRanges.id
                }

                let body = new FormData()
                body.append('payload', JSON.stringify(payload))

                var url = GLOBAL.URL.ADD_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE
                if (!addFacultyCourseWeightageDateRange) {
                    url = GLOBAL.URL.UPDATE_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE
                }

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        getFacultyCourseWeightageDateRange(data.courseId)
                        hideFacultyCourseWeightageDateRangeModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteFacultyCourseMappingDateRange = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.DELETE_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE,
            body
        )
            .then((response) => {
                message.success(response.data)
                getFacultyCourseWeightageDateRange()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddCourseFacultyWeightageVisible = () => {
        setAddCourseFacultyWeightageVisible(true)
    }

    const hideAddCourseFacultyWeightageVisible = () => {
        setAddCourseFacultyWeightageVisible(false)
    }

    const addCourseFacultyWeightage = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.dateRangeId =
                    selectedFacultyCourseWeightageDateRanges.id
                payload.facultyIds = values.facultyIds

                let body = new FormData()
                body.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.ADD_FACULTY_COURSE_WEIGHTAGE, body)
                    .then((response) => {
                        message.success(response.data)
                        getFacultyCourseWeightage()
                        hideAddCourseFacultyWeightageVisible()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateCourseFacultyWeightageVisible = (record) => {
        setUpdateCourseFacultyWeightageVisible(true)
        setSelectedFacultyCourseMappingWeightage(record)
        if (record.id) {
            form.setFieldsValue({ percent: record.weightagePercent })
        } else {
            form.resetFields()
        }
    }

    const hideUpdateCourseFacultyWeightageVisible = () => {
        setUpdateCourseFacultyWeightageVisible(false)
    }

    const updateCourseFacultyWeightage = () => {
        form.validateFields(['percent'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.id = selectedFacultyCourseMappingWeightage.id
                payload.percent = values.percent

                let body = new FormData()
                body.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_FACULTY_COURSE_WEIGHTAGE, body)
                    .then((response) => {
                        message.success(response.data)
                        getFacultyCourseWeightage()
                        hideUpdateCourseFacultyWeightageVisible()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteCourseFacultyWeightage = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_FACULTY_COURSE_WEIGHTAGE, body)
            .then((response) => {
                message.success(response.data)
                getFacultyCourseWeightage()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeViewType = (event) => {
        setSelectedView(event.target.value)
    }

    const navigateToDemoVideos = () => {
        navigate('/demovideos', {
            state: { courseName: data.name },
        })
    }

    const getBooks = (query) => {
        setLoadingBooks(true)

        RestApi.doGet(GLOBAL.URL.BOOKS.GET_BOOKS + query)
            .then((response) => {
                var res = JSON.parse(response.data)
                setBooksData(res.data)
                setBooksDataNextUrl(res.next)
            })
            .finally(() => {
                setLoadingBooks(false)
            })
    }

    const getNextPageBooks = () => {
        if (!booksDataNextUrl) {
            return
        }

        setLoadingBooks(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + booksDataNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setBooksData([...booksData, ...res.data])
                setBooksDataNextUrl(res.next)
            })
            .finally(() => {
                setLoadingBooks(false)
            })
    }

    const showAddBookModal = () => {
        getBooks('')
        setAddBookModalVisible(true)
    }

    const hideAddBookModal = () => {
        setAddBookModalVisible(false)
    }

    const addBookToCourse = (bookUid) => {
        setLoadingBooks(true)

        var payload = {}
        payload.courseId = data.courseId
        payload.bookUid = bookUid

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.ADD_BOOK_TO_COURSE, body)
            .then((response) => {
                message.success(response.data)
                hideAddBookModal()
                getBooksInCourse()
            })
            .finally(() => {
                setLoadingBooks(false)
            })
    }

    const deleteBookFromCourse = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.REMOVE_BOOK_TO_COURSE, body)
            .then((response) => {
                message.success(response.data)
                getBooksInCourse()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorderBooks = () => {
        const columns = [
            {
                title: 'Book Name',
                dataIndex: 'book',
                key: 'book',
            },
        ]

        navigate('/reorder', {
            state: {
                reorderData: booksInCourse,
                updateUrl: GLOBAL.URL.BOOKS.REORDER_BOOK_TO_COURSE,
                columns: columns,
            },
        })
    }

    const enableDisableBook = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ uid: record.uid }))
        let url = GLOBAL.URL.BOOKS.ENABLE_BOOK_TO_COURSE
        if (record.enabled) {
            url = GLOBAL.URL.BOOKS.DISABLE_BOOK_TO_COURSE
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = booksInCourse
                    data.forEach((data) => {
                        if (record.uid === data.uid) {
                            data.enabled = !record.enabled
                            return
                        }
                    })

                    setBooksInCourse(data)
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisableVisibleToStudentBook = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ uid: record.uid }))
        let url = GLOBAL.URL.BOOKS.ENABLE_BOOK_IN_COURSE_VISIBLE_TO_STUDENT
        if (record.visibleToStudent) {
            url = GLOBAL.URL.BOOKS.DISABLE_BOOK_IN_COURSE_VISIBLE_TO_STUDENT
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = booksInCourse
                    data.forEach((data) => {
                        if (record.uid === data.uid) {
                            data.visibleToStudent = !record.visibleToStudent
                            return
                        }
                    })

                    setBooksInCourse(data)
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddAddOnModal = () => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setAddAdOnModalVisible(true)
    }

    const hideAddAddOnModal = () => {
        setAddAdOnModalVisible(false)
    }

    const addAddOnCourse = () => {
        form.validateFields(['addOnCourseId'])
            .then((values) => {
                setLoading(true)

                values['courseId'] = id

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.COURSE.ADD_COURSE_ADD_ON, body)
                    .then((response) => {
                        message.success(response.data)
                        getCourseAddOns()
                        hideAddAddOnModal()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getCourseAddOns = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.COURSE.GET_COURSE_ADD_ONS + id)
            .then((res) => {
                setAddOns(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enabledDisableAddOn = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ uid: record.uid }))
        let url = GLOBAL.URL.COURSE.ENABLE_COURSE_ADD_ON
        if (record.enabled) {
            url = GLOBAL.URL.COURSE.DISABLE_COURSE_ADD_ON
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                message.success(response.data)
                getCourseAddOns()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteAddOn = (uid) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ uid: uid }))

        RestApi.doPost(GLOBAL.URL.COURSE.DELETE_COURSE_ADD_ON, postBody)
            .then((response) => {
                message.success(response.data)
                getCourseAddOns()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorderAddOns = () => {
        const columns = [
            {
                title: 'Course Name',
                dataIndex: 'courseName',
                key: 'courseName',
            },
        ]

        var reorderData = []
        addOns.forEach((addOn) => {
            reorderData.push({
                uid: addOn.uid,
                courseName: addOn.addOnCourse.name,
            })
        })

        navigate('/reorder', {
            state: {
                reorderData: reorderData,
                updateUrl: GLOBAL.URL.COURSE.REORDER_COURSE_ADD_ON,
                columns: columns,
            },
        })
    }

    const resetCache = () => {
        setResetCacheLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ courseId: id }))

        RestApi.doPost(GLOBAL.URL.RESET_COURSE_CACHE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setResetCacheLoading(false)
            })
    }

    const deleteCourse = () => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ courseId: id }))

        RestApi.doPost(GLOBAL.URL.COURSE.DELETE_COURSE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                goBack(navigate)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleIndivudalModule = (e) => {
        setIsIndModule(e.target.checked)
    }

    const archiveUnarchiveCourse = () => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ courseId: id }))

        var url = GLOBAL.URL.COURSE.ARCHIVE_COURSE
        if (data.archived) {
            url = GLOBAL.URL.COURSE.UNARCHIVE_COURSE
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                var dataNew = data
                dataNew.archived = !dataNew.archived

                setData(dataNew)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const { Option } = Select
    const { Search } = Input

    const priceColumns = [
        {
            title: 'Enabled',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisablePrice(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                        float: 'left',
                        marginRight: 10,
                    }}
                    onClick={() => showEditPriceModal(record)}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 100,
        },
        {
            title: 'Strike Price',
            dataIndex: 'strikePrice',
            key: 'strikePrice',
            width: 150,
            render: (text, record) => (
                <>
                    {record.strikePrice
                        ? record.strikePriceString +
                          ' (' +
                          record.strikePriceDiscount +
                          '%)'
                        : null}
                </>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'priceString',
            key: 'priceString',
            width: 150,
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deletePrice(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    var bookColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 70,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisableBook(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Visible To Student',
            dataIndex: 'visibleToStudent',
            key: 'visibleToStudent',
            width: 70,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisableVisibleToStudentBook(record)
                    }}
                >
                    {record.visibleToStudent ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Book Name',
            dataIndex: 'book',
            key: 'book',
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteBookFromCourse(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    var addOnColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 70,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                    }}
                    onClick={() => {
                        enabledDisableAddOn(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                    }}
                    onClick={() => {
                        window.open(
                            '/course/configure/' + record.addOnCourse.courseId,
                            '_blank'
                        )
                    }}
                >
                    {record.addOnCourse.name}
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteAddOn(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    var searchBookColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Add',
            dataIndex: 'add',
            key: 'add',
            width: 75,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => addBookToCourse(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" size="small">
                        Add
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.name}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {'(' + record.code + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>
                    {record.stream
                        ? record.stream.course + ' - ' + record.stream.level
                        : ''}
                </span>
            ),
        },
    ]

    var bundleCourseColumns = [
        {
            title: 'Enabled',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisableBundleCourse(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                    }}
                    onClick={() => {
                        window.open(
                            '/course/configure/' + record.courseId,
                            '_blank'
                        )
                    }}
                >
                    {record.courseName}
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {bundleData.length !== 1 ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteBundleCourse(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{ border: 'none', color: 'red' }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </span>
            ),
        },
    ]

    const seoMetaColumns = [
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
        },
    ]

    const facultyCourseDateRangeColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                        float: 'left',
                        marginRight: 10,
                    }}
                    onClick={() =>
                        showFacultyCourseWeightageDateRangeModal(record)
                    }
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 150,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            deleteFacultyCourseMappingDateRange(record.id)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{ border: 'none', color: 'red' }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const facultyCourseWeightageColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                        float: 'left',
                        marginRight: 10,
                    }}
                    onClick={() =>
                        showUpdateCourseFacultyWeightageVisible(record)
                    }
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Weightage (%)',
            dataIndex: 'weightagePercent',
            key: 'weightagePercent',
            width: 150,
        },
        {
            title: 'Faculty',
            dataIndex: 'facultyName',
            key: 'facultyName',
        },
        {
            title: 'Delete',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            deleteCourseFacultyWeightage(record.id)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{ border: 'none', color: 'red' }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    var streamDetails = []
    streamData.forEach((item) => {
        streamDetails.push(<Option value={item.course}>{item.course}</Option>)
    })

    var levelDetails = []
    levelData.forEach((item) => {
        levelDetails.push(<Option value={item}>{item}</Option>)
    })

    var allCourses = []
    courses.forEach((item) => {
        allCourses.push(
            <Option value={item.name} key={item.name}>
                {item.name}
            </Option>
        )
    })

    var allCoursesWithIds = []
    courses.forEach((item) => {
        allCoursesWithIds.push(
            <Option value={item.courseId} key={item.name}>
                {item.name}
            </Option>
        )
    })

    var facultyDetails = []
    facultyData.forEach((item) => {
        facultyDetails.push(
            <Option value={item.id}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    })

    var attemptDetails = []
    attemptData.forEach((item) => {
        attemptDetails.push(
            <Option value={item.attempt} key={item.attempt}>
                {item.attempt}
            </Option>
        )
    })

    const learnFormItems = () => {
        return (
            <Form.List name="learn">
                {(fields, { add, remove }) => (
                    <>
                        {fields.length === 0 && add()}
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    gap: '8px',
                                }}
                            >
                                <Form.Item
                                    label=""
                                    key={key}
                                    style={{ marginBottom: 10, width: '90%' }}
                                    name={[name, 'name']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Mandatory field!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter data"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label={''}>
                                    {fields.length > 1 && (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        ))}

                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                style={{ width: '60%' }}
                            >
                                Add Learn
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    }

    var learn = data.details.learn
        ? data.details.learn.map((learn, index) => <li key={index}>{learn}</li>)
        : null

    return (
        <div>
            {data ? (
                <div>
                    <h2>
                        {data.name}
                        <CopyToClipboard
                            text={data.deepLink}
                            onCopy={() =>
                                notification['success']({
                                    message: 'Link copied!',
                                })
                            }
                            style={{ marginLeft: 15 }}
                        >
                            <span>
                                <Button type="link" style={{ padding: 0 }}>
                                    <CopyOutlined />
                                    {' Deep Link'}
                                </Button>
                            </span>
                        </CopyToClipboard>
                    </h2>
                    <div>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => {
                                navigate('/courses')
                            }}
                        >
                            Search Course
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                navigate('/modules/' + data.courseId, {
                                    state: { courseId: data.courseId },
                                })
                            }}
                        >
                            Modules List
                        </Button>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => resetCache()}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="default"
                                size="small"
                                style={{ marginLeft: 20 }}
                                loading={resetCacheLoading}
                            >
                                Reset Cache
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => archiveUnarchiveCourse()}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="default"
                                size="small"
                                style={{ marginLeft: 20 }}
                                loading={resetCacheLoading}
                            >
                                {data.archived ? 'Unarchive' : 'Archive'}
                            </Button>
                        </Popconfirm>
                        {Utils.isSuperUserAdmin() ? (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteCourse()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="default"
                                    size="small"
                                    style={{ marginLeft: 20 }}
                                    loading={loading}
                                >
                                    Delete Course
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </div>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <div>
                            <Radio.Group
                                onChange={onChangeViewType}
                                defaultValue={selectedView}
                            >
                                <Radio.Button value={VIEW_ALL}>
                                    {VIEW_ALL}
                                </Radio.Button>
                                <Radio.Button value={VIEW_COURSE_DETAILS}>
                                    {VIEW_COURSE_DETAILS}
                                </Radio.Button>
                                <Radio.Button value={VIEW_PRICE}>
                                    {VIEW_PRICE}
                                </Radio.Button>
                                <Radio.Button value={VIEW_COURSE_BOOKS}>
                                    {VIEW_COURSE_BOOKS}
                                </Radio.Button>
                                <Radio.Button value={VIEW_ADD_ONS}>
                                    {VIEW_ADD_ONS}
                                </Radio.Button>
                                <Radio.Button value={VIEW_DESCRIPTION_WEB}>
                                    {VIEW_DESCRIPTION_WEB}
                                </Radio.Button>
                                <Radio.Button value={VIEW_FACULTY}>
                                    {VIEW_FACULTY}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_COURSE_DETAILS ? (
                        <>
                            <div>
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        float: 'left',
                                        marginRight: 10,
                                    }}
                                    onClick={() =>
                                        setEditTypeData(
                                            EDIT_TYPE_COURSE_DETAILS
                                        )
                                    }
                                >
                                    <EditTwoTone style={{ fontSize: 14 }} />
                                </Button>
                                <Descriptions
                                    title="Course Details"
                                    bordered
                                    size="small"
                                >
                                    <Descriptions.Item label="Name">
                                        <strong>{data.name}</strong>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Image" width>
                                        <a
                                            href={data.pictureUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            link
                                        </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Stream">
                                        {data.stream
                                            ? data.stream.course +
                                              ' - ' +
                                              data.stream.level
                                            : ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Duration">
                                        {data.duration}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Do not update duration">
                                        {data.fixDuration ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Illustrations #">
                                        {data.illustrationsCount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Demo">
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                            onClick={navigateToDemoVideos}
                                        >
                                            Demo Videos
                                        </Button>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Full Group?">
                                        {data.fullGroup ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Individual Module?">
                                        {data.indModule ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Parent Course">
                                        {data.parentCourseName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="DSR Enabled?">
                                        {data.dsrEnabled ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="DSR Sequence">
                                        {data.dsrSequence}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="DSR Group">
                                        {data.dsrGroup}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="DSR Name">
                                        {data.dsrName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="iCash Disabled">
                                        {data.disableiCashUsage ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Subjects Count">
                                        {data.subjectsCount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Study Planner Enabled">
                                        {data.studyPlannerEnabled
                                            ? 'Yes'
                                            : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Coupons Disabled">
                                        {data.disableCouponUsage ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    {data.studyPlannerEnabled ? (
                                        <Descriptions.Item label="Study Planner Course Name">
                                            {data.studyPlannerCourseName}
                                        </Descriptions.Item>
                                    ) : null}
                                    <Descriptions.Item label="Books Only Course">
                                        {data.bookOnlyCourse ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Duration Views Limit">
                                        {data.durationViewsLimit
                                            ? data.durationViewsLimit
                                            : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Nudge Enabled">
                                        {data.nudgeEnabled ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        float: 'left',
                                        marginRight: 10,
                                    }}
                                    onClick={() =>
                                        setEditTypeData(EDIT_TYPE_BUNDLE_INFO)
                                    }
                                >
                                    <EditTwoTone style={{ fontSize: 14 }} />
                                </Button>
                                <Descriptions
                                    title="Bundle Info"
                                    bordered
                                    size="small"
                                >
                                    <Descriptions.Item label="Is Bundle?">
                                        {data.bundle ? 'YES' : 'NO'}
                                    </Descriptions.Item>
                                </Descriptions>
                                {data.bundle ? (
                                    <span>
                                        <Table
                                            columns={bundleCourseColumns}
                                            dataSource={bundleData}
                                            pagination={false}
                                            size="small"
                                            loading={loading}
                                            style={{ marginTop: 10 }}
                                            rowKey="id"
                                        />
                                        <div style={{ marginTop: 10 }}>
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() =>
                                                    showAddCourseToBundleModal()
                                                }
                                            >
                                                Add Course
                                            </Button>
                                            <Button
                                                size="small"
                                                style={{ marginLeft: 10 }}
                                                onClick={() =>
                                                    reorderCoursesinBundle()
                                                }
                                            >
                                                Reorder Course
                                            </Button>
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_PRICE ? (
                        <div>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    float: 'left',
                                    marginRight: 10,
                                }}
                                onClick={() => showAddPriceModal()}
                            >
                                <PlusCircleOutlined style={{ fontSize: 14 }} />
                            </Button>
                            <h3
                                style={{
                                    marginTop: 20,
                                    fontWeight: 'bold',
                                }}
                            >
                                Price
                            </h3>
                            <Table
                                columns={priceColumns}
                                dataSource={data.prices}
                                pagination={false}
                                size="small"
                                loading={loading}
                                style={{ marginTop: 10 }}
                                rowKey="attempt"
                            />
                            <Button
                                size="small"
                                style={{ marginTop: 10 }}
                                onClick={reorderPrices}
                            >
                                Reorder Prices
                            </Button>
                        </div>
                    ) : null}
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_COURSE_BOOKS ? (
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    float: 'left',
                                    marginRight: 10,
                                }}
                                onClick={() => showAddBookModal()}
                            >
                                <PlusCircleOutlined style={{ fontSize: 14 }} />
                            </Button>
                            <h3
                                style={{
                                    marginTop: 20,
                                    fontWeight: 'bold',
                                }}
                            >
                                Books
                            </h3>
                            <Table
                                columns={bookColumns}
                                dataSource={booksInCourse}
                                pagination={false}
                                size="small"
                                loading={loading}
                                style={{ marginTop: 10 }}
                                rowKey="book"
                            />
                            <Button
                                size="small"
                                style={{ marginTop: 10 }}
                                onClick={reorderBooks}
                            >
                                Reorder Books
                            </Button>
                        </div>
                    ) : null}
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_ADD_ONS ? (
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    float: 'left',
                                    marginRight: 10,
                                }}
                                onClick={() => showAddAddOnModal()}
                            >
                                <PlusCircleOutlined style={{ fontSize: 14 }} />
                            </Button>
                            <h3
                                style={{
                                    marginTop: 20,
                                    fontWeight: 'bold',
                                }}
                            >
                                Add Ons
                            </h3>
                            <Table
                                columns={addOnColumns}
                                dataSource={addOns}
                                pagination={false}
                                size="small"
                                loading={loading}
                                style={{ marginTop: 10 }}
                                rowKey="courseName"
                            />
                            <Button
                                size="small"
                                style={{ marginTop: 10 }}
                                onClick={reorderAddOns}
                            >
                                Reorder Add Ons
                            </Button>
                        </div>
                    ) : null}
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_DESCRIPTION_WEB ? (
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    float: 'left',
                                    marginRight: 10,
                                }}
                                onClick={() =>
                                    setEditTypeData(EDIT_TYPE_DESCRIPTION_WEB)
                                }
                            >
                                <EditTwoTone style={{ fontSize: 14 }} />
                            </Button>
                            <h3 style={{ fontWeight: 'bold' }}>Description</h3>
                            <h3>Overview</h3>
                            <div dangerouslySetInnerHTML={createMarkupWeb()} />
                            <h3 style={{ marginTop: 10 }}>
                                What you'll learn?
                            </h3>
                            <ul>{learn}</ul>
                            <h3 style={{ marginTop: 10 }}>SEO</h3>
                            <h4>Title: </h4>
                            <span>
                                {data.details.seo
                                    ? data.details.seo.title
                                    : '<Not Set>'}
                            </span>
                            <h4>Meta: </h4>
                            {data.details.seo ? (
                                <Table
                                    columns={seoMetaColumns}
                                    dataSource={data.details.seo.meta}
                                    pagination={false}
                                    size="small"
                                    loading={loading}
                                    style={{ marginTop: 10 }}
                                    rowKey="property"
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {selectedView === VIEW_ALL ||
                    selectedView === VIEW_FACULTY ? (
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    float: 'left',
                                    marginRight: 10,
                                }}
                                onClick={() =>
                                    setEditTypeData(EDIT_TYPE_FACULTY)
                                }
                            >
                                <EditTwoTone style={{ fontSize: 14 }} />
                            </Button>
                            <Descriptions title="Faculty" bordered size="small">
                                <Descriptions.Item label="Names">
                                    {getFacultyNames()}
                                </Descriptions.Item>
                            </Descriptions>
                            <div style={{ marginTop: 20 }}>
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        float: 'left',
                                        marginRight: 10,
                                    }}
                                    onClick={() =>
                                        showFacultyCourseWeightageDateRangeModal()
                                    }
                                >
                                    <PlusCircleOutlined
                                        style={{ fontSize: 14 }}
                                    />
                                </Button>
                                <h3 style={{ fontWeight: 'bold' }}>
                                    Faculty Course Mapping Date Range
                                </h3>
                                <Table
                                    columns={facultyCourseDateRangeColumns}
                                    dataSource={
                                        facultyCourseWeightageDateRanges
                                    }
                                    pagination={false}
                                    size="small"
                                    loading={loading}
                                    style={{ marginTop: 10 }}
                                    rowKey="startDate"
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                onFacultyCourseMappingDateRangeRowClick(
                                                    record
                                                )
                                            },
                                        }
                                    }}
                                    key={
                                        facultyCourseWeightageDateRanges.length
                                    }
                                />
                                <h3 style={{ marginTop: 20 }}>
                                    Faculty Weightage (
                                    {selectedFacultyCourseWeightageDateRanges &&
                                    selectedFacultyCourseWeightageDateRanges.startDate
                                        ? selectedFacultyCourseWeightageDateRanges.startDate +
                                          ' to ' +
                                          selectedFacultyCourseWeightageDateRanges.endDate
                                        : null}
                                    )
                                </h3>
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() =>
                                        showAddCourseFacultyWeightageVisible()
                                    }
                                >
                                    Add
                                </Button>
                                <div style={{ marginTop: 5 }}>
                                    <span>
                                        Total Weightage: {totalWeightage}
                                        {'%'}
                                    </span>
                                </div>
                                <Table
                                    columns={facultyCourseWeightageColumns}
                                    dataSource={facultyCourseWeightage}
                                    pagination={false}
                                    size="small"
                                    loading={loading}
                                    style={{ marginTop: 10 }}
                                    rowKey="facultyName"
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <Modal
                title="Course Details"
                open={editType === EDIT_TYPE_COURSE_DETAILS}
                onOk={() => {
                    updateCourseDetails()
                }}
                confirmLoading={loading}
                onCancel={hideModal}
                okText="Update"
                width={900}
                destroyOnClose={true}
            >
                {editType === EDIT_TYPE_COURSE_DETAILS ? (
                    <Form
                        form={form}
                        initialValues={{
                            studyPlannerEnabled: true,
                            disableCouponUsage: false,
                            disableiCashUsage: false,
                            dsrEnabled: false,
                            bookOnlyCourse: false,
                            indModule: false,
                            fullGroup: false,
                            fixDuration: false,
                            nudgeEnabled: false,
                        }}
                        layout="horizontal"
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="Course Name"
                            style={{ marginBottom: 0 }}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter name!',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                        <Form.Item
                            label="Picture URL"
                            style={{ marginBottom: 0 }}
                            name="pictureUrl"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter url!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Course Stream"
                            style={{ marginBottom: 0 }}
                            name="course"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                onChange={(value) =>
                                    handleCourseChange(value, '')
                                }
                                showSearch
                            >
                                {streamDetails}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Stream Level"
                            style={{ marginBottom: 0 }}
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select level',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select level"
                                showSearch
                            >
                                {levelDetails}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Duration"
                            style={{ marginBottom: 0 }}
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter duration!',
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Do not update duration"
                            style={{ marginBottom: 0 }}
                            name="fixDuration"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Illustrations#"
                            style={{ marginBottom: 0 }}
                            name="illustrationsCount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter duration!',
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Full Group?"
                            style={{ marginBottom: 0 }}
                            name="fullGroup"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Indivudal Module?"
                            style={{ marginBottom: 0 }}
                            name="indModule"
                            valuePropName="checked"
                        >
                            <Checkbox
                                defaultChecked={isIndModule}
                                onChange={handleIndivudalModule}
                            ></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Parent Course"
                            style={{ marginBottom: 0 }}
                            name="parentCourseName"
                            rules={[
                                {
                                    required: isIndModule,
                                    message: 'Select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                showSearch
                                allowClear
                            >
                                {allCourses}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Books Only?"
                            style={{ marginBottom: 0 }}
                            name="bookOnlyCourse"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="DSR Enabled?"
                            style={{ marginBottom: 0 }}
                            name="dsrEnabled"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="DSR Sequence"
                            style={{ marginBottom: 0 }}
                            name="dsrSequence"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter sequence!',
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            label="DSR Name"
                            style={{ marginBottom: 0 }}
                            name="dsrName"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="DSR Group"
                            style={{ marginBottom: 0 }}
                            name="dsrGroup"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Disable iCash"
                            style={{ marginBottom: 0 }}
                            name="disableiCashUsage"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Disable Coupons"
                            style={{ marginBottom: 0 }}
                            name="disableCouponUsage"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Subjects Count"
                            style={{ marginBottom: 0 }}
                            name="subjectsCount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter count!',
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            label="Study Planner Enabled?"
                            style={{ marginBottom: 0 }}
                            name="studyPlannerEnabled"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Study Planner Name"
                            style={{ marginBottom: 0 }}
                            name="studyPlannerCourseName"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Duration Views Limit"
                            style={{ marginBottom: 0 }}
                            name="durationViewsLimit"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} step={1.0} />
                        </Form.Item>
                        <Form.Item
                            label="Nudge Enabled?"
                            style={{ marginBottom: 0 }}
                            name="nudgeEnabled"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Bundle Info Update"
                open={editType === EDIT_TYPE_BUNDLE_INFO}
                onOk={() => {
                    updateBundleInfo()
                }}
                confirmLoading={loading}
                onCancel={hideModal}
                okText="Update"
                width={900}
                destroyOnClose={true}
            >
                {editType === EDIT_TYPE_BUNDLE_INFO ? (
                    <Form
                        form={form}
                        initialValues={{ bundle: false }}
                        layout="horizontal"
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="Bundle?"
                            name="bundle"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Add Course to Bundle"
                open={addCourseToBundleModalVisible}
                onOk={() => {
                    addCourseToBundle()
                }}
                confirmLoading={loading}
                onCancel={hideAddCourseToBundleModal}
                okText="Add"
                width={900}
                destroyOnClose={true}
            >
                {addCourseToBundleModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Select Course"
                            name="courseName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                showSearch
                            >
                                {allCourses}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Faculty Update"
                open={editType === EDIT_TYPE_FACULTY}
                onOk={() => {
                    updateFaculty()
                }}
                confirmLoading={loading}
                onCancel={hideModal}
                okText="Update"
                width={900}
                destroyOnClose={true}
            >
                <Form form={form}>
                    {editType === EDIT_TYPE_FACULTY ? (
                        <Form.Item
                            label="Select Faculty"
                            name="faculty"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select faculty!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select Faculty"
                                mode="multiple"
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                            >
                                {facultyDetails}
                            </Select>
                        </Form.Item>
                    ) : null}
                </Form>
            </Modal>
            <Modal
                title="Course Price"
                open={editPriceModalVisible}
                onOk={() => {
                    addOrUpdatePrice()
                }}
                confirmLoading={loading}
                onCancel={hideEditPriceModal}
                okText={selectedPriceRecord.id ? 'Update' : 'Add'}
                width={900}
                destroyOnClose={true}
            >
                {editPriceModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Attempt"
                            name="attempt"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select Attempt"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {attemptDetails}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Strike Price"
                            name="strikePrice"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100000} />
                        </Form.Item>
                        <Form.Item
                            label="Price"
                            name="price"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Price needed!',
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100000} />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Course Description - Web"
                open={editType === EDIT_TYPE_DESCRIPTION_WEB}
                onOk={() => {
                    updateDescriptionWeb()
                }}
                confirmLoading={loading}
                onCancel={hideModal}
                okText="Update"
                width={1200}
                destroyOnClose={true}
            >
                <h3>Overview</h3>
                <TinyMceEditor
                    height={300}
                    handleEditorChange={handleEditorChange}
                    initialValue={content}
                />
                <h3 style={{ marginTop: 10 }}>What you'll learn?</h3>
                {editType === EDIT_TYPE_DESCRIPTION_WEB ? (
                    <Form form={form} layout="horizontal">
                        {learnFormItems()}
                        <h3 style={{ marginTop: 10 }}>SEO: Title</h3>
                        <Form.Item
                            label=""
                            name="title"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                placeholder="SEO Title"
                                style={{ width: '90%', marginRight: 5 }}
                            />
                        </Form.Item>
                        <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                        <MetaFormItems />
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Faculty Course Weightage Date Range"
                open={facultyCourseWeightageDateRangeModalVisible}
                onOk={() => {
                    addOrUpdateFacultyCourseWeightageDateRange()
                }}
                confirmLoading={loading}
                onCancel={hideFacultyCourseWeightageDateRangeModal}
                okText="OK"
                destroyOnClose={true}
            >
                {facultyCourseWeightageDateRangeModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Start Date"
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Select Date"
                                format="DD-MMM-YYYY"
                                onChange={
                                    onChangeFacultyCourseWeightageDateRangeStartDate
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            name="endDate"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Select Date"
                                format="DD-MMM-YYYY"
                                onChange={
                                    onChangeFacultyCourseWeightageDateRangeEndDate
                                }
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Add Faculty"
                open={addCourseFacultyWeightageVisible}
                onOk={() => {
                    addCourseFacultyWeightage()
                }}
                confirmLoading={loading}
                onCancel={hideAddCourseFacultyWeightageVisible}
                okText="Add"
                destroyOnClose={true}
            >
                <Form form={form}>
                    {addCourseFacultyWeightageVisible ? (
                        <Form.Item
                            label="Select Faculties"
                            name="facultyIds"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select faculties!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select Faculties"
                                style={{ width: '100%' }}
                                mode="multiple"
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                            >
                                {facultyDetails}
                            </Select>
                        </Form.Item>
                    ) : null}
                </Form>
            </Modal>
            <Modal
                title="Update Weightage"
                open={updateCourseFacultyWeightageVisible}
                onOk={() => {
                    updateCourseFacultyWeightage()
                }}
                confirmLoading={loading}
                onCancel={hideUpdateCourseFacultyWeightageVisible}
                okText="Update"
                destroyOnClose={true}
            >
                <span>Faculty: </span>
                <h3>{selectedFacultyCourseMappingWeightage.facultyName}</h3>
                {updateCourseFacultyWeightageVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Weightage"
                            name="percent"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                step={1.0}
                                autoFocus
                                onPressEnter={() => {
                                    updateCourseFacultyWeightage()
                                }}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Add Book"
                open={addBookModalVisible}
                onCancel={hideAddBookModal}
                width={800}
                destroyOnClose={true}
                footer={null}
            >
                <Search
                    placeholder="Search Book"
                    onSearch={(value) => getBooks(value)}
                    style={{ width: 500 }}
                    autoFocus
                    allowClear
                />
                <Table
                    columns={searchBookColumns}
                    dataSource={booksData}
                    pagination={false}
                    size="small"
                    loading={loadingBooks}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                />
                {booksDataNextUrl ? (
                    <Button
                        type="primary"
                        loading={loadingBooks}
                        onClick={getNextPageBooks}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
            <Modal
                title="Add On Course"
                open={addAdOnModalVisible}
                onOk={addAddOnCourse}
                confirmLoading={loading}
                onCancel={hideAddAddOnModal}
                okText="Add"
                width={900}
                destroyOnClose={true}
            >
                {addAdOnModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Select Course"
                            name="addOnCourseId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {allCoursesWithIds}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default ConfigureCourse
