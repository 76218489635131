import React, { useState, useEffect } from 'react'
import { Button, Modal, notification, Select } from 'antd'
import Globals from '../../Globals'
import { getMessageSalesUserNumber } from '../JS/IlUtil'

function SendWhatsAppToLead(props) {
    const { Option } = Select

    const MSG_TYPE_WELCOME_CALL_NOT_ANSWERING =
        'MSG_TYPE_WELCOME_CALL_NOT_ANSWERING'

    // Cart
    const MSG_TYPE_CART_NOT_ANSWERING = 'MSG_TYPE_CART_NOT_ANSWERING'
    const MSG_TYPE_CART_FOLLOW_UP_HOT_COLD_LEAD =
        'MSG_TYPE_CART_FOLLOW_UP_HOT_COLD_LEAD'
    const MSG_TYPE_CART_BOOKS = 'MSG_TYPE_CART_BOOKS'
    const MSG_TYPE_CART_EXTENSION = 'MSG_TYPE_CART_EXTENSION'

    // Misc
    const MSG_TYPE_RESULTS_TESTIMONIAL = 'MSG_TYPE_RESULTS_TESTIMONIAL'
    const MSG_TYPE_EMPTY = 'MSG_TYPE_EMPTY'
    const MSG_TYPE_SURVEY_CALL_NA = 'MSG_TYPE_SURVEY_CALL_NA'
    const MSG_FR_AFM_FREE_ACCESS = 'MSG_FR_AFM_FREE_ACCESS'
    const MSG_NOT_PURCHASED_SUBSEQUENTLY_SPECIAL_OFFER =
        'MSG_NOT_PURCHASED_SUBSEQUENTLY_SPECIAL_OFFER'
    const MSG_STUDENT_SUCCESS_TEAM_NOT_ANSWERING =
        'MSG_STUDENT_SUCCESS_TEAM_NOT_ANSWERING'

    // CA
    const MSG_TYPE_CA_FREE_NOT_ANSWERED_NOT_SIGNED_UP =
        'MSG_TYPE_CA_FREE_NOT_ANSWERED_NOT_SIGNED_UP'
    const MSG_TYPE_CA_FREE_NOT_ANSWERED_SIGNED_UP =
        'MSG_TYPE_CA_FREE_NOT_ANSWERED_SIGNED_UP'
    const MSG_TYPE_CA_COURSE_DETAILS = 'MSG_TYPE_CA_COURSE_DETAILS'
    const MSG_TYPE_CA_SUBSCRIPTION = 'MSG_TYPE_CA_SUBSCRIPTION'
    const MSG_TYPE_CA_DEMO_FOUNDATION = 'MSG_TYPE_CA_DEMO_FOUNDATION'
    const MSG_TYPE_CA_DEMO_INTER = 'MSG_TYPE_CA_DEMO_INTER'
    const MSG_TYPE_CA_DEMO_FINAL = 'MSG_TYPE_CA_DEMO_FINAL'

    // CFA
    const MSG_TYPE_CFA_NOT_ANSWERING = 'MSG_TYPE_CFA_NOT_ANSWERING'
    const MSG_TYPE_CFA_CALL_ANSWERED = 'MSG_TYPE_CFA_CALL_ANSWERED'
    const MSG_TYPE_CFA_COURSE_DETAILS = 'MSG_TYPE_CFA_COURSE_DETAILS'
    const MSG_TYPE_CFA_DEMO_L1 = 'MSG_TYPE_CFA_DEMO_L1'
    const MSG_TYPE_CFA_DEMO_L2 = 'MSG_TYPE_CFA_DEMO_L2'
    const MSG_TYPE_CFA_WEBINAR_NOT_ANSWERING =
        'MSG_TYPE_CFA_WEBINAR_NOT_ANSWERING'

    // ACCA
    const MSG_TYPE_ACCA_NOT_ANSWERING = 'MSG_TYPE_ACCA_NOT_ANSWERING'
    const MSG_TYPE_ACCA_COURSE_DETAILS = 'MSG_TYPE_ACCA_COURSE_DETAILS'
    const MSG_TYPE_ACCA_KNOW_MORE = 'MSG_TYPE_ACCA_KNOW_MORE'
    const MSG_TYPE_ACCA_DEMO_LEVEL_I = 'MSG_TYPE_ACCA_DEMO_LEVEL_I'
    const MSG_TYPE_ACCA_DEMO_LEVEL_II = 'MSG_TYPE_ACCA_DEMO_LEVEL_II'
    const MSG_TYPE_ACCA_WEBINAR_NOT_ANSWERING =
        'MSG_TYPE_ACCA_WEBINAR_NOT_ANSWERING'
    const MSG_TYPE_ACCA_CA_ACCA_NA = 'MSG_TYPE_ACCA_CA_ACCA_NA'

    // CMA US
    const MSG_TYPE_CMA_US_NOT_ANSWERING = 'const MSG_TYPE_CMA_US_NOT_ANSWERING'
    const MSG_TYPE_CMA_US_COURSE_DETAILS =
        'const MSG_TYPE_CMA_US_COURSE_DETAILS'

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [userData, setUserData] = useState({})
    const [cartData, setCartData] = useState({})

    useEffect(() => {
        if (props.userData) {
            setUserData(props.userData)
        }

        if (props.cartData) {
            setCartData(props.cartData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const showModal = () => {
        setIsModalVisible(true)
    }

    const hideModal = () => {
        setIsModalVisible(false)
    }

    const getStreamData = () => {
        var data = ''
        if (userData.stream && userData.stream.course) {
            data =
                ' for ' +
                userData.stream.course +
                (userData.stream.level ? ' ' + userData.stream.level : '')
        }

        return data
    }

    const getCartData = () => {
        var data = ''
        if (cartData && cartData.cartItems && cartData.cartItems.length > 0) {
            data = ' in'
            cartData.cartItems.forEach((item, index) => {
                data = data + '\n' + (index + 1) + '. ' + item.courseName
            })
        }

        return data
    }

    const sendMessage = (msgType) => {
        if (!userData.phone) {
            notification['error']({
                message: 'Student phone number not available!',
            })

            return
        }

        var message = ''
        if (msgType === MSG_TYPE_WELCOME_CALL_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\nGreetings from *1FIN by IndigoLearn!!*' +
                '\n\nI tried reaching your 📞 but couldn’t connect.' +
                '\nThanks for registering with us' +
                getStreamData() +
                '.' +
                '\n\nA special offer awaits you 🎉, please call back.' +
                "\n\nIf you don't want to receive any messages pls reply as *STOP*" +
                '\n\n' +
                Globals.USER_DATA.firstName +
                '\nCall/WhatsApp: ' +
                getMessageSalesUserNumber() +
                '\n\n#LearnLikeNeverBefore'
        } else if (msgType === MSG_TYPE_CART_NOT_ANSWERING) {
            message =
                'Dear ' +
                userData.firstName +
                ',\n\n' +
                'Greetings from *1FIN by IndigoLearn!!*' +
                '\n\n' +
                'I tried reaching your 📞 but couldn’t connect.' +
                '\n\n' +
                'Thanks for your interest' +
                getCartData() +
                '\n\n' +
                'We have an attractive offer for you to help you make your decision.' +
                '\n\n' +
                'Call/WhatsApp ' +
                Globals.USER_DATA.firstName +
                ' to know more!!' +
                '\n' +
                getMessageSalesUserNumber() +
                '\n\n' +
                '*Start Learning Like Never Before!!*'
        } else if (msgType === MSG_TYPE_CART_FOLLOW_UP_HOT_COLD_LEAD) {
            message =
                'Dear ' +
                userData.firstName +
                ',\n\n' +
                'Greetings from *1FIN by IndigoLearn!!*' +
                '\n\n' +
                'I tried reaching your 📞 but couldn’t connect.' +
                '\n\n' +
                'As discussed over last call, you were interested to enroll' +
                getCartData() +
                '\n\n' +
                'Let me know how I can assist you in the purchase so that you can start preparing right away.' +
                '\n\n' +
                'Call/WhatsApp: ' +
                getMessageSalesUserNumber() +
                '\n\n' +
                Globals.USER_DATA.firstName
        } else if (msgType === MSG_TYPE_CART_BOOKS) {
            message =
                'Dear ' +
                userData.firstName +
                ',\n\n' +
                'Greetings from *1FIN by IndigoLearn!!*' +
                '\n\n' +
                'I tried reaching your 📞 but couldn’t connect.' +
                '\n\n' +
                'Thanks for your interest' +
                getCartData() +
                '\n\n' +
                '*Complete the purchase and get books delivered right at your doorstep.*' +
                '\n\n' +
                'Call/WhatsApp: ' +
                getMessageSalesUserNumber() +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n\n' +
                'Start Learning Like Never Before!!'
        } else if (msgType === MSG_TYPE_CART_EXTENSION) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                'Greetings from *1FIN by IndigoLearn!!*' +
                '\n\n' +
                'We see that you are trying to extend your classes to the next attempt, please let me know how I can help you in the process so that you can start preparing immediately.' +
                '\n\n' +
                'For any queries, Call/WhatsApp: ' +
                getMessageSalesUserNumber() +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n\n' +
                'Learn Like Never Before!!'
        } else if (msgType === MSG_TYPE_RESULTS_TESTIMONIAL) {
            message =
                'Dear ' +
                userData.firstName +
                ',\n\n' +
                'Results of CA Exams are declared.' +
                '\n\n' +
                "If you have cleared, we request you to share your Marksheet 🧾 and a Testimonial ⭐ with us. In case if you haven't cleared the exams please reach us for any assistance, we will be happy to assist you." +
                '\n\n' +
                'Any feedback ✍🏼 shall be highly appreciated.' +
                '\n\n' +
                'Feel free to reach out to us at ' +
                getMessageSalesUserNumber() +
                ' for any Guidance or Support.' +
                '\n\n' +
                'Good Luck!!' +
                '\n' +
                'Team IndigoLearn'
        } else if (msgType === MSG_TYPE_EMPTY) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n\n' +
                'Regards,' +
                '\n' +
                Globals.USER_DATA.firstName
        } else if (msgType === MSG_TYPE_CFA_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                Globals.USER_DATA.firstName +
                ' this side from 1FIN by IndigoLearn. \n' +
                'I tried reaching out to you - please suggest when I can connect again.' +
                '\n\n' +
                '*Want to make a career in Finance? CFA is the highest finance distinction.*' +
                '\n\n' +
                'Looking forward to hearing from you and *answering any queries you may have!!*' +
                '\nhttps://1fin.in/CFA'
        } else if (msgType === MSG_TYPE_CFA_COURSE_DETAILS) {
            message =
                '*1FIN has partnered with Kaplan Schweser, the World Leader in CFA Prep.*' +
                '\n\n' +
                'Our Level I CFA course comprises:' +
                '\n• Video classes with unlimited views: ~120 hours' +
                '\n• Schweser Notes: Original printed copy + E-copies' +
                '\n• Schweser Notes Videos: 40+ hours of recorded videos' +
                '\n• Live Revision' +
                '\n• Schweser Pro QBank: Online' +
                '\n• Mock Exams: Four' +
                '\n• QuickSheets - Summary Summary of key formulas, definitions, and concepts' +
                '\n• Checkpoint Exams: Three' +
                '\n• Activity Feed/Study Plan' +
                '\n• Performance Tracker' +
                '\n• Upskilling Courses: Courses on Excel + Financial Modelling (without certificate)' +
                '\n• Pass Protection' +
                '\n\nEnroll in CFA Level I course here - https://1fin.in/EnrollCFA' +
                '\n\nFor more information or queries: Call/WhatsApp - ' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_TYPE_CFA_CALL_ANSWERED) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                'Thanks for your time over call. It was pleasure connecting with you. As discussed, please find all the relevant details below.' +
                ',\n\n' +
                '*If you would want anything else or have any further queries, please reach out.*' +
                '\nAll the best!' +
                '\n' +
                Globals.USER_DATA.firstName
        } else if (msgType === MSG_TYPE_CFA_DEMO_L1) {
            message =
                'Our Level I CFA Classes are recorded by expert Charterholder faculties. Below are links to the *demo videos*.' +
                '\n\n*Derivatives:*' +
                '\nhttps://youtu.be/ij-nBS0jMPw' +
                '\n\n*Financial Statement Analysis:*' +
                '\nhttps://youtu.be/p32nZeGRiSE' +
                '\n\nTo check out more videos, get free trial here -' +
                '\nhttps://1fin.in/CFA'
        } else if (msgType === MSG_TYPE_CFA_DEMO_L2) {
            message =
                'Our Level II CFA Classes are recorded by expert Charterholder faculties. Below are links to the *demo videos*.' +
                '\n\n*Financial Statement Analysis:*' +
                '\nhttps://youtu.be/m_THj8mQgIM' +
                '\n\n*Quantitive Methods:*' +
                '\nhttps://youtu.be/IqxovfL5jaU' +
                '\n\nTo check out more videos, get free trial here -' +
                '\nhttps://1fin.in/CFA'
        } else if (msgType === MSG_TYPE_CFA_WEBINAR_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                Globals.USER_DATA.firstName +
                ' this side from 1FIN by IndigoLearn.\n' +
                'I tried calling you - please suggest when I can connect again.' +
                '\n\n' +
                "*Here's a link to the recording of CFA webinar/workshop that you registered for:*" +
                '\nhttps://1fin.in/CFAwebinar' +
                '\n\n' +
                'Do watch it and let me know if any queries.' +
                "\n\nKnow more about CFA and World's Best Study Package here:" +
                '\nhttps://1fin.in/CFA'
        } else if (msgType === MSG_TYPE_ACCA_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                Globals.USER_DATA.firstName +
                ' this side from 1FIN by IndigoLearn. \n' +
                'I tried reaching out to you - please suggest when I can connect again.' +
                '\n\n' +
                '*ACCA is one of the most flexible professional qualification - achievable, short duration, exemptions, MNC jobs,etc.*' +
                '\n\n' +
                'Looking forward to hearing from you to *answer any question you have about ACCA*.' +
                '\n\nKnow more about ACCA by 1FIN\nhttps://1fin.in/ACCA'
        } else if (msgType === MSG_TYPE_ACCA_COURSE_DETAILS) {
            message =
                'Our ACCA course comprises:' +
                '\n• Live classes with the backup of recordings for all levels' +
                '\n• Kaplan E-Notes' +
                '\n• Mock Exams: Three' +
                '\n• Upskilling Courses: Course on Excel' +
                '\n• 100% Placement Assistance' +
                '\n• Regular Webinars for updates' +
                '\n\nACCA Level 1\nhttps://1fin.in/ACCAL1' +
                '\n\nACCA Levle 2\nhttps://1fin.in/ACCAL2' +
                '\n\nFor more information or queries: Call/WhatsApp - ' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_TYPE_ACCA_KNOW_MORE) {
            message =
                'Subscribe to our youtube channel ACCA by 1FIN for more updates' +
                '\nhttps://1fin.in/ACCAYT' +
                '\n\nFor more information or queries: Call/WhatsApp - ' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_TYPE_ACCA_DEMO_LEVEL_I) {
            message =
                'Our Live Classes for ACCA Level I are in progress. Here is a link for the demo:' +
                '\nhttps://youtu.be/X6GkEobUUDs' +
                '\n\nTo check out more videos, subscribe to our youtube channel ACCA by 1FIN here' +
                '\nhttps://1fin.in/ACCAYT'
        } else if (msgType === MSG_TYPE_ACCA_DEMO_LEVEL_II) {
            message =
                'Our Live Classes for ACCA Level II are in progress. Here is a link for the demo:' +
                '\n\nFR' +
                '\nhttps://youtu.be/Kn7nq44Tgc8' +
                '\n\nPM' +
                '\nhttps://youtu.be/b6oEoDZ-ylQ' +
                '\n\nAudit & Assurance' +
                '\nhttps://youtu.be/Fp3e-aDLyfQ' +
                '\n\nTo check out more videos, subscribe to our youtube channel ACCA by 1FIN here' +
                '\nhttps://1fin.in/ACCAYT'
        } else if (msgType === MSG_TYPE_ACCA_WEBINAR_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                Globals.USER_DATA.firstName +
                ' this side from 1FIN by IndigoLearn. \n' +
                'I tried calling you - please suggest when I can connect again.' +
                '\n\n' +
                "Here's a link to the ACCA webinar recording that you registered for:" +
                '\nhttps://youtu.be/hcQzwE2iu4I' +
                '\n\n' +
                'Do watch it and let me know if any queries.' +
                '\n\nKnow more about ACCA, the globally recognised qualification here' +
                '\nhttps://1fin.in/ACCA'
        } else if (msgType === MSG_TYPE_CMA_US_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',\n\n' +
                Globals.USER_DATA.firstName +
                ' this side from 1FIN by IndigoLearn.\n' +
                'I tried reaching out to you - please suggest when I can connect again.' +
                '\n\n' +
                '*CMA by IMA , a global professional certification in Business provides recognition, proficiencies in 12 core competencies, Big4 jobs,etc.*' +
                '\n\n' +
                'Looking forward to hearing from you to *answer any question you have about CMA*.' +
                '\n\nKnow more about CMA by 1FIN https://1fin.in/CMA-US'
        } else if (msgType === MSG_TYPE_CMA_US_COURSE_DETAILS) {
            message =
                'Our CMA (US) course comprises:' +
                '\n• Live Classes - Back up recordings available with unlimited views' +
                '\n• Gleim Notes' +
                '\n• Mock Tests' +
                '\n• Online Doubt Resolution Forum' +
                '\n• Access to Webinars by Industry Experts' +
                '\n• Resume Building & Mock Interviews' +
                '\n• 100% Placement Assistance' +
                '\n\nEnroll in CMA-US here - https://1fin.in/Enroll-CMA-US' +
                '\n\nFor more information or queries: Call/WhatsApp - ' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_TYPE_CA_FREE_NOT_ANSWERED_NOT_SIGNED_UP) {
            message =
                'Dear ' +
                (userData.firstName ? userData.firstName : 'CA Aspirant') +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn!!*' +
                '\n\nI tried calling you 📞 but couldn’t connect. Thanks for your interest in our FREE CA modules – engaging videos with unlimited views.' +
                '\n\n*You just need to finish your signup at our App/website so that you can start Learning Like Never Before!.*' +
                '\n\nComplete signup here - 1fin.in/sign' +
                '\n\nWant to pass CA Exams? Let’s connect.' +
                '\n\n' +
                Globals.USER_DATA.firstName +
                '\nCall/WhatsApp - ' +
                getMessageSalesUserNumber() +
                '\n#StudentFirst'
        } else if (msgType === MSG_TYPE_CA_FREE_NOT_ANSWERED_SIGNED_UP) {
            message =
                'Dear ' +
                userData.firstName +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn!!*' +
                '\n\nI tried calling you 📞 but couldn’t connect. Thanks for your interest in our FREE CA modules – engaging videos with unlimited views.' +
                '\n\n*You can start watching the videos either on our App or at website under Free Courses tab & experience Learning Like Never Before!*' +
                '\n\nAlternatively, just click here: 1fin.in/FreeCourses' +
                '\n\nWant to pass CA Exams? Let’s connect.' +
                '\n\n' +
                Globals.USER_DATA.firstName +
                '\nCall/WhatsApp - ' +
                getMessageSalesUserNumber() +
                '\n#StudentFirst'
        } else if (msgType === MSG_TYPE_SURVEY_CALL_NA) {
            message =
                'Dear ' +
                userData.firstName +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn..!!*' +
                '\n\nHope you are doing well..' +
                '\n\nIts time to start preparing for the upcoming exams with us.' +
                '\nGrab this opportunity and enjoy reduced prices along with additional discounts on All our courses.' +
                '\n\nDo let me know if you have any queries, I would be glad to address them.' +
                '\nEnroll for success!' +
                "\n\nIf you don't want to receive any messages pls reply as *STOP*" +
                '\n\nRegards,' +
                '\n' +
                Globals.USER_DATA.firstName
        } else if (msgType === MSG_TYPE_CA_COURSE_DETAILS) {
            message =
                `Thank you ${userData.firstName} for your interest in 1FIN CA classes.` +
                '\n\nBelow are the key outcomes when you purchase the classes from us:' +
                '\n\n1. Unlimited views' +
                '\n2. Validity - till the last day of the attempt purchased for' +
                '\n3. TWO Upskilling classes for FREE of cost.' +
                '\n4. Physical Books' +
                '\n5. Pass protection scheme' +
                '\n6. Extension to next attempt after the course expiry (T&C)' +
                '\n\nClick on the link to know about the classes:' +
                '\nCA Final - https://1fin.in/CA_Final' +
                '\nCA Inter - https://1fin.in/CA_Inter' +
                '\nCA Foundation - https://1fin.in/CA_Foundation' +
                '\n\nDo let me know if you have any queries, I would be glad to address them.'
        } else if (msgType === MSG_TYPE_CA_SUBSCRIPTION) {
            message =
                'Hi ' +
                userData.firstName +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn..!!*' +
                '\n\nThanks for showing interest in our Subscription course.' +
                "\nWe tried reaching you but couldn't connect. Kindly let me know when would be a good time to speak to you." +
                '\n\nBelow are the key outcomes when you activate the Subscription course:' +
                '\n1. 100% Syllabus Coverage' +
                '\n2. 1.5x Views' +
                '\n3. Validity - 3 Months' +
                '\n4. E-Notes ' +
                '\n\nStart preparing right away!!' +
                '\n\nLet me know how I can assist you in the purchase.' +
                'Call/WhatsApp: ' +
                getMessageSalesUserNumber() +
                '\n\n' +
                Globals.USER_DATA.firstName
        } else if (msgType === MSG_TYPE_ACCA_CA_ACCA_NA) {
            message =
                'Hi ' +
                userData.firstName +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn..!!*' +
                '\n\nThanks for showing interest towards our CA and ACCA courses.' +
                '\n\nI tried reaching out but couldn’t connect - please suggest when would be the best time to connect again.' +
                '\n\nCA and ACCA are one of the most flexible professional qualifications - achievable, exemptions, MNC jobs,etc.' +
                '\n\nLooking forward to hearing from you to answer any question you have about CA and ACCA.' +
                '\n\nRegards, ' +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n\n' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_FR_AFM_FREE_ACCESS) {
            message =
                'Hi ' +
                userData.firstName +
                ',' +
                '\n\nGreetings from *1FIN by IndigoLearn..!!*' +
                '\nWe hope you have enjoyed our free lectures.' +
                '\n\nWe are happy to announce that you are eligible to receive a *special discount*.' +
                '\n\n*Exclusive discount offer available only for a brief period*' +
                '\n\nPlease let us know if you are interested in it, I would be glad to assist.' +
                '\n\nRegards, ' +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n\n' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_TYPE_CA_DEMO_FOUNDATION) {
            message =
                'Please find the below demo videos of CA Foundation.' +
                '\n\nHow to prepare BRS:' +
                '\nhttps://youtu.be/fFSVqf4DisY' +
                '\n\nContract Act Summary:' +
                '\nhttps://youtu.be/NvjzhoFkmqg' +
                '\n\nRectification of Errors:' +
                '\nhttps://youtu.be/4Eey92mq9SI' +
                '\n\nBasics of Permutation with Examples:' +
                '\nhttps://youtu.be/tRQU7nOOA-g' +
                '\n\nWhy demand curve slopes downward:' +
                '\nhttps://youtu.be/fjLdRujfJzk' +
                '\n\nDirection Tests:' +
                '\nhttps://youtu.be/8rz96zOflAM'
        } else if (msgType === MSG_TYPE_CA_DEMO_INTER) {
            message =
                'Please find the below demo videos of CA Inter.' +
                '\n\n--- *_Group I_* ---' +
                '\n\nAS 2 Valuation of Inventories:' +
                '\nhttps://youtu.be/3PiMMms9rfc' +
                '\n\nEnd of confusion:' +
                '\nhttps://youtu.be/UczkS6ndbqs' +
                '\n\nRevision G1 AS:' +
                '\nhttps://youtu.be/0ePhCyd-zos' +
                '\n\nHow to incorporate a company:' +
                '\nhttps://youtu.be/0ePhCyd-zos' +
                '\n\nManagement & Administration Revision:' +
                '\nhttps://youtu.be/lcZYosStK6k' +
                '\n\nIncome from House Property:' +
                '\nhttps://youtu.be/pV2tCxnP8AEa' +
                '\n\nConcept of Supply:' +
                '\nhttps://youtu.be/_iOCJdPumaI' +
                '\n\n--- *_Group II_* ---' +
                '\n\nDisqualifications of Auditor:' +
                '\nhttps://youtu.be/Qyn1ky2BtxE' +
                "\n\nSA's Marathon Revision:" +
                '\nhttps://youtu.be/ciL2Bl8jEEc' +
                '\n\nBCG Matrix:' +
                '\nhttps://youtu.be/BtKThoa4FJE' +
                '\n\nHow to compute Working Capital:' +
                '\nhttps://youtu.be/UMPedvEqjg4' +
                '\n\nRatio Analysis:' +
                '\nhttps://youtu.be/3ywMj6LFN6g'
        } else if (msgType === MSG_TYPE_CA_DEMO_FINAL) {
            message =
                'Please find the below demo videos of CA Final.' +
                '\n\n--- *_Group I_* ---' +
                '\n\nInd AS 19 – Full Standard:' +
                '\nhttps://youtu.be/kx1g50RimwY' +
                '\n\nInd AS 115 – 5 Steps to Recognise Revenue:' +
                '\nhttps://youtu.be/07Onxd-ZfWE' +
                '\n\nInd AS 36 Summary:' +
                '\nhttps://youtu.be/ZjSSo7JW2I0' +
                '\n\nIntroduction to Financial Policy & Corp Strategy:' +
                '\nhttps://youtu.be/twmbixzms-c' +
                '\n\nPortfolio Management Revision:' +
                '\nhttps://youtu.be/2Y3-zzdeAPU' +
                '\n\nHedging and Arbitrage:' +
                '\nhttps://youtu.be/ljHnSIdApcE' +
                '\n\nFull SA 210 covered:' +
                '\nhttps://youtu.be/x1ertnYs1Qs' +
                '\n\nSA Marathon Revision:' +
                '\nhttps://youtu.be/RBpadgcMyqU' +
                '\n\n--- *_Group II_* ---' +
                '\n\nPlace of Supply:' +
                '\nhttps://youtu.be/UAnxPI2Ah1E' +
                '\n\nCustoms – FTP Schemes:' +
                '\nhttps://youtu.be/48rul2j7XKk'
        } else if (msgType === MSG_NOT_PURCHASED_SUBSEQUENTLY_SPECIAL_OFFER) {
            message =
                '1FIN is giving you an exclusive *20% OFF on all courses*.' +
                '\n\nUse your coupon *IamCA* during checkout!' +
                '\nClick: https://1fin.link/courses' +
                '\n\nThis is the perfect time to begin preparing for the upcoming CA exams.' +
                '\n\nSo why wait??' +
                '\nStart your journey towards fulfilling your dream of becoming a CA.' +
                '\n\n*Limited period offer*!!! Better Hurry.' +
                '\n\nEnjoy uninterrupted learning!' +
                '\n\n*P.S. coupon will work with your registered email id only*' +
                '\n\nRegards,' +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n' +
                getMessageSalesUserNumber()
        } else if (msgType === MSG_STUDENT_SUCCESS_TEAM_NOT_ANSWERING) {
            message =
                'Hi ' +
                userData.firstName +
                ',' +
                "\n\nThis is Shubham from 1FIN's Student Success Team. I tried to reach you earlier to check on your progress and address any issues you might have with our classes or resources." +
                "\n\nHow's your preparation going on?" +
                "\n\nI'll call you back soon. If you prefer a specific time, please let me know." +
                '\n\nBest regards,' +
                '\n' +
                Globals.USER_DATA.firstName +
                '\n' +
                getMessageSalesUserNumber()
        }

        var url =
            'https://api.whatsapp.com/send?phone=' +
            (userData.isd || '+91') +
            userData.phone +
            '&text=' +
            encodeURIComponent(message)
        window.open(url, '_blank')
    }

    const getOption = (key, displayText) => {
        return (
            <Option value={key} key={key}>
                {displayText}
            </Option>
        )
    }

    const getCartOptions = () => {
        var options = []
        options.push(
            getOption(MSG_TYPE_CART_NOT_ANSWERING, 'Cart - Not Answering')
        )
        options.push(
            getOption(
                MSG_TYPE_CART_FOLLOW_UP_HOT_COLD_LEAD,
                'Cart - Follow Up (Hot/ Cold Lead)'
            )
        )
        options.push(getOption(MSG_TYPE_CART_BOOKS, 'Cart - Books'))
        options.push(getOption(MSG_TYPE_CART_EXTENSION, 'Cart - Extension'))

        return options
    }

    const getCAOptions = () => {
        var options = []
        options.push(
            getOption(
                MSG_TYPE_CA_FREE_NOT_ANSWERED_NOT_SIGNED_UP,
                'Free - Not Answering - Not Signed Up'
            )
        )
        options.push(
            getOption(
                MSG_TYPE_CA_FREE_NOT_ANSWERED_SIGNED_UP,
                'Free - Not Answering - Signed Up'
            )
        )
        options.push(getOption(MSG_TYPE_CA_COURSE_DETAILS, 'Course Details'))
        options.push(getOption(MSG_TYPE_CA_SUBSCRIPTION, 'Subscription'))
        options.push(
            getOption(MSG_TYPE_CA_DEMO_FOUNDATION, 'Demo - CA Foundation')
        )
        options.push(getOption(MSG_TYPE_CA_DEMO_INTER, 'Demo - CA Inter'))
        options.push(getOption(MSG_TYPE_CA_DEMO_FINAL, 'Demo - CA Final'))

        return options
    }

    const getCFAOptions = () => {
        var options = []
        options.push(getOption(MSG_TYPE_CFA_NOT_ANSWERING, 'Not Answering'))
        options.push(getOption(MSG_TYPE_CFA_CALL_ANSWERED, 'Call Answered'))
        options.push(getOption(MSG_TYPE_CFA_COURSE_DETAILS, 'Course Details'))
        options.push(getOption(MSG_TYPE_CFA_DEMO_L1, 'Demo - L1'))
        options.push(getOption(MSG_TYPE_CFA_DEMO_L2, 'Demo - L2'))
        options.push(
            getOption(
                MSG_TYPE_CFA_WEBINAR_NOT_ANSWERING,
                'Webinar Not Answering'
            )
        )

        return options
    }

    const getACCAOptions = () => {
        var options = []
        options.push(getOption(MSG_TYPE_ACCA_NOT_ANSWERING, 'Not Answering'))
        options.push(getOption(MSG_TYPE_ACCA_COURSE_DETAILS, 'Course Details'))
        options.push(getOption(MSG_TYPE_ACCA_KNOW_MORE, 'Know More About ACCA'))
        options.push(getOption(MSG_TYPE_ACCA_DEMO_LEVEL_I, 'Demo - Level I'))
        options.push(getOption(MSG_TYPE_ACCA_DEMO_LEVEL_II, 'Demo - Level II'))
        options.push(
            getOption(
                MSG_TYPE_ACCA_WEBINAR_NOT_ANSWERING,
                'Webinar Not Answering'
            )
        )
        options.push(getOption(MSG_TYPE_ACCA_CA_ACCA_NA, 'CA & ACCA NA'))

        return options
    }

    // const getCMAUSOptions = () => {
    //     var options = []
    //     options.push(getOption(MSG_TYPE_CMA_US_NOT_ANSWERING, 'Not Answering'))
    //     options.push(
    //         getOption(MSG_TYPE_CMA_US_COURSE_DETAILS, 'Course Details')
    //     )

    //     return options
    // }

    const getMiscOptions = () => {
        var options = []
        options.push(
            getOption(
                MSG_TYPE_WELCOME_CALL_NOT_ANSWERING,
                'Welcome Call - Not Answering'
            )
        )
        options.push(
            getOption(
                MSG_TYPE_RESULTS_TESTIMONIAL,
                'Results - Request Testimonial'
            )
        )
        options.push(getOption(MSG_TYPE_EMPTY, 'Emtpy Template'))
        options.push(getOption(MSG_TYPE_SURVEY_CALL_NA, 'Survey Call - NA'))
        options.push(getOption(MSG_FR_AFM_FREE_ACCESS, 'FR & AFM Free Access'))
        options.push(
            getOption(
                MSG_NOT_PURCHASED_SUBSEQUENTLY_SPECIAL_OFFER,
                'Spcial Offer - Not Purchased Subsequently'
            )
        )
        options.push(
            getOption(
                MSG_STUDENT_SUCCESS_TEAM_NOT_ANSWERING,
                'Student Success Team - Not Answering'
            )
        )

        return options
    }

    const onSelectOption = (key) => {
        sendMessage(key)
    }

    return (
        <>
            <Button
                type={props.buttonType || 'default'}
                style={props.buttonStyle || { marginLeft: 20 }}
                size="small"
                onClick={showModal}
            >
                {props.buttonText || 'Send WhatsApp Message'}
            </Button>
            <Modal
                title="Send WhatsApp Message"
                open={isModalVisible}
                onCancel={hideModal}
                footer={null}
            >
                <div>
                    <b>Cart</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getCartOptions()}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <b>CA</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getCAOptions()}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <b>CFA</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getCFAOptions()}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <b>ACCA</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getACCAOptions()}
                    </Select>
                </div>
                {/*
                <div style={{ marginTop: 10 }}>
                    <b>CMA (US)</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getCMAUSOptions()}
                    </Select>
                </div>*/}
                <div style={{ marginTop: 10 }}>
                    <b>Misc</b>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        onSelect={onSelectOption}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getMiscOptions()}
                    </Select>
                </div>
            </Modal>
        </>
    )
}

export default SendWhatsAppToLead
