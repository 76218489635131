import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Radio,
    Popconfirm,
    notification,
    DatePicker,
    Select,
    Tag,
    Descriptions,
    InputNumber,
    message,
    Form,
} from 'antd'
import { EditTwoTone, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faExternalLinkAlt,
    faThumbtack,
} from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import SendWhatsAppToLead from '../Sales/SendWhatsAppToLead'
import { useNavigate, useLocation } from 'react-router-dom'
import { Utils } from '../JS/Utils'
import UserAcquisitionInfo from '../Leads/UserAcquisitionInfo'
import { PlayCircleTwoTone } from '@ant-design/icons'
import ReactAudioPlayer from 'react-audio-player'
import dayjs from 'dayjs'
import { Link } from '@mui/material'
import Linkify from 'linkify-react'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../ILShareComponents/ILAttachmentFileList'

const OPEN = 'open'
const UNASSIGNED = 'unassigned'
const FOLLOW_UP = 'followup'
const CLOSED = 'closed'

const COMMENT_FILTER_ALL = 'COMMENT_FILTER_ALL'
const COMMENT_FILTER_PINNED = 'COMMENT_FILTER_PINNED'

function ExternalLeads() {
    const { TextArea } = Input
    const { RangePicker } = DatePicker
    const { Option } = Select
    const { Search } = Input

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const query = params.get('searchQuery')
    const filterData = params.get('filter')
    const streamCourse = params.get('course')
    const streamLevel = params.get('level')
    const filterLead = params.get('leadType')
    const startDateQuery = params.get('startDate')
    const endDateQuery = params.get('endDate')
    const asignee = params.get('asignee')

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [allCommentsModalVisible, setAllCommentsModalVisible] =
        useState(false)
    const [allComments, setAllComments] = useState([])
    const [allCommentsPinned, setAllCommentsPinned] = useState([])
    const [selectedCommentFilter, setSelectedCommentFilter] =
        useState(COMMENT_FILTER_ALL)
    const [filter, setFilter] = useState(filterData || UNASSIGNED)
    const [editCommentVisible, setEditCommentVisible] = useState(false)
    const [selectedComment, setSelectedComment] = useState({})
    const [reminderDate, setReminderDate] = useState('')
    const [startDate, setStartDate] = useState(startDateQuery || '')
    const [endDate, setEndDate] = useState(endDateQuery || '')
    const [adminUsers, setAdminUsers] = useState([])
    const [selectedAsignee, setSelectedAsignee] = useState(asignee || '')
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [filterSubTypes, setFilterSubTypes] = useState([])
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [closed, setClosed] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
    const [addLeadModalVisible, setAddLeadModalVisible] = useState(false)
    const [leadSources, setLeadSources] = useState([])
    const [moreInfoModalVisible, setMoreInfoModalVisible] = useState(false)
    const [leadCourses, setLeadCourses] = useState([])
    const [addLeadCourseModalVisible, setAddLeadCourseModalVisible] =
        useState(false)
    const [courses, setCourses] = useState([])
    const [searchQuery, setSearchQuery] = useState(query || '')
    const [openOrCloseFilterType, setOpenOrCloseFilterType] = useState(true)
    const [filterLeadType, setFilterLeadType] = useState(filterLead || '')
    const [filterLeadSubType, setFilterLeadSubType] = useState('')
    const [addCallEntryModalVisible, setAddCallEntryModalVisible] =
        useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [filterLevelData, setFilterLevelData] = useState([])
    const [streamCourseFilter, setStreamCourseFilter] = useState(
        streamCourse || ''
    )
    const [streamLevelFilter, setStreamLevelFilter] = useState(
        streamLevel || ''
    )
    const [followUpLeadType, setFollowUpLeadType] = useState('open')
    const [phoneEndingWith, setPhoneEndingWith] = useState('')
    const [emailStartingWith, setEmailStartingWith] = useState('')
    const [pinUnpinLoading, setPinUnpinLoading] = useState(false)
    const [leadsCount, setLeadsCount] = useState()
    const [highProspectModalVisible, setHighProspectModalVisible] =
        useState(false)
    const [expectedSaleDate, setExpectedSaleDate] = useState('')
    const [highProspectsFilter, setHighProspectsFilter] = useState('all')

    const [callUserLoading, setCallUserLoading] = useState(false)
    const [playAudioModalVisible, setPlayAudioModalVisible] = useState(false)
    const [audioUrl, setAudioUrl] = useState('')
    const [loadingRefreshCloudPhoneCall, setLoadingRefreshCloudPhoneCall] =
        useState(false)
    const [loadingComments, setLoadingComments] = useState(false)
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter,
        endDate,
        selectedAsignee,
        filterLeadType,
        filterLeadSubType,
        streamCourseFilter,
        streamLevelFilter,
        followUpLeadType,
        highProspectsFilter,
    ])

    useEffect(() => {
        if (location.pathname !== '/leads/external') return
        const queryParams = new URLSearchParams()
        if (filter) queryParams.set('filter', filter)
        if (streamCourseFilter) queryParams.set('course', streamCourseFilter)
        if (streamLevelFilter) queryParams.set('level', streamLevelFilter)
        if (filterLeadType) queryParams.set('leadType', filterLeadType)
        if (selectedAsignee) queryParams.set('asignee', selectedAsignee)
        if (startDate && endDate.length > 0)
            queryParams.set('startDate', startDate)
        if (endDate && endDate.length > 0) queryParams.set('endDate', endDate)
        if (searchQuery) queryParams.set('searchQuery', searchQuery)
        navigate({ search: queryParams.toString() })
        // eslint-disable-next-line
    }, [
        filter,
        streamCourseFilter,
        streamLevelFilter,
        selectedAsignee,
        filterLeadType,
        endDate,
        startDate,
        searchQuery,
        navigate,
    ])

    useEffect(() => {
        getFiltersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        onChangeFilterLeadType(filterLead)
        //eslint-disable-next-line
    }, [filterLead])

    useEffect(() => {
        if (!filterData) {
            return
        }
        setFilter(filterData)
    }, [filterData])

    useEffect(() => {
        setTimeout(() => {
            if (
                (addCommentModalVisible || addLeadModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addCommentModalVisible, addLeadModalVisible, inputRef])

    const getFiltersData = () => {
        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEADS_FILTERS_DATA).then((res) => {
            setAdminUsers(res.data.adminUsers)
            setOpenStatusTypes(res.data.openStatusTypes)
            setClosedStatusTypes(res.data.closedStatusTypes)
            setStreamData(JSON.parse(res.data.streamData))
        })
    }

    const fetchData = () => {
        setLoading(true)
        setData([])

        var url =
            GLOBAL.URL.LEADS.GET_LEADS_EXTERNAL +
            '?filter=' +
            filter +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&assignToUserId=' +
            selectedAsignee +
            '&query=' +
            searchQuery +
            '&leadType=' +
            filterLeadType +
            '&leadSubType=' +
            filterLeadSubType +
            '&stream=' +
            streamCourseFilter +
            '&level=' +
            streamLevelFilter +
            '&followUpLeadType=' +
            followUpLeadType +
            '&phoneEndingWith=' +
            phoneEndingWith +
            '&emailStartingWith=' +
            emailStartingWith +
            '&highProspectsFilter=' +
            highProspectsFilter

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
                setLeadsCount(res.leadsCount)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddLeadModal = (record) => {
        form3.resetFields()

        if (leadSources.length === 0) {
            RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_SOURCES).then(
                (res) => {
                    setLeadSources(res.data)
                }
            )
        }

        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setReminderDate('')
        setAddLeadModalVisible(true)

        if (record.stream) {
            handleCourseChange(record.stream)
        } else {
            setSelectedCourse('')
        }

        if (record.leadId) {
            setClosed(record.closed)
            var recordClone = clone(record)

            if (!recordClone.closed) {
                recordClone.type = recordClone.openType
                recordClone.subType = recordClone.openSubType
            } else {
                recordClone.type = recordClone.closedType
                recordClone.subType = recordClone.closedSubType
            }

            getLeadSubTypes(recordClone.type)

            setTimeout(() => {
                if (recordClone.followUpDate) {
                    setReminderDate(record.followUpDate)
                    var followUpDate = dayjs(
                        recordClone.followUpDate,
                        'DD-MMM-YY HH:mm'
                    )
                    recordClone.followUpDate = followUpDate
                }

                if (recordClone.assignedTo && recordClone.assignedTo.id) {
                    recordClone.assignToUserId = recordClone.assignedTo.id
                }

                form3.setFieldsValue({
                    name: recordClone.name,
                    email: recordClone.email,
                    isd: recordClone.isd,
                    phone: recordClone.phone,
                    leadType: recordClone.type,
                    leadSubType: recordClone.subType,
                    source: recordClone.source,
                    assignedTo: recordClone.assignToUserId,
                    followUpDate: recordClone.followUpDate,
                    stream: recordClone.stream,
                    level: recordClone.level,
                    comments: '',
                })
            }, 100)
        } else {
            setClosed(false)
        }
    }

    const hideAddLeadModal = () => {
        setAddLeadModalVisible(false)
    }

    const leadSourceOptions = () => {
        var options = []
        leadSources.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const addOrUpdateLead = () => {
        form3
            .validateFields([
                'name',
                'email',
                'isd',
                'phone',
                'leadType',
                'leadSubType',
                'source',
                'assignedTo',
                'courseIds',
                'customCourseName',
                'comments',
                'callDurationMins',
                'callDurationSecs',
                'stream',
                'level',
            ])
            .then((values) => {
                setLoading(true)

                values.followUpDate = reminderDate
                values.closed = closed

                if (!values.leadSubType) {
                    values.leadSubType = ''
                }

                var url = GLOBAL.URL.LEADS.ADD_LEAD_EXTERNAL
                if (selectedRecord.leadId) {
                    values.leadId = selectedRecord.leadId
                    url = GLOBAL.URL.LEADS.UPDATE_LEAD_EXTERNAL
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddLeadModal()
                        fetchData()
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeFilter = (event) => {
        setLeadsCount()
        setFilter(event.target.value)

        if (event.target.value === CLOSED) {
            setOpenOrCloseFilterType(false)
        } else {
            setOpenOrCloseFilterType(true)
        }
    }

    const onSearchLead = (value) => {
        setSearchQuery(value)
        fetchData()
    }

    const showBulkAssignModal = () => {
        setBulkAssignModalVisible(true)
    }

    const hideBulkAssignModal = () => {
        setBulkAssignModalVisible(false)
    }

    const onChangeAsignee = (value) => {
        setSelectedAsignee(value ? value : '')
    }

    const onChangeFilterLeadType = (value) => {
        setFilterLeadType(value ? value : '')
        setFilterLeadSubType('')
        setFilterSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                value +
                '&parentStatusType=' +
                (openOrCloseFilterType ? 'Open' : 'Closed')
        ).then((res) => {
            setFilterSubTypes(res.data)
        })
    }

    const onChangeFilterLeadSubType = (value) => {
        setFilterLeadSubType(value ? value : '')
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const filterLeadSubTypeOptions = () => {
        var options = []
        filterSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const openAddCommentModal = (record) => {
        setAddCommentModalVisible(true)
        setSelectedRecord(record)
        form2.resetFields()
    }

    const closeAddCommentModal = () => {
        setAddCommentModalVisible(false)
        setSelectedRecord({})
        setCommentAttachmentFileList([])
    }

    const openAllCommentsModal = (record) => {
        setLoading(true)
        setSelectedRecord(record)
        getCartLeadAllComments(record)
    }

    const hideAllCommentsModal = (record) => {
        setAllCommentsModalVisible(false)
        setAllComments([])
    }

    const getCartLeadAllComments = (record) => {
        setLoadingComments(true)
        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENTS + record.leadId
        )
            .then((res) => {
                var pinnedComments = []
                res.data.forEach((comment) => {
                    if (comment.pinned) {
                        pinnedComments.push(comment)
                    }
                })

                setAllCommentsPinned(pinnedComments)

                setAllComments(res.data)
                setAllCommentsModalVisible(true)

                if (!pinnedComments.length) {
                    setSelectedCommentFilter(COMMENT_FILTER_ALL)
                }
            })
            .finally(() => {
                setLoading(false)
                setLoadingComments(false)
            })
    }

    const callLead = (record) => {
        setSelectedRecord(record)
        setCallUserLoading(true)

        var payload = {}
        payload.leadId = record.leadId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.CALL_EXTERNAL_LEAD, postBody)
            .then((response) => {
                message.success(response.data)

                RestApi.doGet(
                    GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENT_LATEST +
                        '?leadId=' +
                        record.leadId
                ).then((latestComment) => {
                    var fullData = data
                    fullData.forEach((item) => {
                        if (item.leadId === record.leadId) {
                            item.latestComment = latestComment.data
                            return
                        }
                    })

                    setData(fullData)
                })
            })
            .finally(() => {
                setCallUserLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (
                <span style={{ fontSize: 12 }}>{index + 1}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'leadId',
            key: 'leadId',
            render: (text, record) => (
                <span>
                    <Button
                        type={record.closed ? 'outline' : 'primary'}
                        onClick={() => {
                            showAddLeadModal(record)
                        }}
                        size="small"
                        style={{ fontSize: 11 }}
                    >
                        {record.closed ? 'Closed' : 'Open'}
                    </Button>
                    <br />
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => callLead(record)}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            size="small"
                            style={{ marginTop: 5, fontSize: 12 }}
                            loading={callUserLoading}
                        >
                            Call Lead
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span>
                    {record.assignedTo && record.assignedTo.id ? (
                        <>
                            <span>
                                {record.assignedTo
                                    ? record.assignedTo.firstName
                                    : ''}
                            </span>
                            <br />
                        </>
                    ) : null}
                    <span>{record.createdAt}</span>
                </span>
            ),
        },
        {
            title: 'Type',
            key: 'type',
            render: (text, record) => (
                <span>
                    {record.openType || record.closedType ? (
                        <>
                            <Tag color="purple">
                                {!record.closed
                                    ? record.openType
                                    : record.closedType}
                            </Tag>
                            <br />
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 11 }}
                                onClick={() => showAddCallEntryModal(record)}
                            >
                                Add Call Entry
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.name}
                    <br />
                    {record.isd ? record.isd + '-' : ''}
                    {record.phone}
                    <br />
                    {record.linkedUser.id ? (
                        <span>
                            <Link
                                href={'/userdetails/' + record.linkedUser.id}
                                onClick={(event) => {
                                    event.preventDefault()
                                    navigate(
                                        '/userdetails/' + record.linkedUser.id
                                    )
                                }}
                                underline="none"
                            >
                                {record.linkedUser.firstName}
                            </Link>

                            <Link
                                href={'/userdetails/' + record.linkedUser.id}
                                onClick={(event) => {
                                    event.preventDefault()
                                    window.open(
                                        '/userdetails/' + record.linkedUser.id,
                                        '_blank'
                                    )
                                }}
                                underline="none"
                                style={{ marginLeft: 10 }}
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontSize: 10 }}
                                >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </Button>
                            </Link>
                        </span>
                    ) : (
                        <span style={{ fontSize: 12 }}>{record.email}</span>
                    )}
                </span>
            ),
        },
        {
            title: 'Prospect',
            key: 'prospect',
            render: (text, record) => (
                <span>
                    {record.highProspectData &&
                    record.highProspectData.active ? (
                        <span>
                            <Tag color="red">{'High Prospect'}</Tag>
                            {record.highProspectData.expectedSale ? (
                                <>
                                    <br />
                                    {record.highProspectData.expectedSaleString}
                                </>
                            ) : null}
                            {record.highProspectData.expectedSaleDate ? (
                                <>
                                    <br />
                                    {record.highProspectData.expectedSaleDate}
                                </>
                            ) : null}
                        </span>
                    ) : null}
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 10 }}
                        onClick={() => showHighProspectModal(record)}
                    >
                        Update Prospect
                    </Button>
                </span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'Comments',
            key: 'Comments',
            render: (text, record) => (
                <>
                    {record?.latestComment && record.latestComment?.comments ? (
                        <Linkify
                            as="p"
                            options={{
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }}
                            style={{
                                marginBlockStart: '0.4em',
                                marginBlockEnd: '0.4em',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {record.latestComment.comments
                                .split('\n')
                                .map((line, index) => (
                                    <span key={index}>
                                        {line}
                                        {index <
                                            record.latestComment.comments.split(
                                                '\n'
                                            ).length -
                                                1 && <br />}
                                    </span>
                                ))}
                        </Linkify>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                openAddCommentModal(record)
                            }}
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Add Comment
                        </Button>
                        {record.latestComment &&
                        record.latestComment.comments ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    openAllCommentsModal(record)
                                }}
                                size="small"
                                style={{
                                    marginLeft: 10,
                                    fontSize: 10,
                                    padding: 0,
                                }}
                            >
                                All Comments
                            </Button>
                        ) : null}
                    </div>
                </>
            ),
        },
        {
            title: 'Follow Up',
            dataIndex: 'followUpDate',
            key: 'followUpDate',
            render: (text, record) => (
                <>
                    <span>
                        {record.followUpDate ? (
                            <div style={{ marginBottom: 10 }}>
                                {record.followUpDate}
                            </div>
                        ) : null}
                    </span>
                    <SendWhatsAppToLead
                        buttonType="link"
                        buttonText="WhatsApp"
                        buttonStyle={{
                            marginLeft: 0,
                            padding: 0,
                            fontSize: 12,
                        }}
                        userData={{
                            isd: record.isd,
                            phone: record.phone,
                            firstName: record.name,
                        }}
                    />
                </>
            ),
        },
        {
            title: 'Info',
            dataIndex: 'info',
            key: 'info',
            render: (text, record) => (
                <>
                    {record.stream ? (
                        <span style={{ fontSize: 12 }}>
                            {record.stream + ' - ' + record.level}
                            <br />
                        </span>
                    ) : (
                        ''
                    )}
                    <Button
                        type="link"
                        style={{ fontSize: 10, padding: 0 }}
                        onClick={() => showMoreInfoModal(record)}
                    >
                        More Info
                    </Button>
                </>
            ),
        },
    ]

    const showMoreInfoModal = (record) => {
        setSelectedRecord(record)
        setMoreInfoModalVisible(true)
        getLeadCourses(record.leadId)
    }

    const getLeadCourses = (leadId) => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COURSES + leadId)
            .then((res) => {
                setLeadCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideMoreInfoModal = () => {
        setMoreInfoModalVisible(false)
    }

    const onChangeReminderDate = (value, dateString) => {
        setReminderDate(dateString)
    }

    const onOpenCloseChange = (e) => {
        setClosed(e.target.value)
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={false} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={true} key={'close'}>
                Close
            </Radio.Button>
        )

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const addComment = () => {
        form2
            .validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.leadId = selectedRecord.leadId
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))
                commentAttachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(
                    GLOBAL.URL.LEADS.ADD_LEAD_EXTERNAL_COMMENT,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENT_LATEST +
                                '?leadId=' +
                                selectedRecord.leadId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.leadId === selectedRecord.leadId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                                setCommentAttachmentFileList([])
                            })
                            .finally(() => {
                                closeAddCommentModal()
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateComments = () => {
        form.validateFields([
            'comments',
            'callDurationMins',
            'callDurationSecs',
        ])
            .then((values) => {
                setLoading(true)

                values.uid = selectedComment.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.UPDATE_LEAD_EXTERNAL_COMMENT,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        setEditCommentVisible(false)
                        getCartLeadAllComments(selectedRecord)

                        RestApi.doGet(
                            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENT_LATEST +
                                '?leadId=' +
                                selectedRecord.leadId
                        ).then((latestComment) => {
                            var fullData = data
                            fullData.forEach((item) => {
                                if (item.leadId === selectedRecord.leadId) {
                                    item.latestComment = latestComment.data
                                    return
                                }
                            })

                            setData(fullData)
                        })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const addCommentFromAllCommentsModal = () => {
        hideAllCommentsModal()
        openAddCommentModal(selectedRecord)
    }

    const allCommentsColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 140,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            onClick={() => {
                                editComment(record)
                            }}
                            style={{ padding: 0, marginRight: 10 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    ) : null}
                    {record.pinned ? (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{ color: '#1890ff', marginRight: 10 }}
                        />
                    ) : null}
                    <div>
                        <Linkify
                            as="p"
                            options={{
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }}
                            style={{
                                marginBlockStart: '0.4em',
                                marginBlockEnd: '0.4em',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {record.comments} <br />
                            {record.cloudPhoneCall
                                ? record.cloudPhoneCall.direction +
                                  '  ~  ' +
                                  record.cloudPhoneCall.status +
                                  (record.cloudPhoneCall.leg1Status
                                      ? '  ~  ' +
                                        record.cloudPhoneCall.leg1Status +
                                        ' / ' +
                                        (record.cloudPhoneCall.leg2Status
                                            ? record.cloudPhoneCall.leg2Status
                                            : '')
                                      : '')
                                : ''}
                        </Linkify>
                    </div>
                </div>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.callDurationMins || record.callDurationSecs ? (
                        <>
                            <span>
                                {record.callDurationMins +
                                    ':' +
                                    record.callDurationSecs}
                            </span>
                            {record.cloudPhoneCall &&
                            record.cloudPhoneCall.recordingUrl ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() =>
                                        showPlayAudioModal(
                                            record.cloudPhoneCall.recordingUrl
                                        )
                                    }
                                >
                                    <PlayCircleTwoTone
                                        style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                        }}
                                    />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => pinUnpinLeadComment(record)}
                        loading={pinUnpinLoading}
                    >
                        {record.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    {Utils.isUserAdmin() ? (
                        <>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteComment(record.uid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    tyle="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        fontSize: 9,
                                        marginLeft: 10,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Popconfirm>
                            {record.cloudPhoneCall ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginLeft: 5 }}
                                    onClick={() =>
                                        refreshCloudPhoneCall(record)
                                    }
                                >
                                    {loadingRefreshCloudPhoneCall ? (
                                        <LoadingOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    ) : (
                                        <ReloadOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    )}
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
    ]

    const showPlayAudioModal = (url) => {
        setPlayAudioModalVisible(true)
        setAudioUrl(url)
    }

    const hidePlayAudioModal = () => {
        setPlayAudioModalVisible(false)
    }

    const refreshCloudPhoneCall = (record) => {
        setLoadingRefreshCloudPhoneCall(true)

        var payload = {}
        payload.id = record.cloudPhoneCall.id
        payload.leadType = 'external'

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.REFRESH_CALL_STATUS, postBody)
            .then((response) => {
                message.success(response.data)
                getCartLeadAllComments(selectedRecord)
            })
            .finally(() => {
                setLoadingRefreshCloudPhoneCall(false)
            })
    }

    const pinUnpinLeadComment = (record) => {
        setPinUnpinLoading(true)

        var payload = {}
        payload.uid = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.LEADS.PIN_LEAD_EXTERNAL_COMMENT
        if (record.pinned) {
            url = GLOBAL.URL.LEADS.UNPIN_LEAD_EXTERNAL_COMMENT
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                getCartLeadAllComments(selectedRecord)
                notification['success']({
                    message: res.data,
                })
            })
            .finally(() => {
                setPinUnpinLoading(false)
            })
    }

    const editComment = (record) => {
        setEditCommentVisible(true)
        setSelectedComment(record)

        setTimeout(() => {
            form.setFieldsValue({
                comments: record.comments,
                callDurationMins: record.callDurationMins,
                callDurationSecs: record.callDurationSecs,
            })
        }, 100)
    }

    const deleteComment = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.DELETE_LEAD_EXTERNAL_COMMENT, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCartLeadAllComments(selectedRecord)

                RestApi.doGet(
                    GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENT_LATEST +
                        '?leadId=' +
                        selectedRecord.leadId
                ).then((latestComment) => {
                    var fullData = data
                    fullData.forEach((item) => {
                        if (item.leadId === selectedRecord.leadId) {
                            item.latestComment = latestComment.data
                            return
                        }
                    })

                    setData(fullData)
                })
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const bulkAssignLeads = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                if (selectedRows.length === 0) {
                    notification['error']({
                        message: 'No leads selected',
                    })
                    return
                }

                setLoading(true)

                var leadIds = []
                selectedRows.forEach((item) => {
                    leadIds.push(item.leadId)
                })

                values.leadIds = leadIds

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.ASSIGN_LEADS_EXTERNAL_BULK,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideBulkAssignModal()

                        var adminUser = {}
                        adminUsers.forEach((adminUserRow) => {
                            if (adminUserRow.id === values.assignToUserId) {
                                adminUser = adminUserRow
                            }
                        })

                        var fullData = clone(data)
                        selectedRows.forEach((item) => {
                            fullData.forEach((row) => {
                                if (row.leadId === item.leadId) {
                                    row.assignedTo = adminUser
                                    return
                                }
                            })
                        })

                        setData(fullData)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const leadCourseColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLeadCourse(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            fontSize: 9,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddLeadCourseModalVisible = () => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setAddLeadCourseModalVisible(true)
    }

    const hideAddLeadCourseModalVisible = () => {
        setAddLeadCourseModalVisible(false)
    }

    const courseOptions = () => {
        var options = []
        courses.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const addCourses = () => {
        form.validateFields(['courseIds', 'customCourseName'])
            .then((values) => {
                setLoading(true)

                values.leadId = selectedRecord.leadId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.ADD_LEAD_EXTERNAL_COURSES,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddLeadCourseModalVisible()
                        getLeadCourses(selectedRecord.leadId)
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteLeadCourse = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.DELETE_LEAD_EXTERNAL_COURSE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getLeadCourses(selectedRecord.leadId)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const addCallEntry = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.leadId = selectedRecord.leadId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.ADD_CALL_ENTRY_TO_EXTERNAL_LEAD,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENT_LATEST +
                                '?leadId=' +
                                selectedRecord.leadId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.leadId === selectedRecord.leadId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                                hideAddCallEntryModal()
                            })
                            .finally(() => {
                                closeAddCommentModal()
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showAddCallEntryModal = (record) => {
        setSelectedRecord(record)
        setAddCallEntryModalVisible(true)
    }

    const hideAddCallEntryModal = () => {
        setAddCallEntryModalVisible(false)
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const handleFilterCourseChange = (value) => {
        if (!value) {
            setFilterLevelData([])
            setStreamCourseFilter('')
            setStreamLevelFilter('')
        }

        streamData.forEach((item) => {
            if (item.course === value) {
                setFilterLevelData(item.levels)
                setStreamCourseFilter(value)
                setStreamLevelFilter('')
            }
        })
    }

    const filterStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Option value={item.course} key={item.course}>
                    {item.course}
                </Option>
            )
        })

        return options
    }

    const filterLevelOptions = () => {
        var options = []
        filterLevelData.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeFollowUpLeadType = (event) => {
        setFollowUpLeadType(event.target.value)
    }

    const onChangeLeadType = (e) => {
        getLeadSubTypes(e.target.value)
        form3.setFieldsValue({ leadSubType: '' })
    }

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (!closed ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
        })
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const showHighProspectModal = (record) => {
        setSelectedRecord(record)
        setHighProspectModalVisible(true)
        setExpectedSaleDate('')

        if (record.highProspectData && record.highProspectData.uid) {
            if (record.highProspectData.expectedSaleDate) {
                setExpectedSaleDate(record.highProspectData.expectedSaleDate)
                var expectedSaleDateMoment = dayjs(
                    record.highProspectData.expectedSaleDate,
                    'DD-MMM-YYYY'
                )
            }

            setTimeout(() => {
                form.setFieldsValue({
                    active: record.highProspectData.active,
                    expectedSale: record.highProspectData.expectedSale,
                    expectedSaleDate: expectedSaleDateMoment,
                })
            }, 100)
        } else {
            setTimeout(() => {
                form.setFieldsValue({
                    active: true,
                    expectedSale: 0,
                    expectedSaleDate: null,
                })
            }, 100)
        }
    }

    const hideHighProspectModal = () => {
        setSelectedRecord({})
        setHighProspectModalVisible(false)
    }

    const onChangeExpectedSaleDate = (value, dateString) => {
        setExpectedSaleDate(dateString ? dateString : '')
    }

    const updateHighProspectLead = () => {
        form.validateFields(['active', 'expectedSale'])
            .then((values) => {
                setLoading(true)

                var payload = values
                payload.expectedSaleDate = expectedSaleDate

                if (
                    selectedRecord.highProspectData &&
                    selectedRecord.highProspectData.uid
                ) {
                    payload.uid = selectedRecord.highProspectData.uid
                } else {
                    payload.leadExternalId = selectedRecord.leadId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.UPDATE_HIGH_PROSPECT_LEAD,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_BY_LEAD_ID +
                                selectedRecord.leadId
                        )
                            .then((res) => {
                                var fullData = clone(data)
                                fullData.forEach((item, index) => {
                                    if (item.leadId === selectedRecord.leadId) {
                                        fullData[index] = res.data
                                        return
                                    }
                                })

                                setData(fullData)
                                hideHighProspectModal()
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeHighProspectFilter = (event) => {
        setHighProspectsFilter(event.target.value)
    }

    const onUpdateLeadType = (value) => {
        const queryParams = new URLSearchParams(location.search)

        if (value) {
            queryParams.set('leadType', value)
        } else {
            queryParams.delete('leadType')
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        })
    }

    return (
        <div>
            <div>
                <div>
                    <Radio.Group
                        onChange={onChangeFilter}
                        defaultValue={filter}
                    >
                        <Radio.Button value={OPEN} key={OPEN}>
                            {'Open' +
                                (filter === OPEN && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={UNASSIGNED} key={UNASSIGNED}>
                            {'Unassigned' +
                                (filter === UNASSIGNED && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={FOLLOW_UP} key={FOLLOW_UP}>
                            {'Follow Up' +
                                (filter === FOLLOW_UP && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={CLOSED} key={CLOSED}>
                            {'Closed' +
                                (filter === CLOSED && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                    </Radio.Group>
                    <Button type="link" onClick={fetchData}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 15 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 15 }} />
                        )}
                    </Button>
                    <Button
                        type="primary"
                        style={{ marginLeft: 10 }}
                        onClick={showAddLeadModal}
                    >
                        Add Lead
                    </Button>
                    <Button
                        type="default"
                        disabled={selectedRows.length === 0}
                        onClick={showBulkAssignModal}
                        style={{ marginLeft: 10 }}
                    >
                        Bulk Assign
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Select
                        placeholder="Lead Type"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200 }}
                        onChange={onUpdateLeadType}
                        value={filterLeadType || undefined}
                    >
                        {openOrCloseFilterType
                            ? openStatusTypeOptions()
                            : closedStatusTypeOptions()}
                    </Select>
                    {filterSubTypes && filterSubTypes.length ? (
                        <Select
                            placeholder="Lead Sub Type"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 200, marginLeft: 10 }}
                            onChange={onChangeFilterLeadSubType}
                            value={filterLeadSubType || undefined}
                        >
                            {filterLeadSubTypeOptions()}
                        </Select>
                    ) : null}
                    <Select
                        placeholder="Filter By Asignee"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200, marginLeft: 10 }}
                        onChange={onChangeAsignee}
                        value={selectedAsignee || undefined}
                    >
                        {getAdminUserOptions()}
                    </Select>
                    <RangePicker
                        format="DD-MMM-YY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateFilterChange}
                        style={{ marginLeft: 10 }}
                        value={
                            startDate && endDate
                                ? [dayjs(startDate), dayjs(endDate)]
                                : null
                        }
                    />
                    <span style={{ marginLeft: 10 }}>
                        <span>{'High Prospect: '}</span>
                        <Radio.Group
                            onChange={onChangeHighProspectFilter}
                            value={highProspectsFilter}
                        >
                            <Radio.Button value={'all'} key={'all'}>
                                All
                            </Radio.Button>
                            <Radio.Button value={'active'} key={'active'}>
                                Active
                            </Radio.Button>
                            <Radio.Button value={'inactive'} key={'inactive'}>
                                Closed
                            </Radio.Button>
                        </Radio.Group>
                    </span>
                </div>
                {filter === FOLLOW_UP ? (
                    <div style={{ marginTop: 10 }}>
                        <Radio.Group
                            onChange={onChangeFollowUpLeadType}
                            defaultValue={followUpLeadType}
                        >
                            <Radio.Button value={'open'}>Open</Radio.Button>
                            <Radio.Button value={'closed'}>Closed</Radio.Button>
                        </Radio.Group>
                    </div>
                ) : null}
                <div style={{ marginTop: 10 }}>
                    <span>
                        <Input
                            style={{ width: 200 }}
                            placeholder="Phone Ending (, separated)"
                            onChange={(e) => setPhoneEndingWith(e.target.value)}
                            onPressEnter={fetchData}
                        />
                    </span>
                    <span style={{ marginLeft: 10 }}>
                        <Input
                            style={{ width: 200 }}
                            placeholder="Email Starting (, separated)"
                            onChange={(e) =>
                                setEmailStartingWith(e.target.value)
                            }
                            onPressEnter={fetchData}
                        />
                    </span>
                    <Search
                        placeholder="Search Lead"
                        value={searchQuery}
                        onSearch={(value) => onSearchLead(value)}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ width: 200, marginLeft: 10 }}
                    />
                    <Select
                        placeholder="Stream Course"
                        onChange={(value) => handleFilterCourseChange(value)}
                        showSearch
                        style={{ width: 200, marginLeft: 10 }}
                        allowClear={true}
                        value={streamCourseFilter || undefined}
                    >
                        {filterStreamOptions()}
                    </Select>
                    {streamCourseFilter ? (
                        <Select
                            placeholder="Stream level"
                            showSearch
                            style={{ width: 200, marginLeft: 10 }}
                            value={streamLevelFilter || undefined}
                            onChange={(value) =>
                                setStreamLevelFilter(value ? value : '')
                            }
                            allowClear={true}
                        >
                            {filterLevelOptions()}
                        </Select>
                    ) : null}
                </div>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="leadId"
                style={{ marginTop: 10 }}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    More Data
                </Button>
            ) : null}
            <Modal
                title={'Configure Lead'}
                open={addLeadModalVisible}
                onOk={() => {
                    addOrUpdateLead()
                }}
                confirmLoading={loading}
                onCancel={hideAddLeadModal}
                okText={selectedRecord.leadId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                <div style={{ marginLeft: 75, marginBottom: 5 }}>
                    <span>Lead Status: </span>
                    <Radio.Group onChange={onOpenCloseChange} value={closed}>
                        {openCloseOptions()}
                    </Radio.Group>
                </div>
                <Form
                    form={form3}
                    initialValues={{
                        callDurationMins: 0,
                        callDurationSecs: 0,
                    }}
                    layout="horizontal"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Name"
                        style={{ marginBottom: 0 }}
                        name="name"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        style={{ marginBottom: 0 }}
                        name="email"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="ISD"
                        style={{ marginBottom: 0 }}
                        name="isd"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        style={{ marginBottom: 0 }}
                        name="phone"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Lead Type"
                        style={{ marginBottom: 0 }}
                        name="leadType"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeLeadType}>
                            {!closed
                                ? openStatusTypeOptions()
                                : closedStatusTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {leadSubTypes.length > 0 ? (
                        <Form.Item
                            label="Lead Sub Type"
                            style={{ marginBottom: 5 }}
                            name="leadSubType"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{leadSubTypeOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Assign To"
                        style={{ marginBottom: 0 }}
                        name="assignedTo"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Follow Up"
                        style={{ marginBottom: 0 }}
                        name="followUpDate"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            showTime
                            onChange={onChangeReminderDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Source"
                        style={{ marginBottom: 0 }}
                        name="source"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{leadSourceOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        style={{ marginBottom: 0 }}
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            style={{ marginBottom: 0 }}
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {levelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Call Mins"
                        style={{ marginBottom: 0 }}
                        name="callDurationMins"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 0 }}
                        name="callDurationSecs"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                    {!selectedRecord.leadId ? (
                        <>
                            <Form.Item
                                label="Courses Interested In"
                                style={{ marginBottom: 0 }}
                                name="courseIds"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Courses"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                >
                                    {courseOptions()}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Custom Course Name"
                                style={{ marginBottom: 0 }}
                                name="customCourseName"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    ) : null}
                    <Form.Item
                        label="Comments"
                        style={{ marginBottom: 0 }}
                        name="comments"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Comments" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={moreInfoModalVisible}
                onCancel={hideMoreInfoModal}
                destroyOnClose={true}
                footer={null}
                width={800}
            >
                <UserAcquisitionInfo leadExternalId={selectedRecord.leadId} />
                <Descriptions title="More Info" bordered size="small">
                    <Descriptions.Item label="Added By" span={3}>
                        {selectedRecord.addedBy
                            ? selectedRecord.addedBy.firstName
                            : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Update By" span={3}>
                        {selectedRecord.updatedBy ? (
                            <span>
                                {selectedRecord.updatedBy.firstName} {' ('}
                                <span style={{ fontSize: 11 }}>
                                    {selectedRecord.updatedAt}
                                </span>{' '}
                                {')'}
                            </span>
                        ) : (
                            ''
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Source" span={3}>
                        {selectedRecord.source}
                    </Descriptions.Item>
                </Descriptions>
                <h4 style={{ marginTop: 20 }}>Courses Interested</h4>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddLeadCourseModalVisible}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={leadCourseColumns}
                    dataSource={leadCourses}
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="uid"
                    loading={loading}
                />
            </Modal>
            <Modal
                title={'Add Comment'}
                open={addCommentModalVisible}
                onOk={() => {
                    addComment()
                }}
                confirmLoading={loading}
                onCancel={closeAddCommentModal}
                okText={'Add Comment'}
                destroyOnClose={true}
                width={800}
            >
                <span>
                    Lead Name: <strong>{selectedRecord.name}</strong>
                </span>
                {addCommentModalVisible ? (
                    <Form
                        form={form2}
                        layout="horizontal"
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item
                            label="Comments"
                            name="comments"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Comments"
                                autoFocus
                                ref={inputRef}
                                onPaste={(e) =>
                                    processClipboardAttachment(
                                        e,
                                        setCommentAttachmentFileList
                                    )
                                }
                            />
                        </Form.Item>
                    </Form>
                ) : null}

                <div style={{ marginTop: 10, marginLeft: 30 }}>
                    <ILAttachmentFileList
                        attachmentFileList={commentAttachmentFileList}
                        setAttachmentFileList={setCommentAttachmentFileList}
                    />
                </div>
            </Modal>
            <Modal
                title={'Comments'}
                open={allCommentsModalVisible}
                onCancel={hideAllCommentsModal}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <span>
                    Student: <strong>{selectedRecord.name}</strong>
                </span>

                {editCommentVisible ? (
                    <div style={{ marginTop: 10 }}>
                        <h4 style={{ textDecoration: 'underline' }}>
                            Update Comment
                        </h4>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                            style={{ marginTop: 20 }}
                            initialValues={{
                                callDurationMins: 0,
                                callDurationSecs: 0,
                            }}
                        >
                            <Form.Item
                                label="Comments"
                                style={{ marginBottom: 5 }}
                                name="comments"
                                valuePropName="value"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <TextArea
                                    placeholder="Comments"
                                    autoFocus
                                    ref={inputRef}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Call Mins"
                                style={{ marginBottom: 5 }}
                                name="callDurationMins"
                                valuePropName="value"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={100} step={1} />
                            </Form.Item>
                            <Form.Item
                                label="Call Secs"
                                style={{ marginBottom: 5 }}
                                name="callDurationSecs"
                                valuePropName="value"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={59} step={1} />
                            </Form.Item>
                        </Form>
                        <Button
                            type="primary"
                            size="small"
                            loading={loading}
                            onClick={updateComments}
                        >
                            Update
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            loading={loading}
                            onClick={() => setEditCommentVisible(false)}
                            style={{ marginLeft: 10 }}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                addCommentFromAllCommentsModal()
                            }}
                        >
                            Add Comment
                        </Button>
                        {allCommentsPinned.length ? (
                            <Radio.Group
                                value={selectedCommentFilter}
                                onChange={(e) =>
                                    setSelectedCommentFilter(e.target.value)
                                }
                                style={{ marginLeft: 10 }}
                            >
                                <Radio.Button
                                    value={COMMENT_FILTER_ALL}
                                    key={COMMENT_FILTER_ALL}
                                >
                                    All
                                </Radio.Button>
                                <Radio.Button
                                    value={COMMENT_FILTER_PINNED}
                                    key={COMMENT_FILTER_PINNED}
                                >
                                    {'Pinned (' +
                                        allCommentsPinned.length +
                                        ')'}
                                </Radio.Button>
                            </Radio.Group>
                        ) : null}
                        <Button
                            type="link"
                            onClick={() =>
                                getCartLeadAllComments(selectedRecord)
                            }
                        >
                            {loadingComments ? (
                                <LoadingOutlined style={{ fontSize: 20 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 20 }} />
                            )}
                        </Button>
                    </div>
                )}
                <Table
                    columns={allCommentsColumns}
                    dataSource={
                        selectedCommentFilter === COMMENT_FILTER_ALL
                            ? allComments
                            : allCommentsPinned
                    }
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="uid"
                    loading={loadingComments}
                />
            </Modal>
            <Modal
                title={'Bulk Assign'}
                open={bulkAssignModalVisible}
                onOk={() => {
                    bulkAssignLeads()
                }}
                confirmLoading={loading}
                onCancel={hideBulkAssignModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Courses'}
                open={addLeadCourseModalVisible}
                onOk={() => {
                    addCourses()
                }}
                confirmLoading={loading}
                onCancel={hideAddLeadCourseModalVisible}
                okText={'Add'}
                destroyOnClose={true}
                width={900}
            >
                <span>
                    Lead Name: <strong>{selectedRecord.name}</strong>
                </span>
                <Form
                    layout="horizontal"
                    form={form}
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Courses Interested In"
                        name="courseIds"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Custom Course Name"
                        name="customCourseName"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Call Entry'}
                open={addCallEntryModalVisible}
                onOk={() => {
                    addCallEntry()
                }}
                confirmLoading={loading}
                onCancel={hideAddCallEntryModal}
                okText={'Add'}
                destroyOnClose={true}
            >
                <h4>{selectedRecord.name}</h4>
                <Form
                    form={form}
                    initialValues={{ callDurationMins: 0, callDurationSecs: 0 }}
                    layout="horizontal"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Call Mins"
                        name="callDurationMins"
                        style={{ marginBottom: 5 }}
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 5 }}
                        name="callDurationSecs"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'High Prospect Lead'}
                open={highProspectModalVisible}
                onOk={updateHighProspectLead}
                confirmLoading={loading}
                onCancel={hideHighProspectModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                {highProspectModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Active"
                            style={{ marginBottom: 10 }}
                            name="active"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio.Button value={true} key={'Yes'}>
                                    {'Yes'}
                                </Radio.Button>
                                <Radio.Button value={false} key={'No'}>
                                    {'No'}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale"
                            style={{ marginBottom: 10 }}
                            name="expectedSale"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber autoFocus min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale Date"
                            style={{ marginBottom: 10 }}
                            name="expectedSaleDate"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Select Date"
                                format="DD-MMM-YYYY"
                                onChange={onChangeExpectedSaleDate}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                open={playAudioModalVisible}
                title={'Phone Call Recording'}
                onCancel={hidePlayAudioModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <ReactAudioPlayer src={audioUrl} autoPlay controls />
            </Modal>
        </div>
    )
}

export default ExternalLeads
