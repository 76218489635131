import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    Radio,
    Select,
    InputNumber,
    Table,
    DatePicker,
    Checkbox,
} from 'antd'

function PurchaseStats(props) {
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [monthData, setMonthData] = useState([])
    const [limit, setLimit] = useState(6)
    const [stream, setStream] = useState('')
    const [level, setLevel] = useState('')
    const [indOrFullSubject, setIndOrFullSubject] = useState('all')
    const [courseNames, setCourseNames] = useState([])
    const [attemptNames, setAttemptNames] = useState([])
    const [transactionType, setTransactionType] = useState('all')
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [courseNamesList, setCourseNamesList] = useState([])
    const [attemptsList, setAttemptsList] = useState([])
    const [userJoiningStartDate, setUserJoiningStartDate] = useState('')
    const [userJoiningEndDate, setUserJoiningEndDate] = useState('')
    const [
        userJoiningDateRangePivotTableStats,
        setUserJoiningDateRangePivotTableStats,
    ] = useState(true)
    const [pivotStats, setPivotStats] = useState('')

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCourseNames()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stream, level, indOrFullSubject])

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttemptsList(res.data)
        })
    }

    const getCourseNames = () => {
        var url =
            GLOBAL.URL.GET_COURSE_NAMES +
            '?course=' +
            stream +
            '&level=' +
            level +
            '&indOrFullSubject=' +
            (indOrFullSubject === 'all' ? 'both' : indOrFullSubject)

        RestApi.doGet(url).then((res) => {
            setCourseNamesList(res.data)
        })
    }

    const getMonthlyData = () => {
        setLoading(true)

        var payload = {}
        payload.limit = limit
        payload.stream = stream
        payload.level = level
        payload.indOrFullSubject = indOrFullSubject
        payload.courseNames = courseNames
        payload.attemptNames = attemptNames
        payload.transactionType = transactionType
        payload.userJoiningStartDate = userJoiningStartDate
        payload.userJoiningEndDate = userJoiningEndDate
        payload.userJoiningDateRangePivotTableStats =
            userJoiningDateRangePivotTableStats

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STATS.GET_PURCHASE_STATS_BY_MONTH, formData)
            .then((res) => {
                if (
                    userJoiningStartDate &&
                    userJoiningDateRangePivotTableStats
                ) {
                    setPivotStats(res.data)
                } else {
                    setMonthData(res.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeStreamCourse = (event) => {
        setStream(event.target.value)
        setLevelData([])
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                return
            }
        })
    }

    const onChangeStreamLevel = (event) => {
        setLevel(event.target.value)
    }

    const getStreamCourseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        if (!stream) {
            return options
        }

        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeIndOrFullSubject = (e) => {
        setIndOrFullSubject(e.target.value)
    }

    const onChangeTransactionType = (e) => {
        setTransactionType(e.target.value)
    }

    const getCourseNameOptions = () => {
        var courseOptions = []
        courseNamesList.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return courseOptions
    }

    const onChangeCourseNames = (value) => {
        setCourseNames(value)
    }

    const onClearCourseNames = () => {
        setCourseNames([])
    }

    const onChangeAttempts = (value) => {
        setAttemptNames(value)
    }

    const onClearAttempts = () => {
        setAttemptNames([])
    }

    const getAttemptOptions = () => {
        var attemptOptions = []
        attemptsList.forEach((attempt) => {
            attemptOptions.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        return attemptOptions
    }

    const columnsMonthWise = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Month',
            dataIndex: 'label',
            key: 'label',
            width: 75,
        },
        {
            title: 'Unique Users',
            dataIndex: 'uniqueUsers',
            key: 'uniqueUsers',
            width: 100,
            render: (text, record) => (
                <span>
                    {record.uniqueUsers +
                        ' (' +
                        record.uniqueUsersCummulative +
                        ')'}
                </span>
            ),
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
            width: 150,
            render: (text, record) => (
                <span>{record.mrp + ' (' + record.mrpCummulative + ')'}</span>
            ),
        },
        {
            title: 'Subjects Count',
            dataIndex: 'subjectsCount',
            key: 'subjectsCount',
            width: 100,
            render: (text, record) => (
                <span>
                    {record.subjectsCount +
                        ' (' +
                        record.subjectsCountCummulative +
                        ')'}
                </span>
            ),
        },
        {
            title: 'Transactions Count',
            dataIndex: 'mrp',
            key: 'mrp',
            width: 120,
            render: (text, record) => (
                <span>
                    {record.count + ' (' + record.countCummulative + ')'}
                </span>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 150,
            render: (text, record) => (
                <span>
                    {record.price + ' (' + record.priceCummulative + ')'}
                </span>
            ),
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discount',
            key: 'discount',
            width: 150,
        },
        {
            title: 'iCash',
            dataIndex: 'credits',
            key: 'credits',
            width: 75,
        },
        {
            title: 'Combo Discount',
            dataIndex: 'comboDiscount',
            key: 'comboDiscount',
        },
    ]

    const onUserJoiningDateChange = (value, dateString) => {
        setUserJoiningStartDate(dateString[0])
        setUserJoiningEndDate(dateString[1])
    }

    const onChangePivotTable = (e) => {
        setUserJoiningDateRangePivotTableStats(e.target.checked)
    }

    return (
        <div>
            <h2>Purchase Stats</h2>
            <div>
                <Radio.Group onChange={onChangeStreamCourse} value={stream}>
                    {getStreamCourseOptions()}
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeStreamLevel}
                    style={{ marginLeft: 20 }}
                    value={level}
                >
                    {getStreamLevelOptions()}
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeIndOrFullSubject}
                    value={indOrFullSubject}
                >
                    <Radio.Button value="all" key={'all'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="full" key={'full'}>
                        {'Full Subjects'}
                    </Radio.Button>
                    <Radio.Button value="ind" key={'ind'}>
                        {'Individual Modules'}
                    </Radio.Button>
                </Radio.Group>
                <Select
                    placeholder="Select Courses"
                    showSearch
                    style={{
                        marginLeft: 10,
                        width: 800,
                    }}
                    allowClear={true}
                    onChange={onChangeCourseNames}
                    onClear={onClearCourseNames}
                    mode="multiple"
                >
                    {getCourseNameOptions()}
                </Select>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeTransactionType}
                    value={transactionType}
                >
                    <Radio.Button value="all" key={'all'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="purchase" key={'purchase'}>
                        {'Purchase'}
                    </Radio.Button>
                    <Radio.Button value="extension" key={'extension'}>
                        {'Extension'}
                    </Radio.Button>
                </Radio.Group>
                <Select
                    placeholder="Select Attempts"
                    showSearch
                    style={{
                        marginLeft: 10,
                        width: 880,
                    }}
                    allowClear={true}
                    onChange={onChangeAttempts}
                    onClear={onClearAttempts}
                    mode="multiple"
                >
                    {getAttemptOptions()}
                </Select>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>User Joining Range: </span>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onUserJoiningDateChange}
                    style={{ marginLeft: 10 }}
                />
                {userJoiningStartDate ? (
                    <Checkbox
                        style={{ marginLeft: 10 }}
                        checked={userJoiningDateRangePivotTableStats}
                        onChange={onChangePivotTable}
                    >
                        Pivot Table
                    </Checkbox>
                ) : null}
            </div>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={getMonthlyData}
                    loading={loading}
                >
                    Fetch Data
                </Button>
                {(attemptNames && attemptNames.length) ||
                userJoiningStartDate ? null : (
                    <>
                        <span style={{ marginLeft: 10 }}>Limit:</span>
                        <InputNumber
                            min={1}
                            value={limit}
                            onChange={(value) => setLimit(value)}
                            style={{ marginLeft: 10 }}
                        />
                    </>
                )}
            </div>
            {userJoiningStartDate && userJoiningDateRangePivotTableStats ? (
                <div
                    style={{ marginTop: 20 }}
                    dangerouslySetInnerHTML={{ __html: pivotStats }}
                />
            ) : (
                <Table
                    columns={columnsMonthWise}
                    dataSource={monthData}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="label"
                    style={{ marginTop: 10 }}
                />
            )}
        </div>
    )
}

export default PurchaseStats
