import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Radio,
    Form,
} from 'antd'
import { CopyOutlined, EditTwoTone, WechatOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import clone from 'clone'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MetaFormItems from '../Common/MetaFormItems'
import { Link } from '@mui/material'

const FILTER_ALL = 'all'
const FILTER_PUBLISHED = 'published'
const FILTER_UNPUBLISHED = 'unpublished'

function BlogPage(props) {
    const { Option } = Select
    const { TextArea } = Input
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [content, setContent] = useState('')
    const [authors, setAuthors] = useState([])
    const [updateAuthorModalVisible, setUpdateAuthorModalVisible] =
        useState(false)
    const [tags, setTags] = useState([])
    const [updateTagsModalVisible, setUpdateTagsModalVisible] = useState(false)
    const [contentModalVisible, setContentModalVisible] = useState(false)
    const [categoriesModalVisible, setCategoriesModalVisible] = useState(false)
    const [categoryMappings, setCategoryMappings] = useState([])
    const [addBlogMappingModalVisible, setAddBlogMappingModalVisible] =
        useState(false)
    const [categories, setCategories] = useState([])
    const [filter, setFilter] = useState(FILTER_ALL)
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getPages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getPages = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_PAGES + '?filter=' + filter)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const searchPage = (query) => {
        if (!query) {
            getPages()
            return
        }

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.SEARCH_PAGES + encodeURIComponent(query))
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageBlogs = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getPage = (blogId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_PAGE + '?blogId=' + blogId)
            .then((response) => {
                var fullData = clone(data)
                fullData.forEach((item, index) => {
                    if (item.blogId === blogId) {
                        fullData[index] = response.data
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)

        if (record.blogId) {
            setContent(record.content)
        } else {
            setContent('')
        }

        if (record.blogId) {
            setTimeout(() => {
                form.setFieldsValue({
                    title: record.title,
                    image: record.image,
                    imageWide: record.imageWide,
                    previewContent: record.previewContent,
                })
                setFormFieldValues(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const setFormFieldValues = (record) => {
        var metaArrayFields = [0]
        var meta = []
        var length = 1
        var seoObj = null
        if (record.seo) {
            seoObj = JSON.parse(record.seo)
            if (seoObj.meta) {
                var filtered = seoObj.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                if (filtered.length) {
                    metaArrayFields = []
                    meta = filtered
                    length = filtered.length

                    for (var k = 0; k < length; k++) {
                        metaArrayFields.push(k)
                    }
                }
            }
        }

        setTimeout(() => {
            form.setFieldsValue({
                meta: meta,
                seoTitle: seoObj ? seoObj.title : '',
                bodyEnd: seoObj ? seoObj.bodyEnd : '',
            })
        }, 100)
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const addOrUpdatePage = () => [
        form
            .validateFields()
            .then((values) => {
                if (!content) {
                    notification['error']({
                        message: 'Page content cannot be empty',
                    })

                    return
                }

                setLoading(true)
                let filtered = values.meta.filter((el) => {
                    return el != null && el.property && el.content
                })

                let seo = {}
                seo.title = values.seoTitle ? values.seoTitle : ''
                seo.meta = filtered
                seo.bodyEnd = values.bodyEnd ? values.bodyEnd : ''

                values.seo = seo
                values.content = content

                var url = GLOBAL.URL.BLOG.ADD_PAGE
                if (selectedRecord.blogId) {
                    url = GLOBAL.URL.BLOG.UPDATE_PAGE
                    values.blogId = selectedRecord.blogId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        if (selectedRecord.blogId) {
                            getPage(selectedRecord.blogId)
                        } else {
                            getPages()
                        }
                    })
                    .finally(() => setLoading(false))
            })
            .catch(() => {
                //empty catch block
            }),
    ]

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'blogId',
            key: 'blogId',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Published',
            dataIndex: 'published',
            key: 'published',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => publishUnpublish(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.published ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/blog/comments/' + record.blogId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/blog/comments/' + record.blogId, {
                                state: { blogId: record.blogId },
                            })
                        }}
                        underline="none"
                    >
                        <WechatOutlined />
                    </Link>
                    <span style={{ marginLeft: 5 }}>{record.title}</span>
                    {record.publishedAt ? (
                        <>
                            <br />
                            <span style={{ fontSize: 10 }}>
                                Published at:{' '}
                                {record.publishedAt +
                                    ' (' +
                                    record.publishedBy.firstName +
                                    ')'}
                            </span>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Views',
            dataIndex: 'views',
            key: 'views',
        },
        {
            title: 'Deep Link',
            dataIndex: 'deepLink',
            key: 'deepLink',
            render: (text, record) => (
                <span>
                    {record.deepLink ? (
                        <CopyToClipboard
                            text={record.deepLink}
                            onCopy={() =>
                                notification['success']({
                                    message: 'Link copied!',
                                })
                            }
                        >
                            <span>
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontSize: 10 }}
                                >
                                    <CopyOutlined />
                                    {'Copy Link'}
                                </Button>
                            </span>
                        </CopyToClipboard>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            width: 120,
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateAthorModal(record)
                        }}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    {record.author.id ? (
                        <span>{record.author.firstName}</span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showContentModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        Content
                    </Button>
                </span>
            ),
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateTagModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        Tags
                    </Button>
                </span>
            ),
        },
        {
            title: 'Categories',
            dataIndex: 'categories',
            key: 'categories',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showCategoriesModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        Categories
                    </Button>
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 100,
            render: (text, record) => <span>{record.updatedBy.firstName}</span>,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deletePage(record.blogId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deletePage = (blogId) => {
        setLoading(true)

        var payload = {}
        payload.blogId = blogId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_PAGE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getPages()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const publishUnpublish = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.BLOG.PUBLISH_PAGE
        if (record.published) {
            url = GLOBAL.URL.BLOG.UNPUBLISH_PAGE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ blogId: record.blogId }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getPage(record.blogId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showUpdateAthorModal = (record) => {
        if (authors.length === 0) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BLOG.GET_AUTHORS)
                .then((res) => {
                    setAuthors(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        setSelectedRecord(record)
        setUpdateAuthorModalVisible(true)
    }

    const hideUpdateAthorModal = (record) => {
        setUpdateAuthorModalVisible(false)
    }

    const getAuthorOptions = () => {
        var options = []
        authors.forEach((author) => {
            options.push(
                <Option
                    value={author.id}
                    key={author.firstName + ' ' + author.lastName}
                >
                    {author.firstName + ' ' + author.lastName}
                </Option>
            )
        })

        return options
    }

    const getTagOptions = () => {
        var options = []
        tags.forEach((tag) => {
            options.push(
                <Option value={tag.name} key={tag.name}>
                    {tag.name}
                </Option>
            )
        })

        return options
    }

    const updateAuthor = () => {
        form.validateFields(['authorId'])
            .then((values) => {
                setLoading(true)

                values.blogId = selectedRecord.blogId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BLOG.UPDATE_AUTHOR_IN_PAGE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateAthorModal()
                        getPage(selectedRecord.blogId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateTagModal = (record) => {
        if (tags.length === 0) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BLOG.GET_TAGS)
                .then((res) => {
                    setTags(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        setSelectedRecord(record)
        setUpdateTagsModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                tagNames: record.tagNames,
            })
        }, 100)
    }

    const hideUpdateTagModal = (record) => {
        setUpdateTagsModalVisible(false)
    }

    const updateTags = () => {
        form.validateFields(['tagNames'])
            .then((values) => {
                setLoading(true)

                values.blogId = selectedRecord.blogId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BLOG.UPDATE_TAGS_IN_PAGE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateTagModal()
                        getPage(selectedRecord.blogId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showContentModal = (record) => {
        setSelectedRecord(record)
        setContentModalVisible(true)
    }

    const hideContentModal = () => {
        setContentModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const showCategoriesModal = (record) => {
        setCategoryMappings([])
        setSelectedRecord(record)
        setCategoriesModalVisible(true)
        getCategoryMappings(record.blogId)
    }

    const getCategoryMappings = (blogId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_PAGE_CATEGORIES_MAPPINGS + blogId)
            .then((res) => {
                setCategoryMappings(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideCategoriesModal = () => {
        setCategoriesModalVisible(false)
    }

    const categoryMappingTableColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisableCategoryMapping(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCategoryMapping(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const enableDisableCategoryMapping = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.BLOG.ENABLE_BLOG_IN_CATEGORY
        if (record.enabled) {
            url = GLOBAL.URL.BLOG.DISABLE_BLOG_IN_CATEGORY
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(categoryMappings)
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setCategoryMappings(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteCategoryMapping = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_BLOG_FROM_CATEGORY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCategoryMappings(selectedRecord.blogId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAddBlogMappingModal = () => {
        setAddBlogMappingModalVisible(true)

        if (categories.length === 0) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BLOG.GET_BLOG_CATEGORIES)
                .then((res) => {
                    setCategories(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const hideAddBlogMappingModal = () => {
        setAddBlogMappingModalVisible(false)
    }

    const getCategoryOptions = () => {
        var options = []
        categories.forEach((category) => {
            options.push(
                <Option value={category.categoryId} key={category.name}>
                    {category.name}
                </Option>
            )
        })

        return options
    }

    const addCategoryMapping = () => {
        form.validateFields(['categoryId'])
            .then((values) => {
                setLoading(true)

                values.blogId = selectedRecord.blogId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BLOG.ADD_BLOG_TO_CATEGORY, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddBlogMappingModal()
                        getCategoryMappings(selectedRecord.blogId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <h2>Blog Pages</h2>
            <div>
                <Button type="primary" onClick={showAddEditModal}>
                    Add
                </Button>
                <Search
                    placeholder="Search Blog"
                    onSearch={(value) => searchPage(value)}
                    style={{ width: 500, marginLeft: 10 }}
                    autoFocus
                    allowClear
                />
                <Radio.Group
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_PUBLISHED}
                        key={FILTER_PUBLISHED}
                    >
                        {'Published'}
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_UNPUBLISHED}
                        key={FILTER_UNPUBLISHED}
                    >
                        {'Not Published'}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="blogId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageBlogs}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Page'}
                open={addEditModalVisible}
                onOk={addOrUpdatePage}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.blogId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Title"
                        style={{ padding: 0, margin: 0, marginBottom: 8 }}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Image"
                        style={{ padding: 0, margin: 0, marginBottom: 8 }}
                        name="image"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Image Wide"
                        style={{ padding: 0, margin: 0, marginBottom: 8 }}
                        name="imageWide"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Preview Content"
                        style={{ padding: 0, margin: 0 }}
                        name="previewContent"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Preview Content" />
                    </Form.Item>
                    <div style={{ marginTop: 10 }}>
                        <h4>Page Content</h4>
                        <TinyMceEditor
                            height={400}
                            handleEditorChange={handleEditorChange}
                            initialValue={content}
                        />
                    </div>
                    <Form.Item
                        label="SEO: Title"
                        style={{ marginBottom: 8, marginTop: 12 }}
                        name="seoTitle"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="SEO: Title" />
                    </Form.Item>
                    <Form.Item
                        label="SEO: Body End"
                        style={{ marginBottom: 8 }}
                        name="bodyEnd"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                    <MetaFormItems />
                </Form>
            </Modal>
            <Modal
                title={'Configure Author'}
                open={updateAuthorModalVisible}
                onOk={updateAuthor}
                confirmLoading={loading}
                onCancel={hideUpdateAthorModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                {updateAuthorModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Author"
                            name="authorId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Author"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getAuthorOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Configure Tags'}
                open={updateTagsModalVisible}
                onOk={updateTags}
                confirmLoading={loading}
                onCancel={hideUpdateTagModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                {updateTagsModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Tags"
                            name="tagNames"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Tags"
                                showSearch
                                style={{ width: '100%' }}
                                mode="multiple"
                            >
                                {getTagOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Blog Content'}
                open={contentModalVisible}
                confirmLoading={loading}
                onCancel={hideContentModal}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <u>Preview Content</u>
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        selectedRecord.previewContent
                    )}
                />
                <br />
                <u>Content:</u>
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        selectedRecord.content
                    )}
                />
            </Modal>
            <Modal
                title={'Linked Categories'}
                open={categoriesModalVisible}
                confirmLoading={loading}
                onCancel={hideCategoriesModal}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddBlogMappingModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={categoryMappingTableColumns}
                    dataSource={categoryMappings}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="categoryName"
                    style={{ marginTop: 10 }}
                    scroll={{ y: 400 }}
                />
            </Modal>
            <Modal
                title={'Add Blog To Category'}
                open={addBlogMappingModalVisible}
                onOk={addCategoryMapping}
                confirmLoading={loading}
                onCancel={hideAddBlogMappingModal}
                okText={'Add'}
                destroyOnClose={true}
                width={900}
            >
                {addBlogMappingModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Select Category"
                            name="categoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Category"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                            >
                                {getCategoryOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default BlogPage
