import {
    message,
    Table,
    Row,
    Button,
    Col,
    Modal,
    Input,
    Upload,
    Select,
    Popconfirm,
    Form,
} from 'antd'
import { CopyOutlined, EditTwoTone, UploadOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { Option } = Select
const EDIT_CONSTANT = 'Edit Name'
const LINK_TYPE = 'link'
const Files = (props) => {
    const [resourceFilesData, setResourceFilesData] = useState([])
    const [nextUrl, setNextUrl] = useState('')
    const [addEditFileModalVisible, setAddEditFileModalVisible] =
        useState(false)
    const [editFileId, setEditFileId] = useState('')
    const [loadingFileModal, setLoadingFileModal] = useState(false)
    const [fileList, setFileList] = useState([])
    const [fileTypes, setFileTypes] = useState([])
    const [nameVisible, setNameVisible] = useState(false)
    const [isDataType, setIsDataType] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileTypeVisible, setFileTypeVisible] = useState(false)
    const [form] = Form.useForm()

    const searchResources = (query) => {
        if (!query) {
            return
        }

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_FILES + encodeURIComponent(query))
            .then((response) => {
                var res = JSON.parse(response.data)
                setResourceFilesData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPage = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setResourceFilesData([...resourceFilesData, ...res.data])
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSubmit = (id) => {
        setLoadingFileModal(true)

        form.validateFields()
            .then((values) => {
                var url = ''
                if (nameVisible && fileTypeVisible) {
                    url = GLOBAL.URL.ADD_FILES
                } else if (nameVisible) {
                    url = GLOBAL.URL.UPDATE_FILE_NAME
                } else if (fileTypeVisible) {
                    url = GLOBAL.URL.UPDATE_FILE_CONTENT
                }
                if (id) {
                    values['id'] = id
                }
                var formBodyFileObject = new FormData()
                formBodyFileObject.append('payload', JSON.stringify(values))
                if (Object.keys(fileList).length > 0) {
                    formBodyFileObject.append('file', fileList[0])
                }
                RestApi.doPost(url, formBodyFileObject).then((response) => {
                    message.success(
                        id ? 'Edited Successfully!' : 'Added Successfully!'
                    )
                    var FileJsonArray = []
                    FileJsonArray.push(response.data)
                    if (id) {
                        if (nameVisible) {
                            resourceFilesData.forEach((item, index) => {
                                if (item.id === id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            resourceFilesData.forEach((item, index) => {
                                if (item.id === id) {
                                    item.data = values.data
                                    item.type = values.fileType
                                    item.fileName =
                                        Object.keys(fileList).length > 0
                                            ? fileList[0].name
                                            : null
                                }
                            })
                        }
                    } else {
                        setResourceFilesData([
                            ...resourceFilesData,
                            ...FileJsonArray,
                        ])
                    }
                    cancelFileModal()
                })
            })
            .catch(() => {
                //empty catch block
            })
            .finally(() => {
                setLoadingFileModal(false)
            })
    }
    const cancelFileModal = () => {
        setAddEditFileModalVisible(false)
        setEditFileId('')
        setNameVisible(false)
        setFileTypeVisible(false)
        setIsDataType(false)
        setFileList([])
    }

    const openFileModal = (record, editType) => {
        if (Object.keys(fileTypes).length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_FILE_TYPES)
                .then((res) => {
                    setFileTypes(res.data)
                })
                .catch((error) => {
                    message.error(error)
                })
        }
        if (record) {
            if (editType === EDIT_CONSTANT) {
                setNameVisible(true)
                setEditFileId(record.id)
                form.setFieldsValue(record)
            } else {
                setFileTypeVisible(true)
                form.setFieldsValue({
                    fileType: record.type,
                })
                if (record.type === 'youtube' || record.type === LINK_TYPE) {
                    setIsDataType(true)
                    form.setFieldsValue(record)
                } else {
                    setIsDataType(false)
                }

                setEditFileId(record.id)
            }
        } else {
            form.resetFields()
            setNameVisible(true)
            setFileTypeVisible(true)
        }
        setAddEditFileModalVisible(true)
    }

    const changeSelectOption = (value) => {
        if (value === 'youtube' || value === LINK_TYPE) {
            setIsDataType(true)
        } else {
            setIsDataType(false)
        }
    }
    const deleteFile = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_FILE, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = resourceFilesData
                    setResourceFilesData(
                        successObject.filter((key) => key.id !== id)
                    )
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    const dowloadFile = (record) => {
        window.open(record.resourceUrl, '_blank')
    }

    const onCopy = () => {
        message.success('Link copied!')
    }

    const { Search } = Input

    let columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            openFileModal(record, EDIT_CONSTANT)
                        }}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditTwoTone style={{ fontSize: 11 }} />
                    </Button>
                    {record.resourceUrl ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    dowloadFile(record)
                                }}
                                style={{ padding: 0, marginRight: 5 }}
                            >
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    size={'xs'}
                                />
                            </Button>
                            <CopyToClipboard
                                text={record.resourceUrl}
                                onCopy={onCopy}
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginRight: 5 }}
                                >
                                    <CopyOutlined style={{ fontSize: 11 }} />
                                </Button>
                            </CopyToClipboard>
                        </>
                    ) : null}
                    <span>{record.name}</span>
                </span>
            ),
        },
        {
            title: 'Content',
            dataIndex: 'fileName',
            key: 'fileName',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            openFileModal(record)
                        }}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditTwoTone style={{ fontSize: 11 }} />
                    </Button>
                    {record.fileName ? (
                        <span>{record.fileName}</span>
                    ) : (
                        <a
                            href={record.data}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {record.data}
                        </a>
                    )}
                </span>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure want to delete ?"
                    onConfirm={() => {
                        deleteFile(record.id)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const dataProps = {
        onRemove: (file) => {
            setFileList((prev) => {
                const index = prev.indexOf(file)
                const newFileList = prev.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file) => {
            setFileList((prev) => [...prev, file])
            return false
        },
        fileList,
    }
    var fileListOptionArray = []

    fileTypes.forEach((item) => {
        fileListOptionArray.push(<Option value={item}>{item}</Option>)
    })

    return (
        <div>
            <Row style={{ marginBottom: 8 }}>
                <Col span={18}>
                    <h2>Files</h2>
                </Col>

                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            openFileModal()
                        }}
                    >
                        Add File
                    </Button>
                </Col>
            </Row>
            <Search
                placeholder="Search by name"
                onSearch={(value) => searchResources(value)}
                style={{ width: 600, marginBottom: 10 }}
                autoFocus
            />
            <Table
                columns={columns}
                dataSource={resourceFilesData}
                pagination={false}
                size="small"
                rowKey="fileName"
                loading={loading}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPage}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                open={addEditFileModalVisible}
                title={editFileId ? 'Edit File' : 'Add File'}
                okText={editFileId ? 'Edit File' : 'Add File'}
                onCancel={() => {
                    cancelFileModal()
                }}
                confirmLoading={loadingFileModal}
                onOk={() => {
                    onSubmit(editFileId)
                }}
            >
                <Form form={form} layout="vertical">
                    {nameVisible ? (
                        <Form.Item
                            label="File Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter File Name',
                                },
                            ]}
                        >
                            <Input
                                autoFocus
                                placeholder="Please enter file name"
                            />
                        </Form.Item>
                    ) : null}
                    {fileTypeVisible ? (
                        <>
                            <Form.Item
                                label="Select File Type"
                                name="fileType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select File type',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select File type"
                                    onChange={changeSelectOption}
                                >
                                    {fileListOptionArray}
                                </Select>
                            </Form.Item>
                            {isDataType ? (
                                <Form.Item
                                    label="Data"
                                    name="data"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Url',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Url" />
                                </Form.Item>
                            ) : null}
                        </>
                    ) : null}
                </Form>
                {fileTypeVisible ? (
                    <>
                        {!isDataType ? (
                            <Upload {...dataProps}>
                                <Button>
                                    <UploadOutlined /> Select File
                                </Button>
                            </Upload>
                        ) : null}
                    </>
                ) : null}
            </Modal>
        </div>
    )
}

export default Files
