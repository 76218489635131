import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Layout, Menu, Button, notification, Input, Dropdown } from 'antd'
import {
    StarFilled,
    StarOutlined,
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Utils } from './JS/Utils'

import FreeResources from '../components/FreeResources/FreeResources'
import NewsAndUpdates from './NewsAndUpdates/NewsAndUpdates'
import NewsWithOpenCommentsList from './NewsAndUpdates/NewsWithOpenCommentsList'
import NewsWithOpenComments from './NewsAndUpdates/NewsWithOpenComments'
import Attempts from './MarketPlace/Attempts'
import AddFaculty from './MarketPlace/SubComponents/AddFaculty'
import CourseCatalog from './MarketPlace/CourseCatalog'
import Courses from './MarketPlace/Courses'
import FreeCourses from './MarketPlace/FreeCourses'
import Groups from './MarketPlace/Groups'
import AddCourse from './MarketPlace/AddCourse'
import AddGroup from './MarketPlace/AddGroup'
import Coupons from './Ecommerce/Coupons'
import Partners from './Ecommerce/Partners'
import PartnerInvoices from './Ecommerce/PartnerInvoices'
import Files from './FreeResources/Files'
import Subjects from './MCQ/Subjects'
import Chapters from './MCQ/Chapters'
import Sections from './MCQ/Sections'
import Topics from './MCQ/Topics'
import Difficulty from './MCQ/Difficulty'
import Tests from './MCQ/Tests'
import AddQuestion from './MCQ/AddQuestion'
import UploadQuestions from './MCQ/UploadQuestions'
import ReplaceQuestions from './MCQ/ReplaceQuestions'
import AddPage from './Pages/AddPage'
import Pages from './Pages/Pages'
import HomeItems from './HomeItems/HomeItems'
import GetQuestionsInTest from './MCQ/GetQuestionsInTest'
import QuestionDetails from './MCQ/QuestionDetails'
import Transactions from './Transactions/Transactions'
import TransactionDetails from './Transactions/TransactionDetails'
import HomeItemsForm from './HomeItems/HomeItemsForm'
import EmailToUser from './EmailToUser/EmailToUser'
import Flag from './Flag/Flag'
import UserStats from './Stats/UserStats'
import UserStreamSplitStats from './Stats/UserStreamSplitStats'
import ActiveUserStats from './Stats/ActiveUserStats'
import RevenueStats from './Stats/RevenueStats'
import VideoRequestStats from './Stats/VideoRequestStats'
import SearchUser from './User/SearchUser'
import UserDetails from './User/UserDetails'
import Dnd from './User/Dnd'
import Modules from './Course/Modules'
import ModuleSections from './Course/Sections'
import Reorder from './Common/Reorder'
import ForumsHome from './Forums/ForumsHome'
import ForumTopicView from './Forums/ForumTopicView'
import CouponCourseMapping from './Ecommerce/CouponCourseMapping'
import CouponUserMapping from './Ecommerce/CouponUserMapping'
import CreateBulkCoupons from './Ecommerce/CreateBulkCoupons'
import AddTransactionToQueue from './Transactions/AddTransactionToQueue'
import TransactionQueue from './Transactions/TransactionQueue'
import UserRoles from './Admin/UserRoles'
import SendNotification from './User/SendNotification'
import Notifications from './User/Notifications'
import VideoVendor from './Video/VideoVendor'
import Videos from './Video/Videos'
import SearchMCQ from './MCQ/SearchMCQ'
import ExtensionPrice from './MarketPlace/ExtensionPrice'
import ExtensionAttempt from './MarketPlace/ExtensionAttempt'
import CourseReviews from './Course/CourseReviews'
import GroupCourseReviews from './Course/GroupCourseReviews'
import WOF from './Website/WOF'
import DemoVideos from './Website/DemoVideos'
import FAQ from './Website/FAQ'
import AwardiCash from './User/AwardiCash'
import ConfigureCourse from './MarketPlace/ConfigureCourse'
import UserReport from './Reports/UserReport'
import TransactionReport from './Reports/TransactionReport'
import TransactionItemsReport from './Reports/TransactionItemsReport'
import SubjectWiseRevenueReport from './Reports/SubjectWiseRevenueReport'
import FacultyRevenueReport from './Reports/FacultyRevenueReport'
import CourseEnrollmentReport from './Reports/CourseEnrollmentReport'
import SubjectCompletionReport from './Reports/SubjectCompletionReport'
import Dsr from './Stats/Dsr'
import Arpu from './Stats/Arpu'
import RevenueSplit from './Stats/RevenueSplit'
import RevenueSplitByState from './Stats/RevenueSplitByState'
import SubjectRevenue from './Stats/SubjectRevenue'
import FirstPurchaseStats from './Stats/FirstPurchaseStats'
import PurchaseAgingStats from './Stats/PurchaseAgingStats'
import ActiveSubscribers from './Stats/ActiveSubscribers'
import TotalWatchedStats from './Stats/TotalWatchedStats'
import LeadsDashboard from './Ecommerce/LeadsDashoard'
import LeadsStreamWiseDashboard from './Ecommerce/LeadsStreamWiseDashboard'
import FacultyWiseStats from './Stats/FacultyWiseStats'
import AllFacultyRevenueStats from './Stats/AllFacultyRevenueStats'
import Leads from './Ecommerce/Leads'
import LeadsDashboardByStreamAndLeadType from './Ecommerce/LeadsDashboardByStreamAndLeadType'
import CartLeadTypes from './Ecommerce/CartLeadTypes'
import CartDropCoupons from './Ecommerce/CartDropCoupons'
import Config from './Config/Config'
import Surveys from './Surveys/Surveys'
import SurveyQuestions from './Surveys/SurveyQuestions'
import SurveyResponses from './Surveys/SurveyResponses'
import ForumGroup from './Forums/ForumGroup'
import ForumGroupUserMapping from './Forums/ForumGroupUserMapping'
import StreamPage from './Website/StreamPage'
import StreamPageContent from './Website/StreamPageContent'
import BlogAuthor from './Blog/BlogAuthor'
import BlogTags from './Blog/BlogTags'
import BlogPage from './Blog/BlogPage'
import BlogSuper from './Blog/BlogSuper'
import BlogCategory from './Blog/BlogCategory'
import BlogOpenCommentsList from './Blog/BlogOpenCommentsList'
import BlogComments from './Blog/BlogComments'
import Faculty from './Faculty/Faculty'
import FacultyContract from './Faculty/FacultyContract'
import FacultySalesSummary from './Faculty/FacultySalesSummary'
import FacultyInvoice from './Faculty/FacultyInvoice'
import FacultyCoursesSummary from './Faculty/FacultyCoursesSummary'
import FacutyCommissionReport from './Faculty/FacutyCommissionReport'
import StudyPlannerConfig from './StudyPlanner/StudyPlannerConfig'
import StudyPlannerCourseConfig from './StudyPlanner/StudyPlannerCourseConfig'
import StudyPlannerCourseSelectionConfig from './StudyPlanner/StudyPlannerCourseSelectionConfig'
import StudyPlannerSessionConfig from './StudyPlanner/StudyPlannerSessionConfig'
import SalesReport from './Reports/SalesReport'
import SalesTeamCallsReport from './Reports/SalesTeamCallsReport'
import AddCoursesByPurchase from './User/AddCoursesByPurchase'
import CourseTests from './MCQ/CourseTests'
import ShortLink from './Misc/ShortLink'
import ComboDiscount from './Ecommerce/ComboDiscount'
import UpdateTransactions from './Ecommerce/UpdateTransactions'
import CourseCompletionByAttempt from './Stats/CourseCompletionByAttempt'
import UploadAndGetLink from './Common/UploadAndGetLink'
import LiveClassSchedule from './Misc/LiveClassSchedule'
import UnattendedLeadsStats from './Stats/UnattendedLeadsStats'
import { RestApi } from '../RestApi'
import GLOBAL from '../Globals'
import PurchaseStats from './Stats/PurchaseStats'
import AttemptStats from './Stats/AttemptStats'
import CourierStats from './Stats/CourierStats'
import EmailTemplates from './Misc/EmailTemplates'
import ExternalSalesTeam from './Sales/ExternalSalesTeam'
import ExternalSalesNewLeads from './Sales/ExternalSalesNewLeads'
import ExternalSalesLeadDetails from './Sales/ExternalSalesLeadDetails'
import ExternalSalesOldLeads from './Sales/ExternalSalesOldLeads'
import ExternalSalesSearchLead from './Sales/ExternalSalesSearchLead'
import ExternalSalesCallsReport from './Sales/ExternalSalesCallsReport'
import ExternalSalesDashboard from './Sales/ExternalSalesDashboard'
import ExternalSalesUnattendedLeadsStats from './Sales/ExternalSalesUnattendedLeadsStats'
import Books from './Books/Books'
import BookTransactions from './Books/BookTransactions'
import BooksInventoryReport from './Books/BooksInventoryReport'
import Courier from './Courier/Courier'
import Deeplinks from './Misc/Deeplinks'
import UserWatchTimeAnalytics from './Analytics/UserWatchTimeAnalytics'
import Drip from './Marketing/Drip'
import LeadsCurrentStatus from './Sales/LeadsCurrentStatus'
import UsersStreamSplitWeekWise from './Stats/UsersStreamSplitWeekWise'
import Colleges from './College/Colleges'
import Attendance from './College/Attendance'
import Pincodes from './Misc/Pincodes'
import IlEmployeeRoles from './IL/IlEmployeeRoles'
import IlEmployees from './IL/IlEmployees'
import Webinar from './Leads/Webinar'
import IlHolidays from './IL/IlHolidays'
import IlPolicies from './IL/IlPolicies'
import ForumAdminResponseStats from '../components/Forums/ForumAdminResponseStats'
import '../App.css'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../actions'
import { sideBarData } from './Assests/ILSidebarData'
import QrCode from './Misc/QrCode'
import StudyMinsCoursePerDay from './Nudge/StudyMinsCoursePerDay'
import NudgeNotification from './Nudge/NudgeNotification'
import { withRouter } from '../Router/WithRouter'
import { makeStyles } from 'tss-react/mui'
import { Link } from '@mui/material'
import NotificationsSendingSummary from './User/NotificationsSendingSummary'

const { Content, Sider } = Layout
const { SubMenu } = Menu

const MainScreen = (props) => {
    const [searchMenu, setSearchMenu] = useState([])
    const [matchedMenuItems, setMatchedMenuItems] = useState([])
    const userFavoritemenus = useSelector((state) => state.userFavoriteMenus)
    const collapsed = useSelector((state) => state.isSideDrawerCollapsed)
    const dispatch = useDispatch()

    const favoriteMenuKeys = userFavoritemenus.map((item) => item.key)
    const userToken = Utils.getAuthToken()
    const { classes } = useStyles()

    useEffect(() => {
        if (!userToken) {
            dispatch({
                type: actionTypes.SET_IS_LOG_IN,
                isLogIn: true,
            })
        }

        RestApi.doGet(GLOBAL.URL.GET_USER_INFO).then((res) => {
            GLOBAL.USER_DATA = res.data
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        const filteredSidebarData = sideBarData()
        const updatedSearchMenuData = filteredSidebarData
            .map((item) => {
                if (item.show) {
                    const updatedSubmenu = item.subMenu
                        .map((subItem) => {
                            if (subItem.show) {
                                if (subItem.subMenu) {
                                    const updatedNestedSubmenu =
                                        subItem.subMenu.filter(
                                            (nestedItem) => nestedItem.show
                                        )
                                    return {
                                        ...subItem,
                                        subMenu: updatedNestedSubmenu,
                                    }
                                } else {
                                    return subItem
                                }
                            } else {
                                return null
                            }
                        })
                        .filter(Boolean)
                    return { ...item, subMenu: updatedSubmenu }
                } else {
                    return null
                }
            })
            .filter(Boolean)
        setSearchMenu(updatedSearchMenuData)
    }, [userToken])

    const onCollapse = () => {
        dispatch({
            type: actionTypes.SET_IS_SIDE_DRAWER_COLLAPSED,
            isCollapsed: !collapsed,
        })
    }

    const handleLogOut = () => {
        localStorage.removeItem('token')
        dispatch({
            type: actionTypes.SET_IS_LOG_IN,
            isLogIn: true,
        })
    }

    const onCopy = () => {
        notification['success']({
            message: 'Copied!',
        })
    }

    const handleOnChangeSearch = (e) => {
        const newVal = e.target.value
        if (!newVal) {
            setMatchedMenuItems([])
        } else {
            const textLower = newVal.toLowerCase().trim()
            if (textLower.length === 0) {
                return
            }
            const matchedItems = []

            searchMenu.forEach((item) => {
                if (item.subMenu) {
                    item.subMenu.forEach((subItem) => {
                        if (subItem.subMenu) {
                            subItem.subMenu.forEach((nestedItem) => {
                                if (
                                    nestedItem.title
                                        .toLowerCase()
                                        .includes(textLower) &&
                                    nestedItem.show
                                ) {
                                    matchedItems.push(nestedItem)
                                }
                            })
                        } else {
                            if (
                                subItem.title
                                    .toLowerCase()
                                    .includes(textLower) &&
                                subItem.show
                            ) {
                                matchedItems.push(subItem)
                            }
                        }
                    })
                }
            })

            setMatchedMenuItems(matchedItems.length > 0 ? matchedItems : [])
        }
    }

    const onClickFavorite = (item) => {
        const isFavorite = userFavoritemenus.find(
            (favoriteItem) => favoriteItem.key === item.key
        )
        let arrayItems = []
        if (!isFavorite) {
            arrayItems = [...userFavoritemenus, item]
        } else {
            arrayItems = userFavoritemenus.filter(
                (menuItem) => menuItem.key !== item.key
            )
        }
        dispatch({
            type: actionTypes.SET_USER_FAVORITE_MENU,
            payload: arrayItems,
        })
    }

    const menu = (
        <Menu>
            {userFavoritemenus.map((item, index) => (
                <Menu.Item key={item.key}>
                    <Link
                        href={item.route}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(item.route)
                        }}
                        underline="none"
                    >
                        {`${index + 1}. `}
                        <span>{item.title}</span>
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    )

    const renderMenuItem = (item, customStyles) => {
        return (
            <Menu.Item className="menuItem" key={item.key}>
                {item.Icon}
                <span>{item.title}</span>
                <Link
                    href={item.route}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate(item.route)
                    }}
                    underline="none"
                />
                <div
                    className={
                        favoriteMenuKeys.includes(item.key)
                            ? `starIcon ${customStyles} favoriteMenu`
                            : `starIcon ${customStyles}`
                    }
                    onClick={(e) => {
                        e.preventDefault()
                        onClickFavorite(item)
                    }}
                >
                    {favoriteMenuKeys.includes(item.key) ? (
                        <StarFilled style={{ color: 'grey' }} />
                    ) : (
                        <StarOutlined style={{ color: '#fff' }} />
                    )}
                </div>
            </Menu.Item>
        )
    }

    const renderSubMenu = (item) => {
        return item.subMenu ? (
            <SubMenu
                key={item.key}
                title={
                    <span>
                        {item.Icon}
                        <span>{item.title}</span>
                    </span>
                }
            >
                {item.subMenu.map((subItem) =>
                    subItem.subMenu
                        ? renderSubMenu(subItem)
                        : subItem.show
                        ? renderMenuItem(subItem)
                        : null
                )}
            </SubMenu>
        ) : (
            renderMenuItem(item, 'starIconSearch')
        )
    }

    return (
        <Layout className="layout" style={{ minHeight: '100vh' }}>
            <Sider
                width={collapsed ? 0 : 220}
                collapsedWidth={0}
                style={{
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
            >
                {collapsed && <div className={classes.divider} />}
                <div
                    onClick={onCollapse}
                    style={{
                        right: collapsed ? -42 : -13,
                    }}
                    className={classes.toggleIconWrapper}
                >
                    {collapsed ? (
                        <RightOutlined className={classes.toggleIconStyle} />
                    ) : (
                        <LeftOutlined className={classes.toggleIconStyle} />
                    )}
                </div>
                <div className="siderContent">
                    <h1
                        style={{
                            color: 'white',
                            fontSize: 20,
                            margin: 10,
                        }}
                    >
                        IndigoLearn
                    </h1>

                    <div style={{ padding: '0px 15px' }}>
                        <Input
                            type="search"
                            placeholder="Search..."
                            className="custom-input"
                            onChange={handleOnChangeSearch}
                        />
                    </div>
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={['sub2']}
                        mode="inline"
                        className="menuContainer"
                    >
                        {userFavoritemenus.length > 0 &&
                        matchedMenuItems.length === 0 ? (
                            <SubMenu
                                key={'Favourites'}
                                title={
                                    <span>
                                        <StarOutlined />
                                        <span>Favourites</span>
                                    </span>
                                }
                            >
                                {userFavoritemenus.map((item, index) => (
                                    <Menu.Item
                                        className="menuItem"
                                        key={item.key}
                                    >
                                        {`${index + 1}. `}
                                        <span>{item.title}</span>
                                        <Link
                                            href={item.route}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                props.navigate(item.route)
                                            }}
                                            underline="none"
                                        />
                                        <div
                                            className={
                                                favoriteMenuKeys.includes(
                                                    item.key
                                                )
                                                    ? 'starIcon favoriteMenu'
                                                    : 'starIcon'
                                            }
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onClickFavorite(item)
                                            }}
                                        >
                                            {favoriteMenuKeys.includes(
                                                item.key
                                            ) ? (
                                                <StarFilled
                                                    style={{
                                                        color: 'dimgrey',
                                                    }}
                                                />
                                            ) : (
                                                <StarOutlined
                                                    style={{
                                                        color: '#fff',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Menu.Item>
                                ))}
                                {userFavoritemenus.length > 1 && (
                                    <Menu.Item key={'reorder'}>
                                        <span>--reorder--</span>
                                        <Link
                                            href={'/reorder'}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                props.navigate('/reorder', {
                                                    state: {
                                                        isFavoritesUpdate: true,
                                                        columns: [
                                                            {
                                                                title: 'Favourites',
                                                                dataIndex:
                                                                    'title',
                                                                key: 'title',
                                                            },
                                                        ],
                                                    },
                                                })
                                            }}
                                            underline="none"
                                        />
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        ) : null}
                        {matchedMenuItems.length > 0
                            ? matchedMenuItems.map((item) =>
                                  item.show ? renderSubMenu(item) : null
                              )
                            : searchMenu.map((item) =>
                                  item.show ? renderSubMenu(item) : null
                              )}

                        <div>
                            <Button
                                onClick={() => {
                                    handleLogOut()
                                }}
                                type="link"
                                style={{
                                    marginLeft: 10,
                                    marginBottom: '15px',
                                }}
                            >
                                Log Out
                            </Button>

                            {Utils.isUserAdmin() && (
                                <CopyToClipboard
                                    text={localStorage.getItem('token')}
                                    onCopy={onCopy}
                                >
                                    <Button
                                        type="link"
                                        style={{ marginLeft: 10 }}
                                    >
                                        Copy Auth
                                    </Button>
                                </CopyToClipboard>
                            )}
                        </div>
                    </Menu>
                </div>
            </Sider>

            <Content
                style={{
                    background: '#fff',
                    padding: 24,
                    marginLeft: collapsed ? 30 : 220,
                }}
            >
                {userFavoritemenus.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '50%',
                            position: 'fixed',
                            bottom: 10,
                            left: 230,
                            zIndex: 9999,
                        }}
                    >
                        <Dropdown
                            overlay={menu}
                            trigger={['hover']}
                            placement="topLeft"
                        >
                            <Button
                                shape="circle"
                                icon={<StarOutlined />}
                                size="large"
                                style={{
                                    backgroundColor: 'rgba(64,150,255,0.3)',
                                }}
                            />
                        </Dropdown>
                    </div>
                )}
                <Routes>
                    <Route
                        exact
                        path="/news"
                        element={<NewsAndUpdates {...props} />}
                    />
                    <Route
                        exact
                        path="/newswithopencommentslist"
                        element={<NewsWithOpenCommentsList {...props} />}
                    />
                    <Route
                        exact
                        path="/newswithopencomments/:newsId"
                        element={<NewsWithOpenComments {...props} />}
                    />
                    <Route
                        exact
                        path="/attempts"
                        element={<Attempts {...props} />}
                    />
                    <Route
                        exact
                        path="/addfaculty"
                        element={<AddFaculty {...props} />}
                    />
                    <Route
                        exact
                        path="/coursecatalog"
                        element={<CourseCatalog {...props} />}
                    />
                    <Route
                        exact
                        path="/coursecatalog/:catalogId/:name"
                        element={<CourseCatalog {...props} />}
                    />
                    <Route
                        exact
                        path="/addcourse"
                        element={<AddCourse {...props} />}
                    />
                    <Route
                        exact
                        path="/addgroup"
                        element={<AddGroup {...props} />}
                    />
                    <Route
                        exact
                        path="/courses"
                        element={<Courses {...props} />}
                    />
                    <Route
                        exact
                        path="/freecourses"
                        element={<FreeCourses {...props} />}
                    />
                    <Route
                        exact
                        path="/groups"
                        element={<Groups {...props} />}
                    />
                    <Route
                        exact
                        path="/coupons"
                        element={<Coupons {...props} />}
                    />
                    <Route
                        exact
                        path="/freeresources/catlog"
                        element={<FreeResources {...props} />}
                    />
                    <Route
                        exact
                        path="/freeresources/files"
                        element={<Files {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/subjects"
                        element={<Subjects {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/chapters"
                        element={<Chapters {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/sections"
                        element={<Sections {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/topics"
                        element={<Topics {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/difficulty"
                        element={<Difficulty {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/tests"
                        element={<Tests {...props} />}
                    />
                    <Route
                        exact
                        path="/page/addpage"
                        element={<AddPage {...props} />}
                    />
                    <Route
                        exact
                        path="/page/pages"
                        element={<Pages {...props} />}
                    />
                    <Route
                        exact
                        path="/homeitems/home"
                        element={<HomeItems {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/addquestion"
                        element={<AddQuestion {...props} />}
                    />
                    <Route
                        exact
                        path="/homeitems/homeitemsform"
                        element={<HomeItemsForm {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/uploadquestions"
                        element={<UploadQuestions {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/replacequestions"
                        element={<ReplaceQuestions {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/questiondetails/:questionId"
                        element={<QuestionDetails {...props} />}
                    />
                    <Route
                        exact
                        path="/transactions/transactions"
                        element={<Transactions {...props} />}
                    />
                    <Route
                        exact
                        path="/transactions/transactiondetails/:id"
                        element={<TransactionDetails {...props} />}
                    />
                    <Route exact path="/flag" element={<Flag {...props} />} />
                    <Route
                        exact
                        path="/email"
                        element={<EmailToUser {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/test/:testId"
                        element={<GetQuestionsInTest {...props} />}
                    />
                    <Route
                        exact
                        path="/userstats"
                        element={<UserStats {...props} />}
                    />
                    <Route
                        exact
                        path="/userstreamsplitstats"
                        element={<UserStreamSplitStats {...props} />}
                    />
                    <Route
                        exact
                        path="/activeuserstats"
                        element={<ActiveUserStats {...props} />}
                    />
                    <Route
                        exact
                        path="/revenuestats"
                        element={<RevenueStats {...props} />}
                    />
                    <Route
                        exact
                        path="/videorequeststats"
                        element={<VideoRequestStats {...props} />}
                    />
                    <Route
                        exact
                        path="/subjectrevenue"
                        element={<SubjectRevenue {...props} />}
                    />
                    <Route
                        exact
                        path="/activesubscribers"
                        element={<ActiveSubscribers {...props} />}
                    />
                    <Route
                        exact
                        path="/totalwatchedstats"
                        element={<TotalWatchedStats {...props} />}
                    />
                    <Route
                        exact
                        path="/searchuser"
                        element={<SearchUser {...props} />}
                    />
                    <Route
                        exact
                        path="/userdetails/:userId"
                        element={<UserDetails {...props} />}
                    />
                    <Route
                        exact
                        path="/modules/:courseId"
                        element={<Modules {...props} />}
                    />
                    <Route
                        exact
                        path="/modulesections/:moduleId"
                        element={<ModuleSections {...props} />}
                    />
                    <Route
                        exact
                        path="/reorder"
                        element={<Reorder {...props} />}
                    />
                    <Route
                        exact
                        path="/forum"
                        element={<ForumsHome {...props} />}
                    />
                    <Route
                        exact
                        path="/forumtopicdetails/:id"
                        element={<ForumTopicView {...props} />}
                    />
                    <Route
                        exact
                        path="/couponcoursemapping/:id"
                        element={<CouponCourseMapping {...props} />}
                    />
                    <Route
                        exact
                        path="/couponusermapping/:id"
                        element={<CouponUserMapping {...props} />}
                    />
                    <Route
                        exact
                        path="/createbulkcoupons"
                        element={<CreateBulkCoupons {...props} />}
                    />
                    <Route
                        exact
                        path="/addtransactiontoqueue"
                        element={<AddTransactionToQueue {...props} />}
                    />
                    <Route
                        exact
                        path="/transactionqueue"
                        element={<TransactionQueue {...props} />}
                    />
                    <Route
                        exact
                        path="/admin/userroles"
                        element={<UserRoles {...props} />}
                    />
                    <Route
                        exact
                        path="/notification/add"
                        element={<SendNotification {...props} />}
                    />
                    <Route
                        exact
                        path="/notifications"
                        element={<Notifications {...props} />}
                    />
                    <Route
                        exact
                        path="/videovendor"
                        element={<VideoVendor {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/search"
                        element={<SearchMCQ {...props} />}
                    />
                    <Route
                        exact
                        path="/extension/price/:attemptMappingId"
                        element={<ExtensionPrice {...props} />}
                    />
                    <Route
                        exact
                        path="/extension/attempt"
                        element={<ExtensionAttempt {...props} />}
                    />
                    <Route
                        exact
                        path="/coursereviews"
                        element={<CourseReviews {...props} />}
                    />
                    <Route
                        exact
                        path="/testimonials"
                        element={<WOF {...props} />}
                    />
                    <Route
                        exact
                        path="/demovideos"
                        element={<DemoVideos {...props} />}
                    />
                    <Route exact path="/faq" element={<FAQ {...props} />} />
                    <Route
                        exact
                        path="/awardicash"
                        element={<AwardiCash {...props} />}
                    />
                    <Route
                        exact
                        path="/course/configure/:id"
                        element={<ConfigureCourse {...props} />}
                    />
                    <Route
                        exact
                        path="/videos"
                        element={<Videos {...props} />}
                    />
                    <Route
                        exact
                        path="/report/users"
                        element={<UserReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/transactions"
                        element={<TransactionReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/transaction-items"
                        element={<TransactionItemsReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/subjectwiserevenuereport"
                        element={<SubjectWiseRevenueReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/facultyrevenuereport"
                        element={<FacultyRevenueReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/course-enrollment-report"
                        element={<CourseEnrollmentReport {...props} />}
                    />
                    <Route
                        exact
                        path="/report/subject-completion-report"
                        element={<SubjectCompletionReport {...props} />}
                    />
                    <Route exact path="/dsr" element={<Dsr {...props} />} />
                    <Route exact path="/arpu" element={<Arpu {...props} />} />
                    <Route
                        exact
                        path="/revenuesplit"
                        element={<RevenueSplit {...props} />}
                    />
                    <Route
                        exact
                        path="/revenuesplitbystate"
                        element={<RevenueSplitByState {...props} />}
                    />
                    <Route
                        exact
                        path="/partners"
                        element={<Partners {...props} />}
                    />
                    <Route
                        exact
                        path="/partnerinvoices"
                        element={<PartnerInvoices {...props} />}
                    />
                    <Route
                        exact
                        path="/facultywisestats"
                        element={<FacultyWiseStats {...props} />}
                    />
                    <Route
                        exact
                        path="/allfacultyrevenuestats"
                        element={<AllFacultyRevenueStats {...props} />}
                    />
                    <Route path={`/leads/*`} element={<Leads {...props} />} />
                    <Route
                        exact
                        path="/cartleadtypes"
                        element={<CartLeadTypes {...props} />}
                    />
                    <Route
                        exact
                        path="/cartdropcoupons"
                        element={<CartDropCoupons {...props} />}
                    />
                    <Route
                        exact
                        path="/admin/config"
                        element={<Config {...props} />}
                    />
                    <Route
                        exact
                        path="/surveys"
                        element={<Surveys {...props} />}
                    />
                    <Route
                        exact
                        path="/survey/questions/:surveyId"
                        element={<SurveyQuestions {...props} />}
                    />
                    <Route
                        exact
                        path="/survey/responses/:surveyId"
                        element={<SurveyResponses {...props} />}
                    />
                    <Route
                        exact
                        path="/forumcategory"
                        element={<ForumGroup {...props} />}
                    />
                    <Route
                        exact
                        path="/forum-group-user-mapping"
                        element={<ForumGroupUserMapping {...props} />}
                    />
                    <Route
                        exact
                        path="/streampage"
                        element={<StreamPage {...props} />}
                    />
                    <Route
                        exact
                        path="/streampage/:id"
                        element={<StreamPage {...props} />}
                    />
                    <Route
                        exact
                        path="/streampage/content/:pageid"
                        element={<StreamPageContent {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/author"
                        element={<BlogAuthor {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/tags"
                        element={<BlogTags {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/pages"
                        element={<BlogPage {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/super"
                        element={<BlogSuper {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/category"
                        element={<BlogCategory {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/opencommentslist"
                        element={<BlogOpenCommentsList {...props} />}
                    />
                    <Route
                        exact
                        path="/blog/comments/:blogId"
                        element={<BlogComments {...props} />}
                    />
                    <Route
                        exact
                        path="/leadsdashboard"
                        element={<LeadsDashboard {...props} />}
                    />
                    <Route
                        exact
                        path="/leads-stream-wise-dashboard"
                        element={<LeadsStreamWiseDashboard {...props} />}
                    />
                    <Route
                        exact
                        path="/faculty"
                        element={<Faculty {...props} />}
                    />
                    <Route
                        exact
                        path="/facultycontract"
                        element={<FacultyContract {...props} />}
                    />
                    <Route
                        exact
                        path="/facultysalessummary"
                        element={<FacultySalesSummary {...props} />}
                    />
                    <Route
                        exact
                        path="/facultyinvoice"
                        element={<FacultyInvoice {...props} />}
                    />
                    <Route
                        exact
                        path="/facultycoursessummary/:facultyIdInUrl"
                        element={<FacultyCoursesSummary {...props} />}
                    />
                    <Route
                        exact
                        path="/study-planner-config"
                        element={<StudyPlannerConfig {...props} />}
                    />
                    <Route
                        exact
                        path="/study-planner-course-config"
                        element={<StudyPlannerCourseConfig {...props} />}
                    />
                    <Route
                        exact
                        path="/study-planner-course-selection-config"
                        element={
                            <StudyPlannerCourseSelectionConfig {...props} />
                        }
                    />
                    <Route
                        exact
                        path="/study-planner-session-config"
                        element={<StudyPlannerSessionConfig {...props} />}
                    />
                    <Route
                        exact
                        path="/salesreport"
                        element={<SalesReport {...props} />}
                    />
                    <Route
                        exact
                        path="/sales-team-calls-report"
                        element={<SalesTeamCallsReport {...props} />}
                    />
                    <Route
                        exact
                        path="/add-courses-by-purchase"
                        element={<AddCoursesByPurchase {...props} />}
                    />
                    <Route
                        exact
                        path="/group-reviews"
                        element={<GroupCourseReviews {...props} />}
                    />
                    <Route
                        exact
                        path="/mcq/course-tests"
                        element={<CourseTests {...props} />}
                    />
                    <Route exact path="/dnd" element={<Dnd {...props} />} />
                    <Route
                        exact
                        path="/short-links"
                        element={<ShortLink {...props} />}
                    />
                    <Route
                        exact
                        path="/combo-discounts"
                        element={<ComboDiscount {...props} />}
                    />
                    <Route
                        exact
                        path="/update-transactions"
                        element={<UpdateTransactions {...props} />}
                    />
                    <Route
                        exact
                        path="/course-completion-by-attempt"
                        element={<CourseCompletionByAttempt {...props} />}
                    />
                    <Route
                        exact
                        path="/upload-and-get-link"
                        element={<UploadAndGetLink {...props} />}
                    />
                    <Route
                        exact
                        path="/live-class-schedule"
                        element={<LiveClassSchedule {...props} />}
                    />
                    <Route
                        exact
                        path="/unattended-leads-stats"
                        element={<UnattendedLeadsStats {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/purchase-stats"
                        element={<PurchaseStats {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/courier-stats"
                        element={<CourierStats {...props} />}
                    />
                    <Route
                        exact
                        path="/email-templates"
                        element={<EmailTemplates {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-new-leads"
                        element={<ExternalSalesNewLeads {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-lead-details/:id"
                        element={<ExternalSalesLeadDetails {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-lead-search"
                        element={<ExternalSalesSearchLead {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-old-leads"
                        element={<ExternalSalesOldLeads {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-calls-report"
                        element={<ExternalSalesCallsReport {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-dashboard"
                        element={<ExternalSalesDashboard {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-team"
                        element={<ExternalSalesTeam {...props} />}
                    />
                    <Route
                        exact
                        path="/external-sales-unattended-leads-stats"
                        element={
                            <ExternalSalesUnattendedLeadsStats {...props} />
                        }
                    />
                    <Route exact path="/books" element={<Books {...props} />} />
                    <Route
                        exact
                        path="/book-transactions"
                        element={<BookTransactions {...props} />}
                    />
                    <Route
                        exact
                        path="/book-inventory-report"
                        element={<BooksInventoryReport {...props} />}
                    />
                    <Route
                        exact
                        path="/courier"
                        element={<Courier {...props} />}
                    />
                    <Route
                        exact
                        path="/deeplinks"
                        element={<Deeplinks {...props} />}
                    />
                    <Route
                        exact
                        path="/user-watchtime-analytics/:userId"
                        element={<UserWatchTimeAnalytics {...props} />}
                    />
                    <Route
                        exact
                        path="/leads-dashboard-steam-leadtype"
                        element={
                            <LeadsDashboardByStreamAndLeadType {...props} />
                        }
                    />
                    <Route exact path="/drip" element={<Drip {...props} />} />
                    <Route
                        exact
                        path="/leads-current-status"
                        element={<LeadsCurrentStatus {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/first-purchase-stats"
                        element={<FirstPurchaseStats {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/purchase-aging-stats"
                        element={<PurchaseAgingStats {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/user-stream-split-week-wise"
                        element={<UsersStreamSplitWeekWise {...props} />}
                    />
                    <Route
                        exact
                        path="/colleges"
                        element={<Colleges {...props} />}
                    />
                    <Route
                        exact
                        path="/attendance"
                        element={<Attendance {...props} />}
                    />
                    <Route
                        exact
                        path="/pincodes"
                        element={<Pincodes {...props} />}
                    />
                    <Route
                        exact
                        path="/faculty-commission-report"
                        element={<FacutyCommissionReport {...props} />}
                    />
                    <Route
                        exact
                        path="/il/employee-roles"
                        element={<IlEmployeeRoles {...props} />}
                    />
                    <Route
                        exact
                        path="/il/employees"
                        element={<IlEmployees {...props} />}
                    />
                    <Route
                        exact
                        path="/il/holidays"
                        element={<IlHolidays {...props} />}
                    />
                    <Route
                        exact
                        path="/il/policies"
                        element={<IlPolicies {...props} />}
                    />
                    <Route
                        exact
                        path="/webinar"
                        element={<Webinar {...props} />}
                    />
                    <Route
                        exact
                        path="/forum-response-stats"
                        element={<ForumAdminResponseStats {...props} />}
                    />
                    <Route
                        exact
                        path="/qr-code"
                        element={<QrCode {...props} />}
                    />
                    <Route
                        exact
                        path="/stats/attempt-stats"
                        element={<AttemptStats {...props} />}
                    />
                    <Route
                        exact
                        path="/nudge/study-mins-course-per-day"
                        element={<StudyMinsCoursePerDay {...props} />}
                    />
                    <Route
                        exact
                        path="/nudge/notifications"
                        element={<NudgeNotification {...props} />}
                    />
                    <Route
                        exact
                        path="/notifications/summary"
                        element={<NotificationsSendingSummary {...props} />}
                    />
                </Routes>
            </Content>
        </Layout>
    )
}

export default withRouter(MainScreen)

const useStyles = makeStyles()((theme) => ({
    divider: {
        height: '100%',
        width: 3,
        backgroundColor: '#e5e5e5',
        position: 'absolute',
        right: -30,
    },
    toggleIconWrapper: {
        position: 'absolute',
        top: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    toggleIconStyle: {
        background: '#fff',
        boxShadow:
            'rgba(9, 30, 66, 0.2) 0px 0px 0px 1px, rgba(9, 30, 66, 0.2) 0px 2px 4px 1px',
        cursor: 'pointer',
        display: 'flex',
        padding: 5,
        borderRadius: '50%',
        color: '#001529',
        fontSize: 14,
        '&:hover': {
            background: '#0c66e4',
            color: '#fff',
        },
    },
}))
